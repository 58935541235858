import { useFormContext } from '@core/contexts/formContext'
import { ReactNode, useEffect, useMemo } from 'react'
import { propertyName } from '@core/utils/nameOf'
import { useStoreContext } from '@core/contexts/storeContext'
import { Rule } from 'rc-field-form/lib/interface'

export type IPropsInput<T, Y> = {
  label?: ReactNode
  description?: ReactNode
  rules?: Rule[]
  withInitModel?: (item: any) => any
  disabled?: boolean
  dataId?: string
} & (
  | {
      id?: string
      value?: never
      model: T
      property: (x: T) => Y | undefined
      onChange?: (value: Y) => void
    }
  | {
      id: string
      value: Y | undefined
      model?: never
      property?: never
      onChange: ((value: Y) => void) | null
    }
)

export function InitInput<T, Y>(props: IPropsInput<T, Y>, inputName: string) {
  const formContext = useFormContext()
  const storeContext = useStoreContext()

  if (!formContext) {
    throw Error(`Skazy error config: you need to wrap ${inputName} in <SkForm>`)
  }

  const id = useMemo(() => {
    if (props.id) return `${inputName}_${props.id}`
    return `${inputName}_${storeContext ? storeContext.key : 'noStore'}_${propertyName(props.property).replace(/\./g, '-')}`
  }, [props.id ?? propertyName(props.property)])

  useEffect(() => {
    if (props.property && !props.model) throw Error(`${id}: Skazy error config: you need to set model={xxxx} on ${inputName}`)
  }, [])

  return { id }
}

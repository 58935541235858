import React from 'react'
import classNames from 'classnames'
import styles from './skAside.module.scss'
import { useIsMobile } from '@core/utils/hooks/useIsMobile'

interface IProps {
  children: React.ReactNode
  className?: string
}

export const SkAside = (props: IProps) => {
  const isMobile = useIsMobile()
  return (
    <aside className={classNames(styles.skAside, props.className)}>
      <div className="bg-dark h-full w-full lg:w-64">
        <div className={classNames('sticky top-0 flex w-full flex-col', { 'h-screen max-h-screen': !isMobile }, { '!h-[calc(100vh-55px)]': isMobile })}>{props.children}</div>
      </div>
    </aside>
  )
}

export const SkAsideHeader = (props: IProps) => <div className={classNames(props.className)}>{props.children}</div>

export const SkAsideBody = (props: IProps) => <div className={classNames('scrollbar-dark w-full overflow-auto pb-16', props.className)}>{props.children}</div>

export const SkAsideFooter = (props: IProps) => (
  <div className={classNames('border-dark-400 bg-dark-600 fixed bottom-0 w-full border-t p-3 text-gray-300 lg:w-64', props.className)}>
    <div className="flex flex-col">{props.children}</div>
  </div>
)

import { AbstractLabel } from '@core/models/abstractLabel'
import { makeObservable, observable } from 'mobx'
import { ArticleUnitGroup } from '@common/models/articleUnitGroup/articleUnitGroup'

export class ArticleUnit extends AbstractLabel {
  @observable
  decimals: number = 0
  @observable
  group: ArticleUnitGroup

  constructor(data?: Partial<ArticleUnit>) {
    super(data)
    makeObservable(this)
    Object.assign(this, data)
  }
}

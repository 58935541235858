import React from 'react'
import { Dropdown } from 'antd'
import { SkBtn } from '@core/components/btns'
import { SkIconUilAngleDown, SkIconUilEllipsisV } from '@core/global/icons'
import { coreStore } from '@core/store/coreStore'
import { BtnProps } from '@core/components/btns/skBtn'
import { ItemType } from 'antd/lib/menu/hooks/useItems'
import classNames from 'classnames'
import { DropdownProps } from 'antd/lib/dropdown/dropdown'

type Props = BtnProps & {
  label: string
  iconAngleDown?: boolean
  trigger?: ('click' | 'hover' | 'contextMenu')[]
  menuItems: ItemType[]
  children?: React.ReactNode
  hideIconRight?: boolean
  placement?: DropdownProps['placement']
  overlayClassName?: DropdownProps['overlayClassName']
}

export function SkBtnMenu(props: Props) {
  const labelNotEmpty = props.label?.length > 0
  if (props.permission && coreStore.meStore && !coreStore.meStore.hasPermission(props.permission)) {
    return <></>
  }

  return (
    <>
      <Dropdown menu={{ items: props.menuItems }} trigger={props.trigger ?? ['click']} placement={props.placement} overlayClassName={props.overlayClassName}>
        <div>
          <SkBtn {...props}>
            {props.label}
            {!props.hideIconRight && (
              <>
                {props.iconAngleDown ? (
                  <SkIconUilAngleDown className={classNames({ 'ml-2': labelNotEmpty })} width="18" height="18" />
                ) : (
                  <SkIconUilEllipsisV className={classNames({ 'ml-2': labelNotEmpty })} />
                )}
              </>
            )}
            {props.children}
          </SkBtn>
        </div>
      </Dropdown>
    </>
  )
}

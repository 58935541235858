import { observable } from 'mobx'
import { AbstractPerson } from '@core/models/abstractPerson'
import { AbstractContact } from '@core/models/abstractContact'

export class AbstractPersonContacts extends AbstractPerson {
  @observable
  contacts: AbstractContact[]

  constructor(data?: Partial<AbstractPersonContacts>) {
    super(data)
    Object.assign(this, data)
    if (this.contacts) {
      this.contacts.forEach((item, index) => {
        this.contacts[index] = new AbstractContact(item)
      })
      this.contacts = this.contacts.slice().sort((x, y) => x.id - y.id)
    }
  }
}

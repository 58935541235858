import React from 'react'
import { SkNumber } from '@core/components'

interface IProps {
  id: string
  label: string
  value: number
  onChange: (data: number) => void
  onPressEnter?: () => void
  debounce?: number
}

export function SkFilterNumber(props: IProps) {
  return (
    <div className="w-full filter">
      <SkNumber
        id={props.id}
        label={props.label}
        value={props.value}
        debounce={props.debounce}
        onChange={undefined}
        onDebounceChange={props.onChange}
        placeholder="Taper au moins un chiffre ..."
        size="large"
        className="w-full"
      />
    </div>
  )
}

import React, { useEffect, useState } from 'react'
import { SkSelect } from '@core/components'
import { DateTimeEnum } from 'skazy-gescom-common/global/enums'
import moment from 'moment'
import { SkIconUilTimes } from '@core/global/icons'
import { AbstractLabel } from '@core/models/abstractLabel'
import { SkMonthPicker } from '@core/components/monthPicker/skMonthPicker'

type FromToDuration = {
  fromDuration: string
  toDuration: string
  timeZone: string
}

export type FromTo = {
  granularity?: string
  from: string
  to: string
}

interface IProps {
  id: string
  label: string
  value: FromToDuration | FromTo
  onChange: (value: FromToDuration | FromTo) => void
}

export function SkFilterMonthPicker(props: IProps) {
  const durationCurrentMonth = { from: moment().startOf('month').format(), to: moment().endOf('month').format() }
  const durationLastMonth = { from: moment().subtract(1, 'months').startOf('month').format(), to: moment().subtract(1, 'months').endOf('month').format() }
  const durationNextMonth = { from: moment().add(1, 'months').startOf('month').format(), to: moment().add(1, 'months').endOf('month').format() }
  const [selectValue, setSelectValue] = useState<number>()
  const [fromToValue, setFromToValue] = useState<FromTo>()
  const [fromToDuration, setFromToDuration] = useState<FromToDuration>()

  const items = [
    new AbstractLabel({ id: DateTimeEnum.currentMonth, label: 'Mois en cours' }),
    new AbstractLabel({ id: DateTimeEnum.nextMonth, label: 'Mois prochain' }),
    new AbstractLabel({ id: DateTimeEnum.exactMonth, label: 'Mois ...' })
  ]

  useEffect(() => {
    if (!props.value) {
      setSelectValue(null)
      return
    }
    if (props.value['from'] === durationCurrentMonth.from && props.value['to'] === durationCurrentMonth.to) {
      setSelectValue(DateTimeEnum.currentMonth)
      setFromToValue(props.value as FromTo)
    } else if (props.value['from'] === durationLastMonth.from && props.value['to'] === durationLastMonth.to) {
      setSelectValue(DateTimeEnum.lastMonth)
      setFromToValue(props.value as FromTo)
    } else if (props.value['from'] === durationNextMonth.from && props.value['to'] === durationNextMonth.to) {
      setSelectValue(DateTimeEnum.nextMonth)
      setFromToValue(props.value as FromTo)
    } else {
      setSelectValue(DateTimeEnum.exactMonth)
      setFromToValue(props.value as FromTo)
    }
  }, [props.value])

  const onSelectChange = (v: number) => {
    let fromTo = null
    setSelectValue(v)

    switch (v) {
      case DateTimeEnum.currentMonth:
        fromTo = { ...durationCurrentMonth }
        setFromToValue(fromTo)
        props.onChange(fromTo)
        break
      case DateTimeEnum.lastMonth:
        fromTo = { ...durationLastMonth }
        setFromToValue(fromTo)
        props.onChange(fromTo)
        break
      case DateTimeEnum.nextMonth:
        fromTo = { ...durationNextMonth }
        setFromToValue(fromTo)
        props.onChange(fromTo)
        break
      default:
        if (fromToDuration || fromToValue) {
          setFromToValue(null)
          setFromToDuration(null)
          props.onChange(null)
        }
        break
    }
  }

  const onChangeMonth = (month: Date) => {
    const fromTo = month ? { granularity: 'month', from: moment(month).startOf('month').format(), to: moment(month).endOf('month').format() } : null
    props.onChange(fromTo)
    setFromToValue(fromTo)
  }

  return (
    <>
      <div className="w-full filter">
        <div>
          {(!selectValue || selectValue === DateTimeEnum.nextMonth || selectValue === DateTimeEnum.currentMonth || selectValue === DateTimeEnum.lastMonth) && (
            <SkSelect
              label={props.label}
              placeholder="Sélectionner ..."
              size="large"
              value={selectValue}
              items={items}
              id={`${props.id}Select`}
              onChange={onSelectChange}
              returnId
            />
          )}
          {selectValue === DateTimeEnum.exactMonth ? (
            <SkMonthPicker
              label={<Label label={props.label} labelClose="mois" onClose={() => onSelectChange(null)} />}
              value={fromToValue ? moment(fromToValue.to).toDate() : null}
              onChange={onChangeMonth}
              id={`${props.id}SkMonthPicker`}
              size="large"
              autoFocus
            />
          ) : (
            <></>
          )}
        </div>
      </div>
    </>
  )
}

const Label = ({ label, labelClose, onClose }: { label: string; labelClose: string; onClose: () => void }) => (
  <div className="flex items-center justify-between">
    {label}
    <a className="ml-3 flex cursor-pointer items-center rounded bg-pink-100 pl-2 text-xs text-pink-500" onClick={onClose}>
      {labelClose} <SkIconUilTimes height={12} />
    </a>
  </div>
)

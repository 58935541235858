import { action, makeObservable, observable } from 'mobx'
import { AbstractLabel } from '@core/models/abstractLabel'
import { Price } from '@common/models/price/price'
import { Customer } from '@common/models/customer/customer'
import { Package } from '@common/models/package/package'
import { Variant } from '@common/models/variant/variant'

export class Discount extends AbstractLabel {
  @observable
  amount: Price
  @observable
  percentage: number
  @observable
  avoidGrouping: boolean
  @observable
  customer: Customer
  @observable
  variant: Variant // NOUVEAU
  startDate: Date
  endDate: Date
  @observable
  type: AbstractLabel
  package: Package
  packages: Package[]

  constructor(data?: Partial<Discount>) {
    super(data)
    makeObservable(this)
    Object.assign(this, data)
    if (this.type) this.type = new AbstractLabel(this.type)
    if (this.package) this.package = new Package(this.type)
    if (this.packages) {
      this.packages.forEach((item, index) => {
        this.packages[index] = new Package(item)
      })
    }
    if (this.variant) this.variant = new Variant(this.variant)
  }

  @action
  setCustomer = (customer: Customer) => {
    this.customer = customer
  }
}

import * as React from 'react'
import { useObjectMemo } from '@core/utils/hooks'
import { SkFormProps } from '@core/components/form/skForm'
import { FormFields } from '@core/formFields/formFields'
import { ColProps } from 'antd'

export class FormContext {
  fields = new FormFields()
  labelCol: ColProps
  wrapperCol: ColProps

  constructor(public props: SkFormProps) {
    if (props.layout === 'horizontal') {
      if (!props.labelCol && !props.wrapperCol) {
        this.labelCol = { span: 8 }
        this.wrapperCol = { span: 16 }
      }
    }
  }
}

export const useFormContext = () => React.useContext<FormContext>(SkFormContext)

export const SkFormContext = React.createContext<FormContext>(null)

export const SkFormProvider = ({ formContext, children }: { formContext: FormContext; children: React.ReactNode }) => {
  const contextValue = useObjectMemo(formContext)
  return <SkFormContext.Provider value={contextValue}>{children}</SkFormContext.Provider>
}

import React, { useEffect, useState } from 'react'
import { coreStore } from '@core/store/coreStore'
import { SkPageLoader } from '@core/components/page'
import { referentialStore } from '@common/models/referential/referentialStore'
import { observer } from 'mobx-react-lite'
import { useIsMobile } from '@core/utils/hooks/useIsMobile'
import { SkIconUilBars, SkIconUilMultiply } from '@core/global/icons'
import { useToggle } from '@core/utils/hooks/useToggle'
import { Drawer } from 'antd'
import classNames from 'classnames'
import { SkLogoAndBrand } from '@/layouts/default/skLogoAndBrand'
import { rootStore } from '@/stores/rootStore'
import SkAsideApp from '@/layouts/default/aside/skAsideApp'

export const SkLoadMeAndReferential = observer(function SkLoadMeAndReferential(props: { children: React.ReactNode }) {
  const isMobile = useIsMobile()

  useEffect(() => {
    rootStore.isUiMobile = isMobile
    if (referentialStore.data) {
      rootStore.shortcutsStore.load()
      rootStore.menuStore.loadLinks()
    }
  }, [isMobile, referentialStore.data, coreStore.eventStore.whenChangeUser])

  if (!coreStore.meStore.data) {
    if (!coreStore.meStore.isLogout && !coreStore.meStore.isLoading && !coreStore.meStore.isError) {
      coreStore.meStore
        .$get()
        .then()
        .catch(() => console.log('user not loaded'))
    }
    return <SkPageLoader isLoading />
  }
  if (!referentialStore.data) {
    if (!referentialStore.isLoading) {
      referentialStore
        .$get(rootStore.eventStore.onMessage)
        .then()
        .catch(() => console.log('referential not loaded'))
    }
    return <SkPageLoader isLoading />
  }

  return <>{props.children}</>
})

const SkDefaultLayout = observer(function SkDefaultLayout(props: { children: React.ReactNode }) {
  const isMobile = useIsMobile()
  const [onMenuVisible, setOnMenuVisible] = useState(false)

  return (
    <SkLoadMeAndReferential>
      {isMobile && <SkHeaderMobile onMenuVisible={setOnMenuVisible} />}
      <div className={classNames('flex min-h-full flex-row')}>
        {!isMobile && <SkAsideApp />}
        <div className={classNames('w-full', { 'overflow-hidden': onMenuVisible })}>{props.children}</div>
      </div>
    </SkLoadMeAndReferential>
  )
})

const SkHeaderMobile = ({ onMenuVisible }: { onMenuVisible: (visible: boolean) => void }) => {
  const [open, setOpen] = useToggle()

  useEffect(() => {
    const handleRouteChange = () => {
      if (open) toggleMenuVisible(false)
    }
    coreStore.routerStore.events.on('routeChangeStart', handleRouteChange)

    return () => {
      coreStore.routerStore.events.off('routeChangeStart', handleRouteChange)
    }
  }, [open])

  const toggleMenuVisible = (value: boolean) => {
    setOpen(value)
    onMenuVisible(value)
  }

  return (
    <>
      <div className="bg-dark flex items-center">
        <a className="z-10 justify-start p-3 text-white" onClick={() => toggleMenuVisible(true)}>
          <SkIconUilBars />
        </a>
        <div className="z-0 -ml-20 flex-grow items-center">
          <SkLogoAndBrand />
        </div>
      </div>
      <Drawer
        open={open}
        className="skDrawerMenu"
        title={
          <div className="z-0 -ml-20">
            <SkLogoAndBrand />
          </div>
        }
        placement="left"
        width="100%"
        onClose={() => toggleMenuVisible(false)}
        closeIcon={<SkIconUilMultiply />}
        destroyOnClose={true}
      >
        <SkAsideApp />
      </Drawer>
    </>
  )
}

export default SkDefaultLayout

import { Warehouse } from '@common/models/warehouse/warehouse'
import { makeObservable, observable } from 'mobx'
import { Variant } from '@common/models/variant/variant'
import { AbstractLabel } from '@core/models/abstractLabel'

export class WarehouseVariant extends AbstractLabel {
  @observable
  warehouse: Warehouse
  @observable
  warehouseId: number
  @observable
  variant: Variant
  @observable
  stockCount: number
  @observable
  stock: number
  @observable
  lowThreshold: number
  @observable
  alertThreshold: number
  @observable
  preAlertThreshold: number
  @observable
  replenishmentThreshold: number
  @observable
  replenishmentQuantity: number

  constructor(data?: Partial<WarehouseVariant>) {
    super(data)
    makeObservable(this)
    Object.assign(this, data)
    if (this.stockCount === undefined) this.stockCount = 0
    if (this.warehouse) {
      this.warehouse = new Warehouse(this.warehouse)
    }
    if (this.variant) {
      this.variant = new Variant(this.variant)
    }
  }
}

import { makeAutoObservable, runInAction } from 'mobx'
import { rootStore } from '@/stores/rootStore'
import { VariantTrackedStore } from '@common/models/variantTracked/variantTrackedStore'
import { VariantTracked } from '@common/models/variantTracked/variantTracked'
import { Message } from '@core/service/message'
import { Tools } from '@core/utils/tools'
import { referentialStore } from '@common/models/referential/referentialStore'
import { VariantStore } from '@common/models/variant/variantStore'
import { coreStore } from '@core/store/coreStore'
import { CodeBundleEnum } from '@common/global/enums'

export class HandleKeyStore {
  tempScanBarcode = ''
  lastBarcode = ''

  constructor() {
    makeAutoObservable(this)
    document.addEventListener('keypress', this.handleKeyPress)
    document.addEventListener('keypress', this.handleKeyDown)
  }

  removeLastBarcode() {
    this.lastBarcode = null
  }

  removeListeners = () => {
    document.removeEventListener('keypress', this.handleKeyPress)
    document.removeEventListener('keypress', this.handleKeyDown)
  }

  handleKeyDown = event => {
    if (
      coreStore.hasAtLeastOneModalOpen ||
      !rootStore.appStore.canOpenModalShortcuts ||
      event.target?.tagName === 'INPUT' ||
      event.target?.tagName === 'TEXTAREA' ||
      event.originalTarget?.tagName === 'INPUT' ||
      event.originalTarget?.tagName === 'TEXTAREA' ||
      (event.path && event.path[0].toString().indexOf('HTMLInputElement') > -1) ||
      (event.path && event.path[0].toString().indexOf('HTMLTextAreaElement') > -1)
    )
      return
    if (!event.metaKey && event.key === 'a' && referentialStore.data.tenantProperties?.hasBundle(CodeBundleEnum.stock)) {
      setTimeout(() => rootStore.shortcutsStore.openModal())
    } else if (!event.metaKey && event.key === 'r') {
      setTimeout(() => rootStore.appStore.openModalSearchStore())
    }
  }

  handleKeyPress = event => {
    if (event.key === '[' || event.key === '{') {
      this.tempScanBarcode = event.key
      event.preventDefault()
      setTimeout(() => (this.tempScanBarcode = ''), 500)
    } else if (event.key === ']' || event.key === '}') {
      const barcode = this.tempScanBarcode.substring(1, this.tempScanBarcode.length)
      this.lastBarcode = barcode
      if (!referentialStore.data.tenantProperties.barcodeProperties.lengthIsAllowed(barcode.length)) {
        Message.error(`Ce code-barres n’est pas autorisée (longueur ${barcode.length})`)
        this.tempScanBarcode = ''
      } else if (Tools.isMacAddress(barcode)) {
        Message.error('Article non ajouté car le code barre scanné est une adresse MAC')
        this.tempScanBarcode = ''
      } else if (event.target.id !== 'skHeaderSearchModal') {
        this.addByBarcode(barcode).then()
        this.tempScanBarcode = ''
      }
      event.preventDefault()
    } else if (this.tempScanBarcode.length) {
      this.tempScanBarcode += event.key
      event.preventDefault()
    }
  }

  addByBarcode = async (barcode: string) => {
    if (rootStore.appStore.waitingVariantsTracked.some(x => x.barcode === barcode) || rootStore.appStore.waitingVariants.some(x => x.barcodes.some(y => y === barcode))) {
      Message.error('Cet exemplaire est déjà ajouté')
      return
    }
    const variantTrackedStore = new VariantTrackedStore()
    const variantStore = new VariantStore()
    const promises = await Promise.all([
      variantTrackedStore.$first({ params: { filterInit: { barcode: barcode } } }),
      variantStore.$first({ params: { customFilterInit: { barcode: barcode } } })
    ])
    runInAction(() => {
      const variantTracked = promises[0] ?? new VariantTracked({ barcode: barcode })
      const variant = promises[1]
      if (!variant) {
        // add variantTracked if no variant
        rootStore.appStore.waitingVariantsTracked.push(variantTracked)
        this.setNextStepAfterScanBarcode(variantTracked)
      } else {
        if (variant.article.isSingleTracked) {
          Message.error('Veuillez scanner le numéro de série')
          return
        }
        rootStore.appStore.waitingVariants.push(variant)
      }
    })
  }

  setNextStepAfterScanBarcode = (variantTracked: VariantTracked) => {
    if (!rootStore.shortcutsStore.modalIsOpen && rootStore.appStore.canOpenModalShortcuts && !coreStore.hasAtLeastOneModalOpen && !rootStore.appStore.modalSearchStoreIsOpen) {
      setTimeout(() => {
        if (variantTracked.lastStockTracking?.action && rootStore.shortcutsStore.items.some(x => x.canBeAfterThisActionId === variantTracked.lastStockTracking.action.type.id)) {
          rootStore.shortcutsStore.indexSelected = rootStore.shortcutsStore.items.findIndex(x => x.canBeAfterThisActionId === variantTracked.lastStockTracking.action.type.id)
        }
        rootStore.shortcutsStore.openModal()
      })
    }
  }
}

import { makeObservable, observable } from 'mobx'
import { Section } from '@core/models/section'
import { City } from '@core/models/city'
import { ZipCode } from '@core/models/zipCode'
import { AbstractLabel } from '@core/models/abstractLabel'

export class AbstractAddress extends AbstractLabel {
  @observable
  supplement: string
  @observable
  line1: string
  @observable
  line2: string
  @observable
  city: City
  @observable
  section: Section
  @observable
  zipCode: ZipCode
  @observable
  comment: string
  @observable
  fullLabel: string

  constructor(data?: Partial<AbstractAddress>) {
    super(data)
    makeObservable(this)
    Object.assign(this, data)
  }
}

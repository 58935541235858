import { AbstractLabel } from '@core/models/abstractLabel'
import { Province } from '@core/models/province'
import { Section } from '@core/models/section'
import { ZipCode } from '@core/models/zipCode'
import { action, makeObservable, observable } from 'mobx'

export class City extends AbstractLabel {
  @observable
  province: Province
  @observable
  sections: Section[]
  @observable
  zipCodes: ZipCode[]

  constructor(data?: Partial<City>) {
    super(data)
    makeObservable(this)
    Object.assign(this, data)
    if (this.province) {
      this.province = new Province(this.province)
    }
    if (this.sections) {
      this.sections.forEach((item, index) => {
        this.sections[index] = new Section(item)
      })
      this.sections.sort((x, y) => x.label.localeCompare(y.label))
    }
    if (this.zipCodes) {
      this.zipCodes.forEach((item, index) => {
        this.zipCodes[index] = new ZipCode(item)
      })
      this.zipCodes.sort((x, y) => x.label.localeCompare(y.label))
    }
  }
  @action
  addSection = () => {
    if (!this.sections) this.sections = []
    this.sections.push(new Section())
  }

  @action
  removeSection = index => {
    this.sections.splice(index, 1)
  }

  @action
  addZipCode = () => {
    if (!this.zipCodes) this.zipCodes = []
    this.zipCodes.push(new ZipCode())
  }

  @action
  removeZipCode = index => {
    this.zipCodes.splice(index, 1)
  }
}

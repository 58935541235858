import React, { useEffect, useState } from 'react'
import { deepEqual } from 'fast-equals'

/**
 * componentDidMount in hook way
 *
 * @export
 * @param {() => any} onMount
 * @returns
 */
export function useOnMount(onMount: () => any) {
  return React.useEffect(() => {
    if (onMount) {
      onMount()
    }
  }, [])
}

/**
 * componentWillUnmount in hook way
 *
 * @export
 * @param {() => any} onUnmount
 * @returns
 */
export function useOnUnmount(onUnmount: () => any) {
  return React.useEffect(() => {
    return () => onUnmount && onUnmount()
  }, [])
}

export function useObjectMemo(value, isEqual = deepEqual) {
  const [memo, setMemo] = useState(value)
  useEffect(() => {
    if (!isEqual(value, memo)) setMemo(value)
  }, [value])
  return memo
}

import { action, computed, makeObservable, observable } from 'mobx'
import { AbstractItem } from '@core/models/abstractItem'
import { FieldTypeEnum } from '@common/global/enums'
import { AttributeFileFormat } from '@common/models/attribute/attributeFileFormat'
import { FileFormat } from '@common/models/file/fileFormat'

export class Attribute extends AbstractItem {
  @observable
  label: { fr: string }
  @observable
  label2: { fr: string }
  @observable
  description: { fr: string }
  @observable
  code: string
  @observable
  fieldType: { id: FieldTypeEnum; label?: string }
  @observable
  isRequired: boolean
  @observable
  codePlacement: string
  @observable
  attributeFileFormats: AttributeFileFormat[]

  constructor(data?: Partial<Attribute>) {
    super(data)
    Object.assign(this, data)
    makeObservable(this)
  }

  @computed
  get labelT() {
    if (this.label?.fr) return this.label.fr
    return ''
  }

  @computed
  get label2T() {
    if (this.label2?.fr) return this.label2.fr
    return ''
  }

  @computed
  get descriptionT() {
    if (this.description?.fr) return this.description.fr
    return ''
  }

  @action
  seFieldType = (id: FieldTypeEnum) => {
    this.fieldType = { id }
  }

  @action
  onSelectFileFormat = (fileFormat: FileFormat) => {
    if (!this.attributeFileFormats) this.attributeFileFormats = []
    if (!this.attributeFileFormats.some(x => x.fileFormat.id === fileFormat.id)) {
      this.attributeFileFormats.push(new AttributeFileFormat({ fileFormat }))
    }
  }
  @action
  onDeselectFileFormat = (fileFormat: FileFormat) => {
    this.attributeFileFormats = this.attributeFileFormats.filter(x => x.fileFormat.id !== fileFormat.id)
  }
}

import * as React from 'react'
import { Rule } from 'rc-field-form/lib/interface'
import { SizeType } from 'antd/es/config-provider/SizeContext'
import { useEffect, useState } from 'react'
import { SkSelect } from '@core/components'
import { Input } from 'antd'
import { runInAction } from 'mobx'
import { InitInput } from '@core/utils/initInput'
import { AbstractLabel } from '@core/models/abstractLabel'
import { SkBtn } from '@core/components/btns'

type IProps<T> = {
  label?: string
  placeholder?: string
  rules?: Rule[]
  size?: SizeType
  min?: number
  disabled?: boolean
  autoFocus?: boolean
  inputClassName?: string
  required?: boolean
  open?: boolean
} & (
  | { id?: string; value?: never; property: (x: T) => number; model: T; onChange?: (value: number) => void }
  | {
      id: string
      value: number
      property?: never
      model?: never
      onChange: (value: number) => void
    }
)
SkNumberStepSelect.defaultProps = {
  min: 0
}

export function SkNumberStepSelect<T>(props: IProps<T>) {
  const { id } = InitInput(props, 'SkNumberStepSelect')
  const [items, setItems] = useState<AbstractLabel[]>([])
  const [value, setValue] = useState<number>(props.value)
  const [valueText, setValueText] = useState<number>(props.value)
  const [showText, setShowText] = useState(false)
  const [showBtnValidText, setShowBtnValidText] = useState(false)
  const [autoFocus, setAutoFocus] = useState(false)

  useEffect(() => {
    const array: any[] = []
    for (let i = props.min; i < 10; i++) {
      array.push({ id: i, label: i })
    }
    array.push({ id: 10, label: '+10' })
    setItems(array)
  }, [])

  const onChangeText = (e: any) => {
    let { value } = e.target
    value = value?.replace(/[^0-9.]+/g, '')
    if (!isNaN(value) && value !== '') {
      setValueText(parseInt(value))
      return
    }
    setValueText(null)
  }

  const validText = () => {
    const val = valueText ?? 1
    runInAction(() => props.onChange(val))
    setShowBtnValidText(false)
    if (val < 10) setShowText(false)
  }

  const onChangeSelect = (value: any) => {
    if (value?.label === '+10') {
      setValueText(null)
      setShowText(true)
      setShowBtnValidText(true)
      setAutoFocus(true)
      return
    }
    runInAction(() => props.onChange(value?.id))
  }

  const handleKeyDown = (e: any) => {
    if (e.key === 'Enter') {
      e.target.blur()
      validText()
    }
  }

  useEffect(() => {
    setValue(props.value)
    if (props.value > 9) setShowText(true)
  }, [props.value])

  return (
    <>
      {!showText ? (
        <div className="w-14">
          <SkSelect
            size={props.size}
            value={value}
            items={items}
            onChange={onChangeSelect}
            showArrow
            required
            showSearch={false}
            autoFocus={props.autoFocus}
            open={props.open}
            disabled={props.disabled}
            id={`numberStepSelect-${id}`}
          />
        </div>
      ) : (
        <div className="flex items-center justify-start">
          <Input
            size={props.size}
            autoFocus={autoFocus}
            value={valueText}
            onKeyDown={handleKeyDown}
            className="mr-3 w-[56px] text-center"
            onChange={onChangeText}
            onFocus={() => setShowBtnValidText(true)}
            disabled={props.disabled}
            id={`numberStepText-${id}`}
          />
          {showBtnValidText && (
            <SkBtn submit size="small" onClick={validText} id={`validText-${id}`}>
              mettre à jour
            </SkBtn>
          )}
        </div>
      )}
    </>
  )
}

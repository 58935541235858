import { AbstractLabel } from './abstractLabel'
import { observable } from 'mobx'
import { PersonTypeEnum } from '@core/global/enums'
import { User } from 'skazy-gescom-common/models/user/user'

export class AbstractPerson extends AbstractLabel {
  @observable
  title: AbstractLabel
  @observable
  lastName: string
  @observable
  firstName: string
  @observable
  phoneNumber: string
  @observable
  mobilePhoneNumber: string
  @observable
  fax: string
  @observable
  email: string
  @observable
  isInvoiceRecipient: boolean
  @observable
  user: User
  @observable
  driverLicenseNumber: string
  @observable
  department: AbstractLabel
  @observable
  externalRef: string
  @observable
  personType?: AbstractLabel
  @observable
  legalStatus?: AbstractLabel
  @observable
  function?: AbstractLabel
  @observable
  account: AbstractLabel
  @observable
  ridet: string
  @observable
  rcsNumber: string
  @observable
  rcsCity: string
  @observable
  ape: string
  @observable
  siret: string
  @observable
  socialCapital: string
  @observable
  websiteUrl: string

  constructor(data?: Partial<AbstractPerson>) {
    super(data)
    Object.assign(this, data)
    if (this.title) {
      this.title = new AbstractLabel(this.title)
    }
    if (this.department) {
      this.department = new AbstractLabel(this.department)
    }
    if (this.personType) {
      this.personType = new AbstractLabel(this.personType)
    }
    if (this.account) {
      this.account = new AbstractLabel(this.account)
    }
    if (this.function) {
      this.function = new AbstractLabel(this.function)
    }
    if (this.id && (!this.label || this.label.trim().length === 0) && this.personType?.id === PersonTypeEnum.person) {
      this.label = `${this.firstName} ${this.lastName}`.trim()
    }
  }
}

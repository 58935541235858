import React, { ReactNode } from 'react'
import classNames from 'classnames'
import { SizeType } from 'antd/es/config-provider/SizeContext'
import { IPropsInput, InitInput } from '@core/utils/initInput'
import { useEditContext } from '@core/contexts/editContext'
import { Checkbox } from 'antd'
import { SkIconUilArrowRight } from '@core/global/icons'
import { ListenInput } from '@core/utils/listenInput'

type IProps<T, Y> = IPropsInput<T, Y> & {
  title?: React.ReactNode
  placeholder?: string
  size?: SizeType
  required?: boolean
  autoFocus?: boolean
  className?: string
  onClick?: () => void
  textLeft?: boolean
  withArrow?: boolean
  imageLeft?: ReactNode
  onChange?: (value: Y) => void
}

export function SkBtnSwitch<T>(props: IProps<T, boolean>) {
  const editContext = useEditContext()
  const { id } = InitInput(props, 'SkBtnSwitch')
  const { onChangeInput, value, setValue } = ListenInput(props, id)

  const onChange = () => {
    if (props.onClick) {
      props.onClick()
    } else {
      const val = !value
      onChangeInput(val)
      setValue(val)
    }
    editContext?.checkIfItemIsItemChanged()
  }

  return (
    <button
      id={props.id}
      autoFocus={props.autoFocus}
      type="button"
      onClick={(props.withArrow || props.label) && onChange}
      className={classNames(
        props.className,
        'group cursor-pointer items-center justify-between rounded p-4',
        'focus:ring focus:ring-2 focus:ring-blue-700',
        { 'text-left': props.textLeft },
        { flex: props.title },
        { 'text-primary bg-blue-50': value },
        { 'bg-gray-100 hover:bg-blue-50': !value }
      )}
    >
      <div className={classNames('flex', { 'justify-center': !props.textLeft })}>
        {props.imageLeft && (
          <div className="flex-center pr-4" style={{ width: 120 }}>
            {props.imageLeft}
          </div>
        )}
        <div style={props.imageLeft && { width: 'calc(100% - 120px)' }}>
          {props.title && (
            <div className={classNames('font-bold', { 'pb-2 ': props.label })}>
              {props.withArrow ? (
                <>{props.title}</>
              ) : (
                <Checkbox checked={value} onClick={onChange}>
                  {props.title}
                </Checkbox>
              )}
            </div>
          )}
          {props.label && <span className={classNames({ 'text-gray-500': props.title })}>{props.label}</span>}
        </div>
      </div>

      {props.withArrow && (
        <div className="group-hover:text-primary pl-3">
          <SkIconUilArrowRight className="!w-auto" width={26} height={26} />
        </div>
      )}
    </button>
  )
}

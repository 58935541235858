import React, { useEffect } from 'react'
import { useRouter } from 'next/router'
import { coreStore } from '@core/store/coreStore'
import { observer } from 'mobx-react-lite'

export const SkRouterWrapper = observer(function SkRouterWrapper(props: { children }) {
  const router = useRouter()

  const handleRouteChange = url => {
    coreStore.addUrlToRouteHistory(url)
  }

  useEffect(() => {
    handleRouteChange(router.asPath)
    router.events.on('routeChangeStart', handleRouteChange)

    return () => {
      router.events.off('routeChangeStart', handleRouteChange)
    }
  }, [])

  return <>{props.children}</>
})

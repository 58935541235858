import * as Sentry from '@sentry/nextjs'

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN

Sentry.init({
  enabled: process.env.SENTRY === 'true' || process.env.SENTRY === true,
  environment: process.env.SENTRY_ENV,
  dsn: SENTRY_DSN || 'https://949fe05f8d274eec8585b275e1fc63da@o1090901.ingest.sentry.io/6107419',
  tracesSampleRate: 1.0,
  ignoreErrors: [
    'ResizeObserver loop limit exceeded',
    '_error.js getInitialProps missing data at path: /service-worker.js',
    'Skazy: action impossible',
    'Non-Error promise rejection captured'
  ]
})

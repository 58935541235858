import React from 'react'
import { SkBtnCircle } from '@core/components/btns'
import { Dropdown } from 'antd'
import { SkIconUilEllipsisH } from '@core/global/icons'
import { SizeType } from 'antd/es/config-provider/SizeContext'
import { MenuProps } from 'antd/lib/menu'

export function SkBtnCircleMore(props: { menu: MenuProps; color?: 'blue' | 'gray' | 'gray2' | 'transparent' | 'white'; size?: SizeType; id: string; loading?: boolean }) {
  const { menu, ...rest } = props
  return (
    <Dropdown
      menu={props.menu}
      trigger={['click']}
      getPopupContainer={triggerNode => {
        return triggerNode.parentNode as HTMLElement
      }}
    >
      <SkBtnCircle id={props.id} color={props.color} size={props.size} onClick={e => e.stopPropagation()} icon={<SkIconUilEllipsisH />} loading={props.loading} {...rest} />
    </Dropdown>
  )
}

import React from 'react'
import { Dropdown } from 'antd'
import { SkIconUilAngleDown } from '@core/global/icons'
import { ItemType } from 'antd/lib/menu/hooks/useItems'

interface IProps {
  id: string
  label: string
  className?: string
  trigger?: ('click' | 'hover' | 'contextMenu')[]
  menuItems: ItemType[]
}

export function SkLinkMenu(props: IProps) {
  return (
    <Dropdown trigger={props.trigger} menu={{ items: props.menuItems }}>
      <a id={props.id} className={`ant-dropdown-link flex items-center justify-start font-medium hover:underline ${props.className}`} onClick={e => e.preventDefault()}>
        {props.label} <SkIconUilAngleDown width="18" height="18" />
      </a>
    </Dropdown>
  )
}

SkLinkMenu.defaultProps = {
  trigger: ['click']
}

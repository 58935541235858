import React, { useState } from 'react'
import { Checkbox } from 'antd'

import { SkDrawer, SkSearch } from '@core/components'
import { observer } from 'mobx-react-lite'
import { ApiStore } from '@core/api/apiStore'
import { AbstractItem } from '@core/models/abstractItem'

interface IProps<T extends AbstractItem> {
  store: ApiStore<T>
}

export const SkPageListColFilters = observer(function SkPageListColFilters<T extends AbstractItem>(props: IProps<T>) {
  const [items, setItems] = useState(props.store.filtersAndViews.items)

  function queryItems(terms: string) {
    if (props.store.filtersAndViews.items.length) {
      const data: any[] = []
      props.store.filtersAndViews.items.forEach(x => {
        if (x.label.toLowerCase().includes(terms.toLowerCase())) {
          data.push(x)
        }
      })
      setItems(data)
    } else {
      setItems(props.store.filtersAndViews.items)
    }
  }

  return (
    <SkDrawer title="Filtres" onClose={() => props.store.filtersAndViews.toggleFiltersOpen()}>
      <div className="p-5">
        <div className="pb-8">
          <SkSearch id="SkPageListColFilters_SearchFilter" autoFocus onChange={x => queryItems(x)} />
        </div>
        {items?.map((item, index) => (
          <div key={index} className="pb-4">
            <Checkbox checked={item.isActive} onChange={() => props.store.filtersAndViews.toggleFilterIsActive(item)}>
              {item.label}
            </Checkbox>
          </div>
        ))}
      </div>
    </SkDrawer>
  )
})

import { AbstractLabel } from '@core/models/abstractLabel'
import { coreStore } from '@core/store/coreStore'
import { referentialStore } from '@common/models/referential/referentialStore'

export class Photo extends AbstractLabel {
  filename: string
  originalFileName: string
  url: string
  idx: number

  constructor(data?: Partial<Photo>) {
    super(data)
    Object.assign(this, data)
    this.url = `${coreStore.paramAppStore.mediaUrl}/${referentialStore.data.tenantProperties.id}/${this.filename}`
  }
}

import { computed, makeObservable, observable } from 'mobx'
import { StockTracking } from '@common/models/stockTracking/stockTracking'
import { Price } from '@common/models/price/price'
import { Guid } from '@core/utils/guid'
import { Variant } from '@common/models/variant/variant'
import { AbstractUi } from '@core/models/abstractItem'
import { AbstractLabel } from '@core/models/abstractLabel'
import { Tools } from '@core/utils/tools'

export class VariantTracked extends AbstractLabel {
  @observable
  variant: Variant
  @observable
  barcode: string
  @observable
  lastStockTracking: StockTracking
  @observable
  totalQuantity: number
  lastInfo: VariantTrackedInfo
  declare ui: VariantTrackedUi
  cost: Price
  totalPriceIncludingTaxes: Price
  isANewLot = false

  isFromSubscription = false

  constructor(data?: Partial<VariantTracked>) {
    super(data)
    makeObservable(this)
    Object.assign(this, data)
    if (this.variant) {
      this.variant = new Variant(this.variant)
    }
    if (!this.barcode && this.variant?.article?.isLotTracked) {
      this.isANewLot = true
    }
    if (this.cost) {
      this.cost = new Price(this.cost)
    }
    if (this.lastStockTracking) {
      this.lastStockTracking = new StockTracking(this.lastStockTracking)
    }
    this.ui = new VariantTrackedUi()
  }

  @computed
  public get totalQuantityWithUnit(): string {
    const quantity = !Tools.isNullOrUndefined(this.totalQuantity) ? this.totalQuantity : this.lastStockTracking.action.objectsCount
    return [quantity, this.variant?.article?.defaultUnit?.unit.label].filter(word => !!word).join(' ')
  }
}

class VariantTrackedInfo {
  variantTrackedState: AbstractLabel
  isComplete: boolean
  comment: string
}

export class VariantTrackedUi extends AbstractUi {
  missingObjectsCount? = 0
  canEditBarcode? = false
  guid?: string

  constructor(data?: Partial<VariantTrackedUi>) {
    super()
    Object.assign(this, data)
    this.guid = Guid.newGuid()
  }
}

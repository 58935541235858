export class Guid {
  public static newGuid() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => this.generateRandomChar(c))
  }

  public static newGuid6Char() {
    return 'xxxxxx'.replace(/[xy]/g, c => this.generateRandomChar(c))
  }

  public static newGuid6Number() {
    return 'xxxxxx'.replace(/[x]/g, () => this.generateRandomNumber())
  }

  private static generateRandomChar(inputChar: string) {
    const random = (Math.random() * 16) | 0
    const result = inputChar === 'x' ? random : (random & 0x3) | 0x8
    return result.toString(16)
  }

  private static generateRandomNumber(): string {
    return '' + Math.floor(Math.random() * 10)
  }
}

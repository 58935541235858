import React, { useState } from 'react'
import { ApiStore } from '@core/api/apiStore'
import styles from './skPageEditBackButton.module.scss'
import { SkIconUilAngleLeft, SkIconUilArrowLeft } from '@core/global/icons'
import { coreStore } from '@core/store/coreStore'
import { useIsMobile } from '@core/utils/hooks/useIsMobile'

interface IProps {
  store: ApiStore<any>
  simple?: boolean
}

export const SkPageEditBackButton = (props: IProps) => {
  const [previousRouteUrl] = useState(coreStore.previousRouteUrl)
  const isMobile = useIsMobile()

  const goBack = () => {
    if (previousRouteUrl) {
      coreStore.routerStore.push(previousRouteUrl)
    } else {
      coreStore.routerStore.push(props.store.route)
    }
  }

  return (
    <>
      {!props.simple ? (
        <a
          className="bg-blue2-200 border-primary inline-flex items-center rounded rounded-l-none border border-l-0 py-1 pl-2 pr-3 font-medium hover:underline"
          onClick={() => goBack()}
        >
          <SkIconUilAngleLeft width="20" height="20" className="pr-1" />
          {props.store.objectName}
          <span className={`wrapperIcon pl-2 ${styles.wrapperIcon}`}>
            <props.store.icon />
          </span>
        </a>
      ) : (
        <div className="relative flex items-center">
          <a className="absolute" style={{ left: !isMobile && '-35px' }} onClick={() => goBack()}>
            <SkIconUilArrowLeft width={isMobile ? 35 : 30} height={isMobile ? 35 : 30} />
          </a>
        </div>
      )}
    </>
  )
}

SkPageEditBackButton.defaultProps = {
  simple: false
}

import { AbstractItem } from '@core/models/abstractItem'
import { makeObservable, observable } from 'mobx'
import { AbstractAddress } from '@core/models/abstractAddress'

export class BankInformation extends AbstractItem {
  @observable
  bankName: string
  @observable
  agencyName: string
  @observable
  postalBoxNumber: string
  @observable
  address: AbstractAddress

  constructor(data?: Partial<BankInformation>) {
    super(data)
    Object.assign(this, data)
    makeObservable(this)
    if (this.address) this.address = new AbstractAddress(this.address)
  }
}

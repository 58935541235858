import { Photo } from '@common/models/photo/photo'
import { makeObservable, observable } from 'mobx'
import { AbstractLabel } from '@core/models/abstractLabel'
import { Package } from '@common/models/package/package'
import { AbstractUi } from '@core/models/abstractItem'
import { Tag } from '@common/models/tag/tag'
import { PackageGroupTag } from '@common/models/packageGroup/packageGroupTag'
import { DiscountCodeEnum } from '@common/global/enums'
import { EstimateTools } from '@common/models/estimate/estimateTools'

export class PackageGroup extends AbstractLabel {
  @observable
  idx: number
  @observable
  color: string
  @observable
  description: string
  @observable
  photos: Photo[]
  @observable
  packages: Package[]
  @observable
  canBeCustom: boolean
  @observable
  packageGroupTags: PackageGroupTag[]
  declare ui: Ui
  @observable
  withFreeShipping: boolean
  printAdditionalInfo: string

  constructor(data?: Partial<PackageGroup>) {
    super(data)
    makeObservable(this)
    Object.assign(this, data)
    if (this.photos) {
      this.photos.forEach((item, index) => {
        this.photos[index] = new Photo(item)
      })
      this.photos = this.photos.slice().sort((x, y) => {
        return x.idx - y.idx
      })
    }
    if (this.packages) {
      this.packages.forEach((item, index) => {
        this.packages[index] = new Package(item)
      })
      this.packages.sort((x, y) => {
        return y.packageTags.length - x.packageTags.length
      })
    }
    if (this.packageGroupTags) {
      this.packageGroupTags.forEach((item, index) => {
        this.packageGroupTags[index] = new PackageGroupTag(item)
      })
    }
    this.ui = new Ui(this)
  }
}

class Ui extends AbstractUi {
  tags: Tag[] = []
  @observable
  packageSelected: Package
  @observable
  quantity = 1

  constructor(data?: Partial<PackageGroup>) {
    super()
    makeObservable(this)
    data?.packages?.forEach(pack => {
      pack.packageTags?.forEach(packageTag => {
        if (!this.tags.some(x => x.id === packageTag.tag.id)) {
          this.tags.push(packageTag.tag)
        }
      })
    })
    this.tags = this.tags.sort((x, y) => x.idx - y.idx)
    if (data?.packages?.length) this.packageSelected = data.packages[0]
  }

  getTotalPriceIncludingTaxes = (globalDiscountPercentage = 0) => {
    return EstimateTools.calculatePrice(
      this.quantity,
      this.packageSelected.price,
      this.packageSelected.tax ? [this.packageSelected.tax.rate] : [],
      globalDiscountPercentage,
      this.packageSelected.discount?.type.code as DiscountCodeEnum,
      this.packageSelected.discount?.percentage,
      this.packageSelected.discount?.amount?.amount
    )
  }
}

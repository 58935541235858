import { AbstractItem } from '../models/abstractItem'
import { CancelTokenSource } from 'axios'
import { ColumnType } from 'antd/es/table/interface'
import { makeAutoObservable, makeObservable, observable } from 'mobx'
import React from 'react'

export interface Params {
  [key: string]: any
}

export class QueryParams<T> {
  [key: string]: any
  page?: number
  size?: number = 25
  sort?: string[]
  filter?: RecursivePartial<T>
  customFilter?: any
  filterInit?: RecursivePartial<T>
  customFilterInit?: unknown
  constructor() {
    makeAutoObservable(this)
  }
}

export class ActionLoader {
  isLoading = false
  constructor() {
    makeAutoObservable(this)
  }
}

export class QueryFilter {
  filter?: any
  customFilter?: any
  sort?: string[]
  subUrlPath?: string
}

export class Query<T> {
  subUrlPath?: string
  @observable
  params?: QueryParams<T>
  noCreator?: boolean
  cancelTokenSource?: CancelTokenSource
  hideGlobalMessageError?: boolean
  constructor() {
    makeObservable(this)
    this.params = new QueryParams()
  }
}

export class RequestConfig {
  params?: Params
  cancelTokenSource?: CancelTokenSource
  responseType?: string
  hideGlobalMessageError?: boolean
  multiFormat?: true
  headers?: unknown
}

export interface PaginationResult<T extends AbstractItem> {
  content: T[] | null
  last: boolean
  totalPages: number
  totalElements: number
  size: number
  number: number
  numberOfElements: number
  first: boolean
}

export class Sort {
  key: string
  value: string
}

export interface SkColumnType<T> extends ColumnType<T> {
  sorterName?: (item: T) => unknown
}

export declare type SkSorter<T> = () => number

export type SkLink = {
  label: string
  icon?: any
  permissions: string[]
  hidden?: boolean
  children?: SkLink[]
  mobileAvailable?: boolean
} & (
  | {
      id: string
      path?: never
    }
  | {
      id?: string
      path: string
    }
)

export interface MeStoreI {
  readonly isTenantAdministrator: boolean
  readonly isConnectAs: boolean
  readonly hasRoleAdmin: boolean

  hasPermission(codePermission: string): boolean

  hasCrudPermission(codePermission: string): MePermission

  hasOneOrMoreInPermissions(codePermissions: string[]): boolean
}

export interface MePermission {
  canRead: boolean
  canEdit: boolean
}

export type RecursivePartial<T> = {
  [P in keyof T]?: RecursivePartial<T[P]>
}

export class SkTab {
  label: string
  key: string
  icon?: React.Component
  component: React.ReactNode
  needForCreation: boolean
  needForEditing: boolean
  disabledAfterNextTab?: boolean
  classNamesForContent?: string
}

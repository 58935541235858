import React, { useEffect, useMemo, useState } from 'react'
import { SkSelect } from '@core/components'
import { AbstractLabel } from '@core/models/abstractLabel'
import { FrequencyEnum } from 'skazy-gescom-common/global/enums'

type value = {
  multiple: number
  period: { id: FrequencyEnum }
}

interface IProps {
  id: string
  label: string
  value: value
  onChange: (value: value) => void
}

export function SkFilterFrequency(props: IProps) {
  const [selectValue, setSelectValue] = useState<string>()

  const items = useMemo(() => {
    const items = [new AbstractLabel({ id: `${1}|${FrequencyEnum.month}`, label: 'Tous les mois' })]
    for (let i = 2; i < 7; i++) {
      items.push(new AbstractLabel({ id: `${i}|${FrequencyEnum.month}`, label: `Tous les ${i} mois` }))
    }
    items.push(new AbstractLabel({ id: `1|${FrequencyEnum.year}`, label: 'Tous les ans' }))
    for (let i = 2; i < 7; i++) {
      items.push(new AbstractLabel({ id: `${i}|${FrequencyEnum.year}`, label: `Tous les ${i} ans` }))
    }
    return items
  }, [])

  useEffect(() => {
    if (!props.value) {
      setSelectValue(null)
      return
    }
    setSelectValue(`${props.value.multiple}|${props.value.period.id}`)
  }, [props.value])

  const onSelectChange = (value: string) => {
    if (!value) {
      props.onChange(undefined)
      return
    }
    const values = value.split('|')
    props.onChange({ multiple: Number(values[0]), period: { id: Number(values[1]) } })
  }

  return (
    <>
      <div className="w-full filter">
        <SkSelect label={props.label} placeholder="Sélectionner ..." size="large" value={selectValue} items={items} id={`${props.id}Select`} onChange={onSelectChange} returnId />
      </div>
    </>
  )
}

import { VariantTracked } from '../variantTracked/variantTracked'
import { WarehouseActionLine } from '@common/models/warehouseActionLine/warehouseActionLine'
import { EstimateLine } from '@common/models/estimateLine/estimateLine'
import { Variant } from '@common/models/variant/variant'

export class LineQuantityTools {
  static setNonEditableQuantityToLineIfApplicable(line: WarehouseActionLine | EstimateLine, canAlwaysEditQuantity: boolean) {
    const calc = new LineQuantityCalculator(line.variant, line.variantTracked)
    const canEditQuantity = !calc.shouldQuantityBeFixed() || canAlwaysEditQuantity

    if (!canEditQuantity) {
      line.quantity = calc.calulateFixedQuantity()
      if (line instanceof WarehouseActionLine) {
        line.ui.canEditQuantity = canEditQuantity
      }
    }
  }
}

export class LineQuantityCalculator {
  constructor(private variant: Variant | undefined, private variantTracked: VariantTracked | undefined) {}

  public shouldQuantityBeFixed() {
    return this.shouldQuantityBeSetTo1() || this.shouldQuantityBeSetToLotQuantity()
  }

  public calulateFixedQuantity() {
    if (this.shouldQuantityBeSetToLotQuantity()) {
      return this.calculateLotQuantity()
    }
    if (this.shouldQuantityBeSetTo1()) {
      return 1
    }
  }

  private calculateLotQuantity() {
    // Note : line quantity is equal to totalQuantity because a lot cannot be splitted in two locations
    return this.variantTracked?.totalQuantity
  }

  private shouldQuantityBeSetToLotQuantity() {
    return this.variantTracked?.variant?.article?.isLotTracked && !this.variantTracked?.isANewLot
  }

  private shouldQuantityBeSetTo1() {
    return this.variantTracked?.variant?.article?.isSingleTracked || this.variant?.article?.isSingleTracked
  }
}

import { EventStore } from '@core/store/eventStore'
import { ParamAppStore } from '@core/store/paramAppStore'
import { MeStore } from '@core/store/meStore'
import { AbstractUser } from '@core/models/abstractUser'
import { LocalStorage } from '@core/utils/localStorage'
import { Guid } from '@core/utils/guid'
import { QueryClient } from '@tanstack/react-query'

export class CoreStore {
  eventStore = new EventStore()
  paramAppStore = new ParamAppStore()
  meStore: MeStore<AbstractUser>
  isLoaded: boolean
  interceptError: (error: any) => void | null
  globalHttpHeaders: any
  routerCanGoBack: boolean
  countModalOpen = 0
  queryClient: QueryClient | null

  private _routeHistoryUrls: string[] = []

  get hasAtLeastOneModalOpen() {
    return this.countModalOpen > 0
  }

  get previousRouteUrl() {
    return this._routeHistoryUrls.length > 1 ? this._routeHistoryUrls[this._routeHistoryUrls.length - 2] : null
  }

  private _routerStore: any
  get routerStore(): any {
    if (!this._routerStore) throw 'Skazy error config: you need to set routerStore in CoreStore'
    return this._routerStore
  }

  set routerStore(data: any) {
    this._routerStore = data
  }

  get sessionUid(): string {
    let uid = LocalStorage.getItem('sessionUid')
    if (uid) return uid
    uid = Guid.newGuid()
    LocalStorage.setItem('sessionUid', uid)
    return uid
  }

  get keyPathName(): string {
    return this.routerStore.pathname.replace(/-|\//g, '_')
  }

  init<T extends AbstractUser>(
    applicationName: string,
    meStore: MeStore<T>,
    routerStore: any,
    withEventSource: boolean,
    interceptError: ((error: any) => void) | null,
    globalHttpHeaders: any,
    queryClient: QueryClient = null
  ) {
    this.paramAppStore.applicationName = applicationName
    this.meStore = meStore
    this._routerStore = routerStore
    this.eventStore.withEventSource = withEventSource
    this.isLoaded = true
    this.interceptError = interceptError
    this.globalHttpHeaders = globalHttpHeaders
    this.queryClient = queryClient
  }

  addUrlToRouteHistory(url: string) {
    if (this._routeHistoryUrls.length && this._routeHistoryUrls[this._routeHistoryUrls.length - 1] === url) return
    this._routeHistoryUrls.push(url)
    if (this._routeHistoryUrls.length > 2) this._routeHistoryUrls.shift()
  }
}

export const coreStore = new CoreStore()

import { AbstractLabel } from '@core/models/abstractLabel'
import { makeObservable } from 'mobx'
import { Tools } from '@core/utils/tools'

export class Document extends AbstractLabel {
  filename: string
  originalFileName: string
  idx: number

  constructor(data?: Partial<Document>) {
    super(data)
    makeObservable(this)
    Object.assign(this, data)
    if (!this.label && this.originalFileName) this.label = Tools.removeExtension(this.originalFileName)
  }
}

import React from 'react'
import { SkBtn } from '@core/components/btns'
import { BtnProps } from '@core/components/btns/skBtn'
import classNames from 'classnames'

export type SkBtnCircleProps = BtnProps & {
  color?: 'blue' | 'gray' | 'gray2' | 'transparent' | 'white'
  tooltip?: string
}

SkBtnCircle.defaultProps = {
  size: 'middle',
  color: 'gray'
}

export function SkBtnCircle(props: SkBtnCircleProps) {
  return (
    <SkBtn
      {...props}
      type={props.color ? 'default' : undefined}
      shape={'circle'}
      className={classNames(
        props.className,
        { 'border-none bg-transparent shadow-none': props.color === 'transparent' },
        { 'bg-blue2-600 hover:bg-blue2-600 border-none text-white': props.color === 'blue' },
        { 'hover:bg-blue2-100 hover:text-blue2-600 focus:bg-blue2-100 border-none bg-gray-100 text-gray-800': props.color === 'gray' },
        { 'hover:bg-blue2-100 hover:text-blue2-600 focus:bg-blue2-100 border-none bg-gray-200 text-gray-800': props.color === 'gray2' },
        {
          'hover:text-blue2-600 border-gray-100 bg-white text-gray-800 hover:border-gray-100 focus:border-gray-100': props.color === 'white'
        }
      )}
    />
  )
}

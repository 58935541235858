import React, { useEffect, useState } from 'react'

import { SkDrawer, SkSearch, SkStopPropagation } from '@core/components'
import { SkBtnCircleDelete } from '@core/components/btns'
import { observer } from 'mobx-react-lite'
import { ApiStore } from '@core/api/apiStore'
import { makeAutoObservable } from 'mobx'
import { AbstractItem } from '@core/models/abstractItem'

interface IProps<T extends AbstractItem> {
  store: ApiStore<T>
}

class Context {
  viewFiltered: any[]
  terms: string
  constructor(protected props: IProps<any>) {
    makeAutoObservable(this)
    this.viewFiltered = [...props.store.filtersAndViews.viewOthers]
  }

  queryItems = (terms: string = null) => {
    if (terms) this.terms = terms
    if (this.props.store.filtersAndViews.viewOthers.length && terms) {
      const data: any[] = []
      this.props.store.filtersAndViews.viewOthers.forEach(x => {
        if (x.label.toLowerCase().includes(this.terms.toLowerCase())) {
          data.push(x)
        }
      })
      this.viewFiltered = [...data]
    } else {
      this.viewFiltered = [...this.props.store.filtersAndViews.viewOthers]
    }
  }

  setView = async (viewLabel: string) => {
    this.props.store.filtersAndViews.viewsOpen = false
    await this.props.store.filtersAndViews.setView(viewLabel, true)
  }
}

export const SkPageListColViews = observer(function SkPageListColViews<T extends AbstractItem>(props: IProps<T>) {
  const [context] = useState(() => new Context(props))

  useEffect(() => {
    context.queryItems()
  }, [props.store.filtersAndViews.views])

  return (
    <SkDrawer title="Vues" onClose={() => props.store.filtersAndViews.toggleViewsOpen()}>
      <div className="p-5">
        <div className="pb-5">
          <SkSearch id="SkPageListColViews_SearchViews" autoFocus onChange={x => context.queryItems(x)} />
        </div>
        {context.viewFiltered?.map((item, index) => (
          <div
            key={index}
            className="hover:bg-blue2-100 hover:text-blue2-600 flex cursor-pointer items-center justify-between border-b border-gray-100 p-3"
            onClick={() => context.setView(item.label)}
          >
            {item.label}
            <SkStopPropagation>
              <SkBtnCircleDelete onClick={() => props.store.filtersAndViews.removeView(item.label)} id={`removeView${index}`} />
            </SkStopPropagation>
          </div>
        ))}
      </div>
    </SkDrawer>
  )
})

import { LocalStorage } from '@core/utils/localStorage'
import { coreStore } from '@core/store/coreStore'

export class LocalStorageForMe {
  protected static keyComposed = (key: string) => `user-${coreStore.meStore.data.id}-${key}`
  public static setItem(key: string, value: any) {
    LocalStorage.setItem(LocalStorageForMe.keyComposed(key), value)
  }
  public static getItem(key: string) {
    if (!coreStore.meStore?.data) return null
    return LocalStorage.getItem(LocalStorageForMe.keyComposed(key))
  }
}

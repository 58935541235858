import { Table } from 'antd'
import * as React from 'react'
import { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { ApiStore } from '@core/api/apiStore'
import { SkBtn } from '@core/components/btns'
import { SkIconUilAngleDown, SkIconUilAngleUp } from '@core/global/icons'
import { useModalContext } from '@core/components/modal/skModal'
import { SkStoreProvider } from '@core/contexts/storeContext'
import classNames from 'classnames'
import { AbstractItem } from '@core/models/abstractItem'
import { SkTableOrCardsContext } from '@core/components/tableOrCards/skTableOrCards'
import { RecursivePartial, SkColumnType } from '@core/global/interfaces'
import { ExpandableConfig, GetRowKey } from 'rc-table/lib/interface'
import { SizeType } from 'antd/lib/config-provider/SizeContext'
import { useCustomCompareEffect } from 'react-use'
import { deepEqual } from 'fast-equals'
import { useQuery } from '@tanstack/react-query'
import { action, toJS } from 'mobx'

export type SkTableProps<Y extends ApiStore<Y['item']>> = {
  columns: SkColumnType<Y['item']>[]
  store: Y
  filterInit?: RecursivePartial<Y['item']>
  customFilterInit?: unknown
  subUrlPath?: string
  className?: string
  isSortInFront?: boolean
  onRow?: (data: Y['item'], middleClick?: boolean) => void
  expandable?: ExpandableConfig<Y['item']>
  modalToCreateOrEdit?: (data: { itemId: number; item: Y['item'] }) => React.ReactNode
  canExport?: boolean
  size?: number
  sizeType?: SizeType
  withHeaderButtons?: boolean
  tableClassName?: string
  rowKey?: string | GetRowKey<Y['item']>
  expandBtnLabel?: string
  onAfterSearch?: (items: Y['item'][]) => void
  scrollX?: true
}

export const SkTable = observer((props: SkTableProps<ApiStore<unknown>>) => {
  const { columns, store } = props
  const [context] = useState(() => new SkTableOrCardsContext(props))

  useCustomCompareEffect(
    () => {
      if (columns) {
        context.setSorters(columns)
        context.initDefaultSort()
        context.initSearch()
      }
    },
    columns,
    (a, b) => {
      return deepEqual(
        a.map(x => x.title),
        b.map(x => x.title)
      )
    }
  )

  return (
    <SkStoreProvider store={store}>
      <div className={props.className}>{context.columns && context.isInited && store.filtersAndViews.isInit && <SkSearch {...props} context={context} />}</div>
      <SkModalToEdit {...props} />
    </SkStoreProvider>
  )
})

const SkSearch = observer((props: SkTableProps<ApiStore<unknown>> & { context: SkTableOrCardsContext<ApiStore<unknown>> }) => {
  const { store, context, withHeaderButtons = true, canExport = true, rowKey = 'id', onRow, expandable, tableClassName, sizeType, expandBtnLabel, scrollX } = props
  const { params, subUrlPath } = store.querySearch
  const key = [store.key, { params: toJS(params), subUrlPath }]

  const { data, isLoading, isFetching } = useQuery(key, () => store.$searchWithQuery())
  const modalContext = useModalContext()

  useEffect(
    action(() => {
      if (data) store.paginationResult = data
    }),
    []
  )

  const scroll = () => {
    if (modalContext) {
      modalContext.scrollTop()
    } else {
      window.scrollTo(0, 0)
    }
  }

  const onClick = (item: unknown, middleClick: boolean) => {
    if (onRow) {
      onRow(item, middleClick)
    } else if (!expandable) {
      store.openModalToEdit(item)
    }
  }

  return (
    <>
      {withHeaderButtons && (
        <div className="flex justify-start pt-5 pb-3 text-xs">
          <div className="pr-3">
            {isLoading && <span>recherche en cours ...</span>}
            {isFetching && !isLoading && <span>rafraichissement en cours ...</span>}
            {!isLoading && !isFetching && (
              <span data-id="numberOfElements">
                {store.paginationResult?.totalElements} résultat{store.paginationResult?.numberOfElements > 1 ? 's' : ''}
              </span>
            )}
          </div>
          {canExport && (
            <div className="border-l border-gray-400 px-3">
              {!store.$csvLoading ? (
                <a className="text-color underline" onClick={() => store.exportToCsv()}>
                  exporter en csv
                </a>
              ) : (
                <span>export en cours ...</span>
              )}
            </div>
          )}
        </div>
      )}

      <Table
        onRow={item => {
          return {
            onClick: () => {
              onClick(item, false)
            },
            onMouseDown: event => {
              if (event.button === 1) {
                onClick(item, true)
              }
            }
          }
        }}
        className={classNames(tableClassName, { 'pt-5': !withHeaderButtons })}
        size={sizeType}
        rowClassName={context.rowClassName}
        rowKey={rowKey}
        expandable={{
          ...expandable,
          ...(expandBtnLabel
            ? {
                expandIcon: ({ expanded, onExpand, record }) =>
                  expandIcon({
                    expanded,
                    onExpand,
                    record,
                    label: expandBtnLabel
                  })
              }
            : {}),
          expandRowByClick: !!expandable
        }}
        columns={context.columns}
        dataSource={data ? data.content.slice() : null}
        onChange={context.handleTableChange}
        pagination={{
          position: ['bottomCenter'],
          defaultPageSize: store.querySearch.params.size,
          total: data?.totalElements,
          current: store?.querySearch?.params?.page ? store.querySearch.params.page + 1 : 1,
          hideOnSinglePage: true,
          showSizeChanger: false,
          onChange: scroll
        }}
        loading={isLoading}
        scroll={{ x: scrollX }}
      />
    </>
  )
})

const SkModalToEdit = observer((props: SkTableProps<ApiStore<AbstractItem>>) => {
  const { store } = props
  return <>{store.modalToEditIsOpen && props.modalToCreateOrEdit?.({ itemId: store.item?.id, item: store.item })}</>
})

const expandIcon = ({ expanded, onExpand, record, label }) => {
  return (
    <SkBtn gray onClick={e => onExpand(record, e)} id={`rowExpand${record.ui.index}`}>
      {label} {expanded ? <SkIconUilAngleUp /> : <SkIconUilAngleDown />}
    </SkBtn>
  )
}

import * as React from 'react'
import { useEffect, useState } from 'react'
import { Rule } from 'rc-field-form/lib/interface'
import { AbstractLabel } from '../../models/abstractLabel'
import { SizeType } from 'antd/es/config-provider/SizeContext'
import { Select } from 'antd'
import { WithFormItem } from '@core/components/form'
import { makeAutoObservable, runInAction } from 'mobx'
import { useEditContext } from '@core/contexts/editContext'
import { Recursive } from '@core/utils/recursive'
import { propertyName } from '@core/utils/nameOf'
import { Observer } from 'mobx-react-lite'
import { useFormContext } from '@core/contexts/formContext'
import { InitInput } from '@core/utils/initInput'
import { ListenInput } from '@core/utils/listenInput'
import { Tools } from '@core/utils/tools'

type IProps<T, Y extends AbstractLabel> = {
  label?: string
  dataId?: string
  description?: string
  placeholder?: string
  items: Y[]
  rules?: Rule[]
  disabled?: boolean
  readOnly?: boolean
  autoFocus?: boolean
  allowClear?: boolean
  returnId?: boolean
  size?: SizeType
  required?: boolean
  optionElement?: (item: Y) => React.ReactNode
  dropdownRender?: (menu: React.ReactElement) => React.ReactElement
  onSelect?: (value: Y) => void
  onDeselect?: (value: Y) => void
} & (
  | {
      id?: string
      value?: never
      model: T
      property: (x: T) => Y[] | number[]
      onChange?: (value: Y[]) => void
    }
  | {
      id: string
      value: Y[] | number[]
      model?: never
      property?: never
      onChange: (value: Y[]) => void
    }
)

class Context {
  idsSelect: number[]

  constructor(public props: IProps<any, any>, value: AbstractLabel[] | number[]) {
    makeAutoObservable(this)
    this.setIdsSelect(value)
  }

  setIdsSelect = (value: AbstractLabel[] | number[]) => {
    let idsSelect: number[]
    if (!Tools.isNullOrUndefined(value)) {
      if (!this.props.returnId) {
        idsSelect = (value as AbstractLabel[]).map(x => x.id)
      } else {
        idsSelect = value as number[]
      }
    }
    this.idsSelect = idsSelect
  }
}

export function SkSelectMultiple<T>(props: IProps<T, any>) {
  const formContext = useFormContext()
  const editContext = useEditContext()
  const { id } = InitInput(props, 'SkSelectMultiple')
  const { value, setFieldsValue } = ListenInput(props, id)
  const [context] = useState(() => new Context(props, value))
  const [allowClear, setAllowClear] = useState(true)

  //***********************************************************************************************//
  //******** useEffect ****************************************************************************//
  //***********************************************************************************************//

  useEffect(() => {
    if (Tools.isNullOrUndefined(props.allowClear) && props.rules) {
      setAllowClear(!props.rules.some((x: any) => x['required']))
    }
  }, [props.rules])

  useEffect(() => {
    context.setIdsSelect(value)
    setFieldsValue(context.idsSelect)
  }, [value])

  //***********************************************************************************************//
  //******** actions ******************************************************************************//
  //***********************************************************************************************//
  const onChange = (ids: number[]) => {
    let value: any
    if (ids?.length) {
      if (ids && !props.returnId) {
        value = []
        ids.forEach(id => value.push(props.items.find(x => x.id === id)))
      } else {
        value = ids
      }
    }

    runInAction(() => {
      context.idsSelect = ids
      if (props.property) {
        Recursive.setData(props.model, propertyName(props.property), value ?? null)
      }
      props.onChange?.(value)
    })
    editContext?.checkIfItemIsItemChanged()
  }

  const onSelect = (id: number) => {
    let value: any
    if (!props.returnId) {
      value = props.items.find(x => x.id === id)
    } else {
      value = id
    }
    props.onSelect?.(value)
  }

  const onDeselect = (id: number) => {
    let value: any
    if (!props.returnId) {
      value = props.items.find(x => x.id === id)
    } else {
      value = id
    }
    props.onDeselect?.(value)
  }

  //**********************************************************************************************//
  //******** return ******************************************************************************//
  //**********************************************************************************************//

  return (
    <WithFormItem {...props} id={id} value={context.idsSelect}>
      <Observer>
        {() => (
          <Select
            id={id}
            data-id={props.dataId}
            mode="multiple"
            showSearch
            size={props.size}
            value={context.idsSelect}
            allowClear={allowClear}
            placeholder={props.placeholder}
            onChange={onChange}
            onSelect={onSelect}
            onDeselect={onDeselect}
            disabled={props.disabled || formContext.props.disabled}
            autoFocus={props.autoFocus}
            filterOption={filterOption}
            dropdownRender={props.dropdownRender}
          >
            {props.items?.map((item, index) => (
              <Select.Option key={index} value={item.id} data-id={`${id}_itemId-${item.id}`}>
                {props.optionElement ? props.optionElement(item) : item.label}
              </Select.Option>
            ))}
          </Select>
        )}
      </Observer>
    </WithFormItem>
  )
}

const filterOption = (input, option) => {
  return typeof option.children === 'string' && (option.children as string).toLowerCase().indexOf(input.toLowerCase()) >= 0
}

import { makeAutoObservable } from 'mobx'
import { SkLink } from '@core/global/interfaces'
import { CodeBundleEnum, CodePermissionEnum } from '@common/global/enums'
import {
  SkIconUilAnalytics,
  SkIconUilArchive,
  SkIconUilBookAlt,
  SkIconUilBookOpen,
  SkIconUilCalendarAlt,
  SkIconUilEstate,
  SkIconUilExchange,
  SkIconUilLandscapeAlt,
  SkIconUilRestaurant,
  SkIconUilShieldPlus,
  SkIconUilShoppingCartAlt,
  SkIconUilSlidersV,
  SkIconUilTagAlt,
  SkIconUilTruck
} from '@core/global/icons'
import { coreStore } from '@core/store/coreStore'
import { referentialStore } from '@common/models/referential/referentialStore'
import { FieldsNaming } from '@common/global/fieldsNaming'
import { rootStore } from '@/stores/rootStore'

export class MenuStore {
  links: SkLink[] = []
  adminLinks: SkLink[] = []
  asideMenuOpenKeys: string[] = []

  constructor() {
    makeAutoObservable(this)
  }

  setDefaultAsideMenuOpenKeys() {
    if (!this.links) return
    const path = coreStore.routerStore.route.replace('/[id]', '')
    for (let link of this.links) {
      if (link.path === path) {
        return
      }
      if (link.children) {
        for (const child of link.children) {
          if (child.path === path) {
            this.asideMenuOpenKeys = [link.label]
            return
          } else if (child.children) {
            for (const child2 of child.children) {
              if (child2.path === path) {
                this.asideMenuOpenKeys = [link.label, child.label]
                return
              }
            }
          }
        }
      }
    }
  }

  loadLinks() {
    this.links = []
    if (!coreStore.meStore.isTenantAdministrator) {
      this.addLink(this.links, {
        id: 'welcome',
        label: 'Accueil',
        path: '/',
        icon: SkIconUilEstate,
        permissions: [CodePermissionEnum.welcomeScreen]
      })
      if (referentialStore.data.tenantProperties.hasBundle(CodeBundleEnum.restaurant)) {
        this.addLink(this.links, {
          id: 'planning',
          label: 'Planning',
          path: '/planning',
          icon: SkIconUilCalendarAlt,
          permissions: [CodePermissionEnum.planningManagement]
        })
        this.addLink(this.links, {
          id: 'recipes',
          label: 'Recettes',
          path: '/recipes',
          icon: SkIconUilRestaurant,
          permissions: [CodePermissionEnum.recipeManagement]
        })
        this.addLink(this.links, {
          id: 'menus',
          label: 'Menus',
          path: '/menus',
          icon: SkIconUilBookOpen,
          permissions: [CodePermissionEnum.packageGroupManagement]
        })
        this.addLink(this.links, {
          id: 'extras',
          label: 'Suppléments',
          path: '/extras',
          icon: SkIconUilTagAlt,
          permissions: [CodePermissionEnum.packageGroupManagement]
        })
        this.addLinkSale()
        this.addLinkDelivery()
        this.addLink(this.links, {
          id: 'reports',
          label: 'Rapports',
          icon: SkIconUilAnalytics,
          permissions: null,
          children: [
            {
              label: 'Préparations du jour',
              path: '/reports/variant-quantity-by-day',
              permissions: null
            },
            { label: 'Paniers du jour', path: '/reports/estimates-day', permissions: [CodePermissionEnum.invoiceManagement] }
          ]
        })
      }

      if (referentialStore.data.tenantProperties.hasBundle(CodeBundleEnum.stock)) {
        this.addLink(this.links, {
          id: 'stocking',
          label: 'Inventaire',
          icon: SkIconUilArchive,
          permissions: null,
          children: [
            {
              label: 'Exemplaires',
              path: '/variants-tracked-by-single',
              permissions: [CodePermissionEnum.itemManagement],
              hidden: !referentialStore.data.tenantProperties.articleProperties?.withCopies
            },
            {
              label: 'Lots',
              path: '/variants-tracked-by-lot',
              permissions: [CodePermissionEnum.articleManagement],
              hidden: !referentialStore.data.tenantProperties.articleProperties?.withLots
            },
            { label: 'Articles', path: '/articles', permissions: [CodePermissionEnum.articleManagement] }
          ]
        })
        this.addLinkSale()
        this.addLinkDelivery()
        this.addLink(this.links, {
          id: 'transfert',
          label: 'Mouvement',
          icon: SkIconUilExchange,
          permissions: null,
          children: [
            { label: 'Expéditions', path: '/transfert/shipments', permissions: [CodePermissionEnum.actionShip] },
            {
              label: 'Réceptions et transferts',
              path: '/transfert/receipts',
              permissions: [
                CodePermissionEnum.actionAssignToReseller,
                CodePermissionEnum.actionAddInStock,
                CodePermissionEnum.actionReceiveShipment,
                CodePermissionEnum.actionTransfer
              ]
            },
            {
              label: 'Destructions',
              path: '/transfert/destructions',
              permissions: [CodePermissionEnum.actionRemoval]
            },
            {
              label: 'Ajouts en stock chez Socalog',
              path: '/transfert/regulations',
              permissions: [CodePermissionEnum.actionAddInStockSocalog],
              hidden: !referentialStore.data.tenantProperties.withSocalogInterface
            },
            {
              label: 'Réapprovisionnements',
              path: '/transfert/replenishments',
              permissions: [CodePermissionEnum.actionRequestReplenishmentSocalog]
            },
            { label: 'Suppressions', path: '/transfert/deletions', permissions: [CodePermissionEnum.actionDelete] },
            {
              label: 'Changements d‘état',
              path: '/transfert/change-state-and-receipt',
              permissions: [CodePermissionEnum.actionScrap, CodePermissionEnum.actionRepackage, CodePermissionEnum.actionPayback]
            }
          ]
        })
      }
      if (referentialStore.data.tenantProperties.hasBundle(CodeBundleEnum.invoice) && !referentialStore.data.tenantProperties.hasBundle(CodeBundleEnum.restaurant)) {
        this.addLink(this.links, {
          id: 'accounting',
          label: 'Comptabilité',
          icon: SkIconUilBookAlt,
          permissions: [CodePermissionEnum.paramAccounting],
          children: [
            {
              label: 'Exports',
              path: '/accounting/accounting-exports',
              permissions: [CodePermissionEnum.paramAccounting]
            }
          ]
        })
      }
      if (referentialStore.data.tenantProperties.hasBundle(CodeBundleEnum.intervention)) {
        this.addLink(this.links, {
          id: 'equipment',
          label: 'Interventions',
          icon: SkIconUilShieldPlus,
          permissions: null,
          children: [
            {
              label: 'Interventions',
              path: '/sales-service/interventions',
              permissions: null,
              mobileAvailable: true
            },
            {
              label: 'Véhicules',
              path: '/sales-service/equipments',
              permissions: null,
              mobileAvailable: true
            }
          ]
        })
      }
      this.loadAdminLinks()
      if (this.adminLinks?.length) {
        this.addLink(this.links, { label: 'Paramètres', id: 'app-parameters', path: this.adminLinks[0]?.children[0]?.path, icon: SkIconUilSlidersV, permissions: null })
      }
    } else {
      this.addLink(this.links, { label: 'Tenants', path: '/tenants', icon: SkIconUilTagAlt, permissions: null })
      this.addLink(this.links, { label: 'Instance', path: '/instance', icon: SkIconUilLandscapeAlt, permissions: null })
    }
  }

  hasLinkPath(path: string) {
    if (!this.links) return false
    for (const link of this.links) {
      if (link.path === path) return true
      for (const child of link.children) {
        if (child.path === path) return true
      }
    }
    return false
  }

  gotoFirstPageAvailable() {
    if (this.links?.length) {
      for (const link of this.links) {
        if (link.children?.length && link.children[0].path) {
          coreStore.routerStore.push(link.children[0].path)
          return
        } else if (link.path) {
          coreStore.routerStore.push(link.path)
          return
        }
      }
    }
  }

  addLink = (links: SkLink[], link: SkLink) => {
    if (link.permissions == null || coreStore.meStore.hasOneOrMoreInPermissions(link.permissions)) {
      const children = this.getLinkChildren(link)
      if ((!link.hidden && (!link.children || link.children.length === 0) && ((rootStore.isUiMobile && link.mobileAvailable) || !rootStore.isUiMobile)) || children?.length) {
        if (link.path && !link.id) link.id = link.path.replace(/\//g, '-')
        link.children = children
        links.push(link)
      }
    }
  }

  getLinkChildren = (link: SkLink) => {
    let childrenOk = []
    if (link.children) {
      link.children.forEach(child => {
        if (child.path) {
          child.id = child.path.replace(/\//g, '-')
          if (
            !child.hidden &&
            (!child.permissions || coreStore.meStore.hasOneOrMoreInPermissions(child.permissions)) &&
            ((rootStore.isUiMobile && child.mobileAvailable) || !rootStore.isUiMobile)
          ) {
            childrenOk.push(child)
          }
        } else {
          child.children = this.getLinkChildren(child)
          childrenOk.push(child)
        }
      })
    }
    return childrenOk
  }

  onAsideMenuOpenChange = (data: string[]) => {
    const menuLabelClicked = data[data.length - 1]
    for (const link of this.links) {
      if (link.children?.length) {
        if (link.label === menuLabelClicked) {
          this.asideMenuOpenKeys = [menuLabelClicked]
          return
        }
        for (const child of link.children) {
          if (child.label === menuLabelClicked) {
            this.asideMenuOpenKeys = [link.label, menuLabelClicked]
            return
          }
        }
      }
    }
    this.asideMenuOpenKeys = []
  }

  onAsideMenuSelect = data => {
    data.keyPath.shift()
    this.asideMenuOpenKeys = data.keyPath
  }

  private addLinkSale() {
    this.addLink(this.links, {
      id: 'sell',
      label: 'Vente',
      icon: SkIconUilShoppingCartAlt,
      permissions: null,
      children: [
        {
          label: 'Clients',
          path: referentialStore.data.tenantProperties.withSmileApi ? '/sale/customers-smile' : '/sale/customers',
          permissions: [CodePermissionEnum.clientManagement]
        },
        {
          label: FieldsNaming.estimate.objectName.plural,
          path: '/sale/estimates',
          permissions: [CodePermissionEnum.invoiceManagement]
        },
        { label: 'Factures', path: '/sale/invoices', permissions: [CodePermissionEnum.invoiceManagement] },
        {
          id: 'sellPayments',
          label: 'Paiements',
          permissions: [CodePermissionEnum.invoiceManagement],
          children: [
            { label: 'Paiements', path: '/sale/payments', permissions: [CodePermissionEnum.invoiceManagement] },
            { label: 'Remises', path: '/sale/bank-check-deposits', permissions: [CodePermissionEnum.invoiceManagement] },
            {
              label: 'Prélèvements',
              path: '/sale/direct-debit-deposits',
              permissions: [CodePermissionEnum.invoiceManagement],
              hidden: !referentialStore.data.tenantProperties.tenantPaymentMethodProperties.withDirectDebit
            }
          ]
        },
        {
          label: 'Abonnements',
          path: '/sale/subscriptions',
          permissions: [CodePermissionEnum.invoiceManagement]
        },
        {
          label: 'Affaires',
          path: '/sale/business-deals',
          permissions: [CodePermissionEnum.businessDealManagement],
          hidden: referentialStore.data.tenantProperties.hasBundle(CodeBundleEnum.restaurant)
        },
        {
          label: 'Achats',
          path: '/sale/purchases',
          permissions: [CodePermissionEnum.purchaseManagement],
          hidden: referentialStore.data.tenantProperties.hasBundle(CodeBundleEnum.restaurant)
        },
        {
          label: 'Commissions',
          path: '/sale/commissions',
          permissions: [CodePermissionEnum.commissionManagement],
          hidden: !referentialStore.data.tenantProperties.hasBundle(CodeBundleEnum.commission)
        }
      ]
    })
  }

  private addLinkDelivery() {
    if (!referentialStore.data.tenantProperties.hasBundle(CodeBundleEnum.delivery)) return
    this.addLink(this.links, {
      id: 'delivery',
      label: 'Livraison',
      icon: SkIconUilTruck,
      permissions: null,
      children: [
        {
          label: 'Feuilles de route',
          path: '/delivery/roadmaps',
          permissions: null,
          hidden: !referentialStore.data.tenantProperties.hasBundle(CodeBundleEnum.restaurant) || !coreStore.meStore.hasPermission(CodePermissionEnum.paramTransporter)
        },
        { label: 'Transporteurs', path: '/delivery/transporters', permissions: [CodePermissionEnum.paramTransporter] }
      ]
    })
  }

  private loadAdminLinks() {
    this.adminLinks = []
    if (referentialStore.data.tenantProperties.hasBundle(CodeBundleEnum.invoice)) {
      this.addLink(this.adminLinks, {
        id: 'adminSell',
        label: 'Vente',
        permissions: null,
        children: [
          {
            label: 'Devis et facture',
            path: '/admin/sell/estimate',
            permissions: [CodePermissionEnum.invoiceManagement],
            hidden: referentialStore.data.tenantProperties.hasBundle(CodeBundleEnum.restaurant)
          },
          {
            label: 'Planning',
            path: '/admin/sell/planning',
            permissions: [CodePermissionEnum.paramWarehouse],
            hidden: !referentialStore.data.tenantProperties.hasBundle(CodeBundleEnum.restaurant)
          },
          {
            label: 'Livraison',
            path: '/admin/sell/shipping',
            permissions: [CodePermissionEnum.paramWarehouse],
            hidden: !referentialStore.data.tenantProperties.hasBundle(CodeBundleEnum.delivery)
          },
          {
            label: 'Prix défaut',
            path: '/admin/referential/price-tag',
            permissions: [CodePermissionEnum.paramTax]
          },
          {
            label: 'Taxes',
            path: '/admin/referential/tax',
            permissions: [CodePermissionEnum.paramTax]
          },
          {
            label: 'Réductions',
            path: '/admin/sell/discount',
            permissions: [CodePermissionEnum.invoiceManagement]
          },
          {
            label: 'Paiements en ligne',
            path: '/admin/sell/payment-transaction',
            permissions: [CodePermissionEnum.invoiceManagement],
            hidden: !referentialStore.data.tenantProperties.hasBundle(CodeBundleEnum.invoice)
          }
        ]
      })
    }
    if (referentialStore.data.tenantProperties.hasBundle(CodeBundleEnum.stock)) {
      this.addLink(this.adminLinks, {
        id: 'adminStock',
        label: 'Stock',
        permissions: null,
        children: [
          {
            label: 'Sites de stockage',
            path: '/admin/referential/warehouse',
            permissions: [CodePermissionEnum.paramWarehouse]
          }
        ]
      })
    }
    if (referentialStore.data.tenantProperties.hasBundle(CodeBundleEnum.intervention)) {
      this.addLink(this.adminLinks, {
        id: 'adminSalesService',
        label: 'Interventions',
        permissions: null,
        children: [
          {
            label: 'Véhicules',
            path: '/admin/sales-service/equipment-settings',
            permissions: null
          }
        ]
      })
    }
    if (referentialStore.data.tenantProperties.hasBundle(CodeBundleEnum.invoice) && !referentialStore.data.tenantProperties.hasBundle(CodeBundleEnum.restaurant)) {
      this.addLink(this.adminLinks, {
        id: 'adminAccounting',
        label: 'Comptabilité',
        permissions: [CodePermissionEnum.paramAccounting],
        children: [
          {
            label: 'Comptes tiers',
            path: '/admin/accounting/third-party-accounting-account',
            permissions: null
          },
          {
            label: 'Comptes généraux',
            path: '/admin/accounting/general-accounting-account',
            permissions: null
          },
          {
            label: 'Journaux',
            path: '/admin/accounting/accounting-journal',
            permissions: null
          },
          {
            label: 'Années comptables',
            path: '/admin/accounting/financial-year',
            permissions: null
          }
        ]
      })
    }
    this.addLink(this.adminLinks, {
      id: 'adminTemplate',
      label: 'Template',
      permissions: null,
      children: [
        {
          label: 'Modèles',
          path: '/admin/referential/template',
          permissions: [CodePermissionEnum.paramTemplate]
        },
        {
          label: 'Variables',
          path: '/admin/referential/template-variables',
          permissions: [CodePermissionEnum.paramTemplate]
        }
      ]
    })
    this.addLink(this.adminLinks, {
      id: 'adminSecurity',
      label: 'Sécurité',
      permissions: null,
      children: [
        {
          label: 'Utilisateurs',
          path: '/admin/security/user',
          permissions: [CodePermissionEnum.paramUser]
        },
        {
          label: 'Rôles',
          path: '/admin/security/role',
          permissions: [CodePermissionEnum.paramRole]
        }
      ]
    })
    this.addLink(this.adminLinks, {
      id: 'adminLogs',
      label: 'Logs',
      permissions: null,
      children: [
        {
          label: 'Mails',
          path: '/admin/logs/mail',
          permissions: [CodePermissionEnum.paramUser]
        },
        {
          label: 'Batchs',
          path: '/admin/logs/batch',
          permissions: [CodePermissionEnum.paramBatch]
        }
      ]
    })
    this.addLink(this.adminLinks, {
      id: 'adminReferential',
      label: 'Référentiel',
      permissions: null,
      children: [
        {
          label: 'Fournisseurs',
          path: '/admin/referential/supplier',
          permissions: [CodePermissionEnum.paramSupplier]
        },
        {
          label: 'Marques',
          path: '/admin/referential/brand',
          permissions: [CodePermissionEnum.paramBrand],
          hidden: !referentialStore.data.tenantProperties.articleProperties.brandVisible
        },
        {
          label: 'Familles d‘articles',
          path: '/admin/referential/tag',
          permissions: [CodePermissionEnum.paramArticleTag]
        },
        {
          label: 'Types de vente',
          path: '/admin/referential/sale-tag',
          permissions: [CodePermissionEnum.paramSaleTag],
          hidden: !referentialStore.data.tenantProperties.saleProperties.withSaleTags
        },
        {
          label: 'Catégories de devis',
          path: '/admin/referential/estimate-category',
          permissions: [CodePermissionEnum.paramEstimateCategory]
        },
        {
          label: 'Transporteurs',
          path: '/admin/referential/transporter',
          permissions: [CodePermissionEnum.paramTransporter]
        },
        {
          label: 'Comptes',
          path: '/admin/referential/account',
          permissions: [CodePermissionEnum.paramAccount]
        },
        {
          label: 'Banques',
          path: '/admin/bank',
          permissions: [CodePermissionEnum.paramBank]
        },
        {
          label: 'Entités',
          path: '/admin/referential/company',
          permissions: [CodePermissionEnum.paramCompany]
        },
        {
          label: 'Formes juridiques',
          path: '/admin/referential/legal-status',
          permissions: [CodePermissionEnum.clientManagement]
        },
        {
          label: 'Fonctions',
          path: '/admin/referential/corporate-function',
          permissions: [CodePermissionEnum.clientManagement]
        },
        {
          label: 'Mentions franchise de taxe',
          path: '/admin/referential/tax-mention',
          permissions: [CodePermissionEnum.paramTax]
        },
        {
          label: 'Unités d‘articles',
          path: '/admin/referential/article-unit',
          permissions: [CodePermissionEnum.paramArticleUnit]
        },
        {
          label: 'Villes et quartiers',
          path: '/admin/referential/city',
          permissions: [CodePermissionEnum.paramCity]
        }
      ]
    })
  }
}

import { action, makeObservable, observable } from 'mobx'
import { Frequency } from '@common/models/frequency/frequency'
import { AbstractItem } from '@core/models/abstractItem'
import { Payment } from '@common/models/payment/payment'
import { Customer } from '@common/models/customer/customer'
import { TaxMention } from '@common/models/taxMention/taxMention'
import { Price } from '@common/models/price/price'
import { Document } from '@core/models/document'
import { BankInformation } from '@common/models/bankInformation/bankInformation'
import { BankAccount } from '@core/models/bankAccount'

export class PaymentProfile extends AbstractItem {
  @observable
  invoiceFrequency: Frequency
  @observable
  deposit: Payment
  @observable
  customer: Customer
  @observable
  isTaxExempt: boolean
  @observable
  taxExemptProofFile: Document
  @observable
  taxMention: TaxMention
  @observable
  settlementPeriod: number
  @observable
  debitLimit: Price
  @observable
  debitLimitMargin: number
  @observable
  isDebitLimitBlocking: boolean
  @observable
  hasDirectDebit: boolean
  @observable
  directDebitForm: Document
  @observable
  isPurchaseOrderRequired: boolean
  @observable
  bankInformation: BankInformation
  @observable
  bankAccount: BankAccount

  constructor(data?: Partial<PaymentProfile>) {
    super(data)
    Object.assign(this, data)
    makeObservable(this)

    /* 1 mois de délai de paiement par défaut */
    if (!this.settlementPeriod) this.settlementPeriod = 1
    if (this.bankInformation) this.bankInformation = new BankInformation(this.bankInformation)
    if (this.bankAccount) this.bankAccount = new BankAccount(this.bankAccount)
    if (this.invoiceFrequency) this.invoiceFrequency = new Frequency(this.invoiceFrequency)
  }

  @action
  setDebitLimit = (amount: number) => {
    if (!amount) {
      this.debitLimit = null
      return
    }
    if (!this.debitLimit) this.debitLimit = new Price()
    this.debitLimit.amount = amount
  }

  @action
  setBankAccountRibFile = (ribFile: Document) => {
    if (!ribFile) return
    if (!this.bankAccount) this.bankAccount = new BankAccount()
    this.bankAccount.ribFile = ribFile
  }
}

import { WarehouseActionLine } from '@common/models/warehouseActionLine/warehouseActionLine'

export class WarehouseActionUtils {
  public static haveSameVariant(line: WarehouseActionLine, previousLine: WarehouseActionLine) {
    return line.variant?.id === previousLine.variant?.id
  }

  public static havSameVariantTracked(line1: WarehouseActionLine, line2: WarehouseActionLine): boolean {
    return line1.variantTracked?.id === line2.variantTracked?.id
  }
}

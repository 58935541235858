import { AbstractItem } from '@core/models/abstractItem'
import { makeObservable, observable } from 'mobx'
import { FileFormat } from '@common/models/file/fileFormat'

export class AttributeFileFormat extends AbstractItem {
  @observable
  fileFormat: FileFormat

  constructor(data?: Partial<AttributeFileFormat>) {
    super(data)
    Object.assign(this, data)
    makeObservable(this)
  }
}

import React from 'react'
import { SkPageEditBackButton } from '@core/components'
import { useEditContext } from '@core/contexts/editContext'
import classNames from 'classnames'
import { useIsMobile } from '@core/utils/hooks/useIsMobile'

interface IProps {
  children: React.ReactNode
  icon?: React.ReactNode
  hiddenBackButton?: true
}

export const SkPageEditHeaderFirstLine = ({ children, icon, hiddenBackButton }: IProps) => {
  const isMobile = useIsMobile()
  const editContext = useEditContext()
  const { store } = editContext
  return (
    <>
      {!isMobile ? (
        <div className={classNames('flex items-center justify-between pb-3', { 'ml-6': !hiddenBackButton })}>
          <div className="flex w-full items-center">
            {!hiddenBackButton && <SkPageEditBackButton store={store} simple={true} />}
            <div className="flex-center mr-4">
              <div className="bg-dark flex-center h-20 w-20 rounded text-xl text-gray-100">{icon ? icon : <store.icon />}</div>
            </div>
            <div className="flex w-full">{children}</div>
          </div>
        </div>
      ) : (
        <>{children}</>
      )}
    </>
  )
}

import { computed, makeObservable, observable } from 'mobx'
import { AbstractLabel } from '@core/models/abstractLabel'
import moment from 'moment'

export class FinancialYear extends AbstractLabel {
  @observable
  startDate: Date
  @observable
  endDate: Date

  constructor(data?: Partial<FinancialYear>) {
    super(data)
    makeObservable(this)
    Object.assign(this, data)
  }

  @computed
  get isCurrent() {
    let now = moment()
    return moment(this.startDate).isBefore(now) && moment(this.endDate).isAfter(now)
  }
}

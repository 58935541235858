import React from 'react'
import { Warehouse } from '@common/models/warehouse/warehouse'
import { computed, makeObservable, observable, runInAction } from 'mobx'
import { WarehouseActionLine } from '@common/models/warehouseActionLine/warehouseActionLine'
import { Customer } from '@common/models/customer/customer'
import { WarehouseActionLineStore } from '@common/models/warehouseActionLine/warehouseActionLineStore'
import { WarehousesActionTypeEnum } from '@common/global/enums'
import { SkIconUilArrowToRight, SkIconUilPlusSquare, SkIconUilTruckLoading } from '@core/global/icons'
import { AbstractItem, AbstractUi } from '@core/models/abstractItem'
import { AbstractLabel } from '@core/models/abstractLabel'
import moment from 'moment'
import { MissingArticlesGenerator } from '@common/models/warehouseAction/missingItems/missingItemsGenerator'

export class WarehouseAction extends AbstractItem {
  @observable
  type: AbstractLabel
  @observable
  departureWarehouse: Warehouse | undefined
  @observable
  arrivalWarehouse: Warehouse
  @observable
  transitWarehouse: Warehouse
  @observable
  lines: WarehouseActionLine[]
  @observable
  comment: string
  @observable
  previous: WarehouseAction
  next: WarehouseAction
  objectsCount: number
  objectsCountFromPrevious: number
  customer: Customer
  newVariantTrackedState: AbstractLabel
  date: string
  dateMoment?: moment.Moment
  declare ui: Ui

  constructor(data?: Partial<WarehouseAction>) {
    super(data)
    makeObservable(this)
    Object.assign(this, data)
    if (this.departureWarehouse) {
      this.departureWarehouse = new Warehouse(this.departureWarehouse)
    }
    if (this.arrivalWarehouse) {
      this.arrivalWarehouse = new Warehouse(this.arrivalWarehouse)
    }
    if (this.transitWarehouse) {
      this.transitWarehouse = new Warehouse(this.transitWarehouse)
    }
    if (this.lines) {
      this.lines.forEach((item, index) => {
        this.lines[index] = new WarehouseActionLine(item)
      })
    } else {
      this.lines = []
    }
    if (this.previous) {
      this.previous = new WarehouseAction(this.previous)
    }
    if (this.next) {
      this.next = new WarehouseAction(this.next)
    }
    if (this.customer) {
      this.customer = new Customer(this.customer)
    }
    if (this.date) {
      this.dateMoment = moment(this.date)
    }
    this.ui = new Ui(this)
  }

  @computed
  get objectsCountCalc() {
    return this.lines?.length ? this.lines.map(x => (x.variant?.article?.isVariantTracked ? 1 : x.quantity ? x.quantity : 0)).reduce((a, b) => a + b) : 0
  }

  async getAllLines() {
    const store = new WarehouseActionLineStore()
    const lines = await store.$all({ params: { filterInit: { warehouseActionId: this.id } } })
    return runInAction(() => {
      this.lines = lines
    })
  }

  async setPreviousAndLoad(data: WarehouseAction) {
    this.previous = data
    this.departureWarehouse = data.departureWarehouse
    this.arrivalWarehouse = data.arrivalWarehouse
    await data.getAllLines()
    this.setMissingArticleItems()
  }

  setMissingArticleItems() {
    runInAction(() => {
      const missingArticlesCalc = new MissingArticlesGenerator(this.lines, this.previous?.lines)
      this.ui.missingObjectsCount = missingArticlesCalc.missingObjectsCount
      this.ui.missingWarehouseActionLines = missingArticlesCalc.missingWarehouseActionLines
    })
  }
}

class Ui extends AbstractUi {
  @observable
  missingWarehouseActionLines: WarehouseActionLine[]
  @observable
  missingObjectsCount = 0
  receiptTypeLabel: string
  receiptTypeIcon: React.ReactNode

  constructor(data: WarehouseAction) {
    super()
    makeObservable(this)
    if (data.type?.id === WarehousesActionTypeEnum.reception) {
      if (!data.departureWarehouse) {
        this.receiptTypeLabel = 'Ajout en stock'
        this.receiptTypeIcon = <SkIconUilPlusSquare />
      } else {
        if (data.previous) {
          this.receiptTypeLabel = 'Réception d‘une expédition'
          this.receiptTypeIcon = <SkIconUilTruckLoading />
        } else {
          this.receiptTypeLabel = 'Transfert'
          this.receiptTypeIcon = <SkIconUilArrowToRight />
        }
      }
    }
  }
}

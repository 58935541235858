import { Actuator } from '@common/models/actuator/actuator'
import { LocalStorage } from '@core/utils/localStorage'
import NiceModal from '@ebay/nice-modal-react'
import dynamic from 'next/dynamic'
import React from 'react'
import { makeAutoObservable } from 'mobx'

const SkModalInfoReloadApp = dynamic(() => import('@core/components/modals/skModalInfoReloadApp'), {
  ssr: false
})

export class ActuatorStore {
  data: Actuator

  constructor() {
    makeAutoObservable(this)
    this.lastCommitTime = null
  }

  checkLastCommitTimeAndReloadIfDiff(data: Actuator) {
    this.data = data
    if (process.env.NODE_ENV !== 'development') {
      if (!this.lastCommitTime) {
        this.lastCommitTime = data.git.commit.time
        return
      }
      if (this.lastCommitTime !== data.git.commit.time) {
        NiceModal.show(SkModalInfoReloadApp as React.FC<any>).then()
        this.lastCommitTime = null
      }
    }
  }

  protected get lastCommitTime(): string {
    return LocalStorage.getItem('lastCommitTime')
  }

  protected set lastCommitTime(time: string) {
    LocalStorage.setItem('lastCommitTime', time)
  }
}

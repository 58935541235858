import { AbstractItem } from '@core/models/abstractItem'
import cloneDeep from 'lodash/cloneDeep'

export class Tools {
  static accentsTidy = (s: string) => {
    let r: string
    if (!s) {
      return ''
    }
    r = s.toLowerCase()
    r = r.replace(new RegExp(/-/g), ' ')
    r = r.replace(new RegExp(/[àáâãäå]/g), 'a')
    r = r.replace(new RegExp(/æ/g), 'ae')
    r = r.replace(new RegExp(/ç/g), 'c')
    r = r.replace(new RegExp(/[èéêë]/g), 'e')
    r = r.replace(new RegExp(/[ìíîï]/g), 'i')
    r = r.replace(new RegExp(/ñ/g), 'n')
    r = r.replace(new RegExp(/[òóôõö]/g), 'o')
    r = r.replace(new RegExp(/œ/g), 'oe')
    r = r.replace(new RegExp(/[ùúûü]/g), 'u')
    r = r.replace(new RegExp(/[ýÿ]/g), 'y')
    r = r.replace(new RegExp(/\bst\b/g), 'saint')
    return r
  }

  static slugify = (str: string) => {
    if (!str) return
    str = str.replace(/^\s+|\s+$/g, '')

    // Make the string lowercase
    str = str.toLowerCase()

    // Remove accents, swap ñ for n, etc
    const from = 'ÁÄÂÀÃÅČÇĆĎÉĚËÈÊẼĔȆÍÌÎÏŇÑÓÖÒÔÕØŘŔŠŤÚŮÜÙÛÝŸŽáäâàãåčçćďéěëèêẽĕȇíìîïňñóöòôõøðřŕšťúůüùûýÿžþÞĐđßÆa·/_,:;'
    const to = 'AAAAAACCCDEEEEEEEEIIIINNOOOOOORRSTUUUUUYYZaaaaaacccdeeeeeeeeiiiinnooooooorrstuuuuuyyzbBDdBAa------'
    for (let i = 0, l = from.length; i < l; i++) {
      str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i))
    }

    // Remove invalid chars
    str = str
      .replace(/[^a-z0-9 -]/g, '')
      // Collapse whitespace and replace by -
      .replace(/\s+/g, '-')
      // Collapse dashes
      .replace(/-+/g, '-')

    return str
  }

  static removeExtension(filename) {
    return filename.replace(/\.[^/.]+$/, '')
  }

  static upperFirstLetter = (s: string) => {
    if (!s || !s.length) return
    return s.charAt(0).toUpperCase() + s.slice(1)
  }

  static lowerFirstLetter = (s: string) => {
    if (!s || !s.length) return
    return s.charAt(0).toLowerCase() + s.slice(1)
  }

  static isMacAddress = (s: string) => {
    const isMac = /^((?!00|FF|88|87)[0-9A-Fa-f]{2}){6}$/
    if (!isMac.test(s)) return false
    const oneLetterAndOneNumber = /(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/
    return oneLetterAndOneNumber.test(s)
  }

  static isNullOrUndefined<T>(obj?: T | null): boolean {
    // null == undefined so this is true if obj = null or obj = undefined
    return obj == null
  }

  static stringifyOptions = (options: any) => {
    const parts = []
    for (const key in options) {
      parts.push(key + '=' + options[key])
    }
    return parts.join(',')
  }

  static parseQueryString = (str = '') => {
    const obj: any = {}
    str.split('&').forEach(keyValue => {
      const value = keyValue.split('=')
      const key = decodeURIComponent(value[0])
      obj[key] = value[1] ? decodeURIComponent(value[1]) : true
    })
    return obj
  }

  static capitalize = (s: string) => {
    if (!s || !s.length) return
    return s.charAt(0).toUpperCase() + s.slice(1)
  }

  static isEmail = (s: string) => {
    const isEmail = /^[A-Z0-9a-z._-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,6}$/
    return isEmail.test(s)
  }

  static getPathname = (url: string) => {
    return url.split('?')[0]
  }

  static arrayMove = <T>(list: T[], startIndex: number, endIndex: number) => {
    const [removed] = list.splice(startIndex, 1)
    list.splice(endIndex, 0, removed)
    return list
  }

  static arrayMoveAnSetIdx = <T>(list: T[], startIndex: number, endIndex: number) => {
    Tools.arrayMove(list, startIndex, endIndex)
    list.forEach((data, index) => {
      list[index]['idx'] = index
    })
    return list.slice()
  }

  static cleanData(data: AbstractItem) {
    const clone = cloneDeep(data)
    return this._cleanData(clone)
  }

  private static _cleanData(data: AbstractItem) {
    if (!data) return
    try {
      delete data.ui
    } catch (e) {
      data.ui = null
    }
    Object.values(data).forEach(value => {
      if (value?.ui) {
        this._cleanData(value)
      } else if (Array.isArray(value)) {
        for (const item of value) {
          this._cleanData(item)
        }
      }
    })
    return data
  }
}

import { action, computed, makeObservable, observable, runInAction } from 'mobx'
import { AbstractLabel } from '../models/abstractLabel'
import { Context } from '@core/api/context'
import { Http } from '@core/api/http'
import { MePermission, MeStoreI } from '@core/global/interfaces'
import { coreStore } from '@core/store/coreStore'
import { AbstractUser } from '@core/models/abstractUser'
import { Tools } from '@core/utils/tools'
import { Message } from '@core/service/message'

export class MeStore<T extends AbstractUser> implements MeStoreI {
  @observable
  data: T
  @observable
  isLoading = false
  @observable
  isError = false
  @observable
  isLogout = false
  @observable
  selectTenantIsOpen = false
  tenant: AbstractLabel
  tenants: AbstractLabel[]

  constructor(protected readonly tCreator: { new (anys?: any): T }) {
    makeObservable(this)
  }

  @computed
  get isTenantAdministrator() {
    return this.data?.roles?.some(x => x.code === 'TENANT_ADMINISTRATOR')
  }

  @computed
  get isConnectAs() {
    return Context.isUserSimulation()
  }

  @computed
  get hasRoleAdmin() {
    const roles = this.data?.roles ?? []
    for (const role of roles) {
      if (role.admin) {
        return true
      }
    }
    return false
  }

  @action
  $get(): Promise<T> {
    this.isLoading = true
    return new Promise<T>(async (resolve, reject) => {
      try {
        const data = await Http.$get<T>('/api/authentication/me')
        runInAction(() => {
          this.data = new this.tCreator(data)
          coreStore.eventStore.whenChangeUser = new Date()
          this.isLoading = false
          resolve(this.data)
        })
      } catch (error) {
        reject(error)
        runInAction(() => {
          this.isLoading = false
          this.isError = true
        })
      }
    })
  }

  @action
  async openSelectTenantOrLogin(email: string, password: string) {
    if (this.tenants.length === 0) {
      throw new Error('Aucune application est disponible')
    } else if (this.tenants.length === 1) {
      this.tenant = this.tenants[0]
      await this.login(email, password)
    } else {
      this.tenant = null
      this.selectTenantIsOpen = true
    }
  }

  @action
  closeSelectTenant() {
    this.selectTenantIsOpen = false
  }

  @action
  async getTenants(email: string, password: string) {
    this.isLogout = false
    this.isError = false
    const tenants = await Http.$get<AbstractLabel[]>('/api/user-view', { params: { username: email } })
    this.tenants = tenants?.sort((a, b) => a.label.localeCompare(b.label))
    await this.openSelectTenantOrLogin(email, password)
  }

  @action
  async login(email: string, password: string) {
    try {
      Context.removeIsUserSimulation()
      this.isLoading = true
      await Http.$post('/api/authentication/login', { login: btoa(email + ':' + password), tenantId: this.tenant.id })
      await this.$get()
      this.gotoHomePage()
    } finally {
      runInAction(() => (this.isLoading = false))
    }
  }

  authenticate(provider: 'apple' | 'facebook' | 'google') {
    return new Promise(async (resolve, reject) => {
      const redirectUrl = 'authenticate-me'
      const width = 500
      const height = 500
      const options = Tools.stringifyOptions({
        width: width,
        height: height,
        top: window.screenY + (window.outerHeight - height) / 2.5,
        left: window.screenX + (window.outerWidth - width) / 2
      })
      const siteUrl = location.protocol + '//' + location.host
      const urlToLogin = provider === 'apple' ? '/login/oauth2/custom/authorization/' : '/login/oauth2/authorization/'
      const popup = window.open(siteUrl + urlToLogin + provider + '?redirect_uri=' + siteUrl + '/' + redirectUrl, provider, options)
      if (popup && popup.focus) popup.focus()

      const polling = setInterval(async () => {
        if (!popup || popup.closed || popup.closed == undefined) {
          clearInterval(polling)
          reject('Veuillez ne pas fermer la popup')
        }
        try {
          if (popup.location.href.startsWith(siteUrl + '/' + redirectUrl)) {
            if (popup.location.search || popup.location.hash) {
              const query = Tools.parseQueryString(popup.location.search.substring(1).replace(/\/$/, ''))
              const hash = Tools.parseQueryString(popup.location.hash.substring(1).replace(/[/$]/, ''))
              const params: any = Object.assign({}, query, hash)
              if (params.error) {
                reject(params.error)
              } else {
                try {
                  resolve(await this.$get())
                } catch (e) {
                  Message.error('Votre compte a été supprimé ou bloqué')
                  reject()
                }
              }
            } else {
              try {
                resolve(await this.$get())
              } catch (e) {
                Message.error('Votre compte a été supprimé ou bloqué')
                reject()
              }
            }
            clearInterval(polling)
            popup.close()
          }
        } catch {
          // Ignore DOMException: Blocked a frame with origin from accessing a cross-origin frame.
        }
      }, 500)
    })
  }

  hasPermission(codePermission: string): boolean {
    if (!this.data) {
      return false
    }
    if (this.hasRoleAdmin) {
      return true
    }
    for (const permission of this.data.ui.permissions) {
      if (permission.type.code === codePermission) {
        return true
      }
    }
    return false
  }

  hasCrudPermission(codePermission: string): MePermission {
    if (!this.data) {
      return null
    }
    if (this.hasRoleAdmin) {
      return { canRead: true, canEdit: true }
    }
    for (const permission of this.data.ui.crudPermissions) {
      if (permission.type.code === codePermission) {
        return { canRead: true, canEdit: permission.canEdit }
      }
    }
    return null
  }

  hasOneOrMoreInPermissions(codePermissions: string[]): boolean {
    for (const codePermission of codePermissions) {
      if (this.hasPermission(codePermission)) return true
    }
    return false
  }

  async connectAs(user: T) {
    await Http.$post(`/api/user/${user.id}/simulate-me`)
    Context.setIsUserSimulation()
    await this.$get()
    this.gotoHomePage()
  }

  async connectAsAdmin() {
    await Http.$post('/api/user/unsimulate-me')
    Context.removeIsUserSimulation()
    await this.$get()
    this.gotoUserList()
  }

  async changeEmail(email: string) {
    return await Http.$post(`/api/user/${this.data.id}/change-email`, { username: email })
  }

  async signup(user: T) {
    return await Http.$post('/api/signup', user)
  }

  async logout() {
    await Http.$post('/api/authentication/logout')
    runInAction(() => {
      this.data = null
      this.isLogout = true
      Context.removeIsUserSimulation()
    })
    Message.success('Vous êtes déconnecté')
  }

  gotoUserList() {
    coreStore.routerStore.push('/admin/security/user')
  }

  gotoHomePage() {
    coreStore.routerStore.push('/')
  }
}

import React from 'react'
import { Tabs } from 'antd'
import { useEditContext } from '@core/contexts/editContext'
import { useStoreContext } from '@core/contexts/storeContext'
import { SkLink } from '@core/components'

interface IProps {
  tabs: { label: string; key: string }[]
  onChange: (activeKey: string) => void
}

export const SkPageEditHeaderTabs = (props: IProps) => {
  const editContext = useEditContext()
  const storeContext = useStoreContext()

  const items: { label: React.ReactNode; key: string; closable?: boolean }[] = []

  const getHref = (param: string) => {
    const { pathname, search } = location
    const params = new URLSearchParams(search)
    if (params.get(param)) {
      params.delete(param)
    }
    if (!params.toString().length) return pathname
    return `${pathname}?${params.toString()}`
  }

  const getHrefWithParam = (param: string, value: string) => {
    const { pathname, search } = location
    const params = new URLSearchParams(search)
    if (params.get(param)) {
      params.delete(param)
    }
    if (value) params.append(param, value)
    return `${pathname}?${params.toString()}`
  }

  props.tabs.map((tab, index) => {
    const href = index > 0 ? getHrefWithParam(editContext.urlParamTabKey, tab.key) : getHref(editContext.urlParamTabKey)
    items.push({
      label: (
        <SkLink href={href} id={`${storeContext ? `${storeContext.key}-` : ''}tab-${tab.key}`} className="py-3">
          {tab.label}
        </SkLink>
      ),
      key: tab.key
    })
  })

  return <Tabs items={items} tabBarStyle={{ marginBottom: 0 }} activeKey={editContext.activeKeyTab} className="tabs-with-tab-link" />
}

import * as React from 'react'
import { useEffect, useState } from 'react'
import { ColProps, Form } from 'antd'
import { useEditContext } from '@core/contexts/editContext'
import { FormLayout } from 'antd/es/form/Form'
import classNames from 'classnames'
import { FormContext, SkFormProvider } from '@core/contexts/formContext'
import { observer } from 'mobx-react-lite'

const validateMessages = {
  required: 'Ce champ est obligatoire',
  types: {
    email: 'Cette e-mail n‘est pas valide',
    number: 'Cette valeur n‘est pas un chiffre valide'
  },
  number: {
    range: 'La valeur doit être comprise entre ${min} et ${max}'
  }
}

export interface SkFormProps {
  children?: any
  wrapperCol?: ColProps
  labelCol?: ColProps
  layout?: FormLayout
  className?: string
  floatLabel?: boolean
  guid?: string
  formRef?: any
  sectionsStyle?: 'expandable' | 'panel'
  withValidationMessage?: boolean
  autoFocus?: boolean
  disabled?: boolean
  noPaddingForFormItem?: boolean
}

export const SkForm = observer(<T,>(props: SkFormProps) => {
  const { layout = 'vertical', autoFocus = true } = props
  const [form] = Form.useForm()
  const [formContext] = useState(() => new FormContext(props))
  const editContext = useEditContext()
  const fieldRef = React.useRef(null)

  useEffect(() => {
    let timeout: any = null
    formContext.fields.init(form)
    if (editContext) editContext.formContext = formContext

    // set focus to first input
    if (autoFocus) {
      timeout = setTimeout(() => {
        const inputField = fieldRef?.current.querySelector('input')
        if (inputField) {
          inputField.focus()
        }
      })
    }
    return () => clearTimeout(timeout)
  }, [])

  return (
    <SkFormProvider formContext={formContext}>
      <Form
        form={form}
        layout={layout}
        labelCol={formContext.labelCol}
        wrapperCol={formContext.wrapperCol}
        validateMessages={validateMessages}
        onValuesChange={editContext?.onValuesChange}
        scrollToFirstError={true}
        validateTrigger={['onChange']}
        className={classNames({ 'no-padding-for-form-item': props.noPaddingForFormItem }, { 'w-full': props.className }, props.className)}
      >
        <fieldset disabled={props.disabled} ref={fieldRef}>
          {formContext.fields.isInit && props.children}
        </fieldset>
      </Form>
    </SkFormProvider>
  )
})

import { makeObservable } from 'mobx'
import { AbstractItem } from '@core/models/abstractItem'
import { Tax } from '@common/models/tax/tax'

export class ArticleTax extends AbstractItem {
  tax: Tax

  constructor(data?: Partial<ArticleTax>) {
    super(data)
    makeObservable(this)
    Object.assign(this, data)
    if (this.tax) {
      this.tax = new Tax(this.tax)
    }
  }
}

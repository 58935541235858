import { DatePicker } from 'antd'
import * as React from 'react'
import moment from 'moment'
import { DateRender } from 'rc-picker/lib/panels/DatePanel/DateBody'
import classNames from 'classnames'
import styles from './skCalendarPicker.module.scss'
import { SkIconUilCalendarAlt } from '@core/global/icons'

type IProps = {
  id: string
  value?: moment.Moment
  dateRender?: DateRender<moment.Moment>
  onChange?: (value: moment.Moment | null, dateString: string) => void
  onPanelChange?: (value: moment.Moment | null, dateString: string) => void
  noShadow?: boolean
  open?: boolean
  relative?: boolean
  onBlur?: React.FocusEventHandler<HTMLInputElement>
  onOpenChange?: (open: boolean) => void
  disabled?: boolean
}

export function SkCalendarPicker<T>(props: IProps) {
  return (
    <div className={classNames(styles.skCalendarPicker, { 'relative h-[270px]': props.relative })}>
      <DatePicker
        onOpenChange={props.onOpenChange}
        onPanelChange={props.onChange}
        value={props.value}
        open={props.relative ?? undefined}
        suffixIcon={<SkIconUilCalendarAlt />}
        picker="week"
        className={classNames({ invisible: props.relative }, { 'border-0 !font-bold !shadow-none': !props.relative })}
        allowClear={false}
        popupClassName={props.noShadow ? styles.skCalendarPickerShadowNone : ''}
        dateRender={props.dateRender}
        onChange={props.onChange}
        getPopupContainer={triggerNode => {
          if (!props.relative) return triggerNode
          return triggerNode.parentNode as HTMLElement
        }}
        disabled={props.disabled}
      />
    </div>
  )
}

import React, { useEffect, useState } from 'react'
import { SkDatePicker, SkSelect } from '@core/components'
import { DateTimeEnum } from 'skazy-gescom-common/global/enums'
import moment from 'moment'
import { SkRangePicker } from '@core/components/rangePicker/skRangePicker'
import { SkIconUilTimes } from '@core/global/icons'
import { AbstractLabel } from '@core/models/abstractLabel'
import { SkMonthPicker } from '@core/components/monthPicker/skMonthPicker'

type FromToDuration = {
  fromDuration: string
  toDuration: string
  timeZone: string
}

export type FromTo = {
  granularity?: string
  from: string
  to: string
}

interface IProps {
  id: string
  label: string
  value: FromToDuration | FromTo
  onChange: (value: FromToDuration | FromTo) => void
  includedDateTimes?: DateTimeEnum[]
}

export function SkFilterDatePickerAndDuration(props: IProps) {
  const durationToDay = moment.duration(0, 'd').toJSON()
  const durationTomorrow = moment.duration(1, 'd').toJSON()
  const durationCurrentMonth = { from: moment().startOf('month').format(), to: moment().endOf('month').format() }
  const durationLastMonth = { from: moment().subtract(1, 'months').startOf('month').format(), to: moment().subtract(1, 'months').endOf('month').format() }
  const [selectValue, setSelectValue] = useState<number>()
  const [fromToValue, setFromToValue] = useState<FromTo>()
  const [fromToDuration, setFromToDuration] = useState<FromToDuration>()

  const items = [
    new AbstractLabel({ id: DateTimeEnum.today, label: 'Aujourd’hui' }),
    new AbstractLabel({ id: DateTimeEnum.tomorrow, label: 'Demain' }),
    new AbstractLabel({ id: DateTimeEnum.currentMonth, label: 'Mois en cours' }),
    new AbstractLabel({ id: DateTimeEnum.lastMonth, label: 'Mois dernier' }),
    new AbstractLabel({ id: DateTimeEnum.exact, label: 'Date exacte' }),
    new AbstractLabel({ id: DateTimeEnum.between, label: 'Dates comprise' }),
    new AbstractLabel({ id: DateTimeEnum.start, label: 'A partir du' }),
    new AbstractLabel({ id: DateTimeEnum.end, label: 'Jusqu’au' }),
    new AbstractLabel({ id: DateTimeEnum.exactMonth, label: 'Mois' })
  ].filter(dateTime => props.includedDateTimes == null || props.includedDateTimes.includes(dateTime.id))

  useEffect(() => {
    if (!props.value) {
      setSelectValue(null)
      return
    }
    if (props.value['fromDuration'] === durationToDay) {
      setSelectValue(DateTimeEnum.today)
      setFromToDuration(props.value['fromDuration'])
    } else if (props.value['fromDuration'] === durationTomorrow) {
      setSelectValue(DateTimeEnum.tomorrow)
      setFromToDuration(props.value['fromDuration'])
    } else if (props.value['from'] === durationCurrentMonth.from && props.value['to'] === durationCurrentMonth.to) {
      setSelectValue(DateTimeEnum.currentMonth)
      setFromToValue(props.value as FromTo)
    } else if (props.value['from'] === durationLastMonth.from && props.value['to'] === durationLastMonth.to) {
      setSelectValue(DateTimeEnum.lastMonth)
      setFromToValue(props.value as FromTo)
    } else if (props.value['from'] && props.value['to'] && moment(props.value['to']).isSame('12/31/2999', 'd')) {
      setSelectValue(DateTimeEnum.start)
      setFromToValue(props.value as FromTo)
    } else if (props.value['from'] && props.value['to'] && moment(props.value['from']).isSame('01/01/1900', 'd')) {
      setSelectValue(DateTimeEnum.end)
      setFromToValue(props.value as FromTo)
    } else if (props.value['from'] && props.value['to'] && !moment(props.value['from']).isSame(props.value['to'], 'd')) {
      if (props.value['granularity'] === 'month') {
        setSelectValue(DateTimeEnum.exactMonth)
      } else {
        setSelectValue(DateTimeEnum.between)
      }
      setFromToValue(props.value as FromTo)
    } else {
      setSelectValue(DateTimeEnum.exact)
      setFromToValue(props.value as FromTo)
    }
  }, [props.value])

  const onSelectChange = (v: number) => {
    let from = null
    let fromTo = null
    setSelectValue(v)

    switch (v) {
      case DateTimeEnum.today:
        from = { fromDuration: durationToDay, toDuration: durationToDay, timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone }
        setFromToDuration(from)
        props.onChange(from)
        break
      case DateTimeEnum.tomorrow:
        from = { fromDuration: durationTomorrow, toDuration: durationTomorrow, timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone }
        setFromToDuration(from)
        props.onChange(from)
        break
      case DateTimeEnum.currentMonth:
        fromTo = { ...durationCurrentMonth }
        setFromToValue(fromTo)
        props.onChange(fromTo)
        break
      case DateTimeEnum.lastMonth:
        fromTo = { ...durationLastMonth }
        setFromToValue(fromTo)
        props.onChange(fromTo)
        break
      default:
        if (fromToDuration || fromToValue) {
          setFromToValue(null)
          setFromToDuration(null)
          onChangeDate(null)
        }
        break
    }
  }

  const onChangeDate = (date: Date) => {
    let fromTo: FromTo
    if (selectValue === DateTimeEnum.start) {
      fromTo = date ? { granularity: 'd', from: moment(date).startOf('d').format(), to: moment('12/31/2999').endOf('d').format() } : null
    } else if (selectValue === DateTimeEnum.end) {
      fromTo = date ? { granularity: 'd', from: moment('01/01/1900').startOf('d').format(), to: moment(date).endOf('d').format() } : null
    } else {
      fromTo = date ? { granularity: 'd', from: moment(date).startOf('d').format(), to: moment(date).endOf('d').format() } : null
    }
    props.onChange(fromTo)
    setFromToValue(fromTo)
  }

  const onChangeMonth = (month: Date) => {
    const fromTo = month ? { granularity: 'month', from: moment(month).startOf('month').format(), to: moment(month).endOf('month').format() } : null
    props.onChange(fromTo)
    setFromToValue(fromTo)
  }

  return (
    <>
      <div className="w-full filter">
        <div>
          {(!selectValue ||
            selectValue === DateTimeEnum.today ||
            selectValue === DateTimeEnum.tomorrow ||
            selectValue === DateTimeEnum.currentMonth ||
            selectValue === DateTimeEnum.lastMonth) && (
            <SkSelect
              label={props.label}
              placeholder="Sélectionner ..."
              size="large"
              value={selectValue}
              items={items}
              id={`${props.id}Select`}
              onChange={onSelectChange}
              returnId
            />
          )}
          {selectValue === DateTimeEnum.exact ? (
            <SkDatePicker
              label={<Label label={props.label} labelClose="date exacte" onClose={() => onSelectChange(null)} />}
              value={fromToValue ? moment(fromToValue.to).toDate() : null}
              onChange={onChangeDate}
              id={`${props.id}SkDatePicker`}
              size="large"
              autoFocus
            />
          ) : selectValue === DateTimeEnum.between ? (
            <SkRangePicker
              label={<Label label={props.label} labelClose="dates comprise" onClose={() => onSelectChange(null)} />}
              value={fromToValue}
              onChange={props.onChange}
              id={`${props.id}SkRangePicker`}
              size="large"
              autoFocus
            />
          ) : selectValue === DateTimeEnum.exactMonth ? (
            <SkMonthPicker
              label={<Label label={props.label} labelClose="mois" onClose={() => onSelectChange(null)} />}
              value={fromToValue ? moment(fromToValue.to).toDate() : null}
              onChange={onChangeMonth}
              id={`${props.id}SkMonthPicker`}
              size="large"
              autoFocus
            />
          ) : selectValue === DateTimeEnum.start ? (
            <SkDatePicker
              label={<Label label={props.label} labelClose="à partir du" onClose={() => onSelectChange(null)} />}
              value={fromToValue ? moment(fromToValue.from).toDate() : null}
              onChange={onChangeDate}
              id={`${props.id}SkStartPicker`}
              size="large"
              autoFocus
            />
          ) : selectValue === DateTimeEnum.end ? (
            <SkDatePicker
              label={<Label label={props.label} labelClose="jusqu’au" onClose={() => onSelectChange(null)} />}
              value={fromToValue ? moment(fromToValue.to).toDate() : null}
              onChange={onChangeDate}
              id={`${props.id}SkStartPicker`}
              size="large"
              autoFocus
            />
          ) : (
            <></>
          )}
        </div>
      </div>
    </>
  )
}

const Label = ({ label, labelClose, onClose }: { label: string; labelClose: string; onClose: () => void }) => (
  <div className="flex items-center justify-between">
    {label}
    <a className="ml-3 flex cursor-pointer items-center rounded bg-pink-100 pl-2 text-xs text-pink-500" onClick={onClose}>
      {labelClose} <SkIconUilTimes height={12} />
    </a>
  </div>
)

import { makeAutoObservable, runInAction } from 'mobx'
import { Filter, FilterStoreText } from '@core/api/filter'
import { ApiStore } from '@core/api/apiStore'
import { coreStore } from '@core/store/coreStore'
import { Tools } from '@core/utils/tools'
import { LocalStorageForMe } from '@core/utils/localStorageForMe'

export class FiltersAndViews<T> {
  items: Filter[]
  modalToCreateViewOpen = false
  filtersOpen = false
  viewsOpen = false
  activeViewLabel: string
  isViewLoaded = false
  isInit = false
  views: View[]
  tabs: string[]
  withViewAndLocalStorage = false

  keyPageStore: string
  viewsKey: string
  generalViewKey: string
  lastViewKey: string
  tabsKey: string

  constructor(protected store: ApiStore<T>) {
    makeAutoObservable(this)
    this.keyPageStore = `FilterStore=${this.store.codePermission.toLowerCase()}&Path=${coreStore.keyPathName}`
    this.viewsKey = `${this.keyPageStore}&Views`
    this.generalViewKey = 'GeneralView'
    this.lastViewKey = `${this.keyPageStore}&LastView`
    this.tabsKey = `${this.keyPageStore}&otherTabs`
    runInAction(() => {
      this.activeViewLabel = this.lastActiveViewLabelStorage ?? this.generalViewKey
      this.views = this.viewsStorage
      this.tabs = this.tabsStorage
    })
  }

  get viewOthers(): View[] {
    return this.views.filter(x => x.label !== this.generalViewKey)
  }

  get countMoreFilter() {
    return this.items.length - this.items.filter(x => x.isActive).length
  }

  get lastActiveViewLabelStorage() {
    return LocalStorageForMe.getItem(this.lastViewKey)
  }

  set lastActiveViewLabelStorage(name: string) {
    this.activeViewLabel = name
    LocalStorageForMe.setItem(this.lastViewKey, name)
  }

  get viewsStorage(): View[] {
    return LocalStorageForMe.getItem(this.viewsKey) ?? []
  }

  set viewsStorage(data: View[]) {
    runInAction(() => (this.views = data))
    LocalStorageForMe.setItem(this.viewsKey, data)
  }

  get tabsStorage(): string[] {
    return LocalStorageForMe.getItem(this.tabsKey) ?? []
  }

  set tabsStorage(data: string[]) {
    runInAction(() => (this.tabs = data))
    LocalStorageForMe.setItem(this.tabsKey, data)
  }

  get needMoreFilters() {
    return this.items?.length > 4 || this.items?.some(x => !x.isActive)
  }

  get filtersToShowForMobile() {
    const filters = []
    for (let i = 1; i < this.items?.length; i++) {
      let item = this.items[i]
      if (item instanceof FilterStoreText) {
        if (item.data?.trim().length > 0) filters.push(item)
      } else {
        if (!Tools.isNullOrUndefined(item.data)) filters.push(item)
      }
    }
    return filters
  }

  toggleModalToCreate = () => {
    this.modalToCreateViewOpen = !this.modalToCreateViewOpen
  }

  toggleFiltersOpen = () => {
    this.filtersOpen = !this.filtersOpen
  }

  toggleViewsOpen = () => {
    this.viewsOpen = !this.viewsOpen
  }

  removeFromTabs = async (viewLabel: string) => {
    this.tabsStorage = this.tabs.filter(x => x !== viewLabel)
    if (viewLabel === this.activeViewLabel) {
      await this.setView(this.tabs.length ? this.tabs[this.tabs.length - 1] : this.generalViewKey, true)
    }
  }

  setView = async (viewLabel: string, withSearch: boolean) => {
    await runInAction(async () => {
      if (viewLabel !== this.generalViewKey) {
        if (!this.tabsStorage.some(x => x === viewLabel)) {
          this.tabsStorage = [...this.tabsStorage, viewLabel]
        }
      }
      this.activeViewLabel = viewLabel
      this.lastActiveViewLabelStorage = viewLabel
      const view = this.views.find(x => x.label === viewLabel)
      if (view?.filters) {
        this.items?.forEach(x => {
          x.data = null
          x.isActive = false
        })
        this.setFilters(view.filters)
      }
      if (withSearch) await this.store.setQueryToSearch()
    })
  }

  setTabActive = async (viewLabel: string) => {
    if (viewLabel === this.activeViewLabel) return
    await this.setView(viewLabel, true)
  }

  saveViewsInStorage = (viewLabel: string) => {
    const viewIndex = this.views.findIndex(x => x.label === viewLabel)
    const views = this.views
    const viewToSave = new View()
    viewToSave.label = viewLabel
    viewToSave.filters = []
    this.items?.forEach(x => {
      viewToSave.filters.push({
        data: x.data,
        isActive: x.isActive,
        propertyToFilter: x.propertyToFilter,
        isCustom: x.isCustom
      })
    })
    if (viewIndex < 0) {
      views.push(viewToSave)
      if (viewLabel !== this.generalViewKey) this.tabsStorage = [...this.tabsStorage, viewLabel]
    } else {
      views[viewIndex] = viewToSave
    }
    this.viewsStorage = views
  }

  removeView = async (viewLabel: string) => {
    this.viewsStorage = this.views.filter(x => x.label !== viewLabel)
    await this.removeFromTabs(viewLabel)
  }

  reset = async () => {
    this.items?.forEach(filter => {
      filter.data = null
    })
    await this.store.setQueryToSearch()
  }

  save = () => {
    this.saveViewsInStorage(this.activeViewLabel)
  }

  toggleFilterIsActive = async (filter: Filter) => {
    filter.isActive = !filter.isActive
    if (filter.data) {
      await this.store.setQueryToSearch()
    } else this.save()
  }

  loadView = () => {
    this.setView(this.activeViewLabel, false).then()
    this.isViewLoaded = true
  }

  setFilters = (filters: Filter[]) => {
    filters.forEach(filterSave => {
      const filter = this.items?.find(x => x.propertyToFilter === filterSave.propertyToFilter)
      if (filter) {
        filter.data = filterSave.data
        filter.isActive = filterSave.isActive
      }
    })
  }
}

class View {
  label: string
  filters: any[]
}

import React, { useEffect } from 'react'
import { Switch } from 'antd'
import { SwitchSize } from 'antd/es/switch'
import { CheckOutlined, CloseOutlined } from '@ant-design/icons'
import { WithFormItem } from '@core/components/form'
import { useFormContext } from '@core/contexts/formContext'
import { IPropsInput, InitInput } from '@core/utils/initInput'
import { ListenInput } from '@core/utils/listenInput'

type IProps<T, Y> = IPropsInput<T, Y> & {
  placeholder?: string
  size?: SwitchSize
  inline?: boolean
  loading?: boolean
}

export function SkSwitch<T>(props: IProps<T, boolean>) {
  const formContext = useFormContext()
  const { id } = InitInput(props, 'SkSwitch')
  const { onChangeInput, value, setFieldsValue } = ListenInput(props, id)

  useEffect(() => {
    setFieldsValue(value)
  }, [value])

  const Wrapper = () => (
    <WithFormItem {...props} id={id} value={value}>
      <Switch
        checkedChildren={<CheckOutlined />}
        unCheckedChildren={<CloseOutlined />}
        checked={value}
        size={props.size}
        onChange={onChangeInput}
        className="flex items-center"
        disabled={props.disabled || formContext.props.disabled}
        loading={props.loading}
      />
    </WithFormItem>
  )

  if (props.inline)
    return (
      <div className="ant-label-and-control-inline">
        <Wrapper />
      </div>
    )

  return <Wrapper />
}

import React, { useEffect } from 'react'
import { rootStore } from '@/stores/rootStore'
import { UilSetting } from '@iconscout/react-unicons'
import { Dropdown, Menu, Space, Spin, Tooltip } from 'antd'
import { Observer, observer } from 'mobx-react-lite'
import { SkBtn, SkBtnCircle } from '@core/components/btns'
import { SkMenuItem } from '@core/components/menu/skMenuItem'
import { SkSearch } from '@core/components/search/skSearch'
import styles from './skAsideApp.module.scss'
import { SkAside, SkAsideBody, SkAsideFooter, SkAsideHeader } from '@core/components/aside/skAside'
import { CodeBundleEnum, VariantTrackedStateEnum } from '@common/global/enums'
import { SkIconUilAtom, SkIconUilBoltAlt, SkIconUilHistory, SkIconUilSetting, SkIconUilSignout, SkIconUilUser } from '@core/global/icons'
import { coreStore } from '@core/store/coreStore'
import { AppStore } from '@/stores/appStore'
import { HandleKeyStore } from '@/stores/handleKeyStore'
import { useOnMount, useOnUnmount } from '@core/utils/hooks'
import Link from 'next/link'
import { ModalTypeEnum } from '@/stores/shortcutsStore'
import { referentialStore } from '@common/models/referential/referentialStore'
import dynamic from 'next/dynamic'
import { useIsMobile } from '@core/utils/hooks/useIsMobile'
import { SkLogoAndBrand } from '@/layouts/default/skLogoAndBrand'
import { useRouter } from 'next/router'

const SkEstimateFullModalEdit = dynamic(() => import('@/components/estimateFull/edit/estimateFullEditContext'), { ssr: false })
const SkShortcutsModal = dynamic(() => import('@/layouts/default/shorcutsModal/skShortcutsModal'), { ssr: false })
const SkEstimateModalEdit = dynamic(() => import('@/components/estimate/edit/estimateEditContext'), { ssr: false })
const SkSearchModal = dynamic(() => import('@/layouts/default/searchModal/skSearchModal'), { ssr: false })
const SkTransfertsShipmentsModalEdit = dynamic(() => import('@/components/transfert/shipment/modalEdit/skTransfertsShipmentsModalEdit'), { ssr: false })
const SkTransfertsRegulationsModalEdit = dynamic(() => import('@/components/transfert/regulations/modalEdit/skTransfertsRegulationsModalEdit'), { ssr: false })
const SkTransfertsReplenishmentsModalEdit = dynamic(() => import('@/components/transfert/replenishments/modalEdit/skTransfertsReplenishmentsModalEdit'), { ssr: false })
const SkTransfertsChangeStateAndReceiptModalEdit = dynamic(() => import('@/components/transfert/changeStateAndReceipt/modalEdit/skTransfertsChangeStateAndReceiptModalEdit'), {
  ssr: false
})
const SkTransfertsDeletionsModalEdit = dynamic(() => import('@/components/transfert/deletions/modalEdit/skTransfertsDeletionsModalEdit'), { ssr: false })
const SkTransfertsDestructionsModalEdit = dynamic(() => import('@/components/transfert/destructions/modalEdit/skTransfertsDestructionsModalEdit'), { ssr: false })
const SkTransfertsReceiptsModalEdit = dynamic(() => import('@/components/transfert/receipts/modalEdit/skTransfertsReceiptsModalEdit'), { ssr: false })

const SkAsideApp = observer(function SkAsideApp() {
  const { eventStore, meStore } = coreStore
  const { menuStore, shortcutsStore, appStore } = rootStore
  const isMobile = useIsMobile()
  const router = useRouter()

  const menuItems = []
  menuStore.links?.forEach(link => {
    if (link.children?.length) {
      const children = []
      {
        link.children.forEach(submenu => {
          if (submenu.path) {
            children.push({
              key: submenu.path,
              label: (
                <div key={submenu.label} id={`submenu${submenu.id}`}>
                  <Link href={submenu.path} as={submenu.path}>
                    {submenu.label}
                  </Link>
                </div>
              ),
              id: `submenu-${submenu.id}`
            })
          } else {
            const children2 = []
            submenu.children.forEach(submenu2 => {
              children2.push({
                key: submenu2.path,
                label: (
                  <div key={submenu2.label} id={`submenu${submenu2.id}`}>
                    <Link href={submenu2.path} as={submenu2.path}>
                      {submenu2.label}
                    </Link>
                  </div>
                ),
                id: `submenu-${submenu2.id}`
              })
            })
            if (children2.length)
              children.push({
                key: submenu.label,
                label: submenu.label,
                id: `submenu-${submenu.id}`,
                children: children2
              })
          }
        })
      }
      if (children.length)
        menuItems.push({
          key: link.label,
          label: link.label,
          id: `menu-${link.id}`,
          icon: link.icon && <link.icon width={18} className="mr-2 text-dark-200" />,
          children
        })
    } else if (link.path) {
      menuItems.push({
        key: link.path,
        label: (
          <div key={link.label} id={`menu-${link.id}`}>
            <Link href={link.path} as={link.path} passHref>
              <Space>
                {link.icon && <link.icon width={18} className="mr-2 text-dark-200" />}
                {link.label}
              </Space>
            </Link>
          </div>
        ),
        id: `menu-${link.id}`
      })
    }
  })

  useEffect(() => {
    menuStore.setDefaultAsideMenuOpenKeys()
  }, [menuStore.links, router.route])

  useOnMount(() => {
    rootStore.handleKeyStore = new HandleKeyStore()
  })

  useOnUnmount(() => rootStore.handleKeyStore?.removeListeners())

  return (
    <>
      <SkAside>
        {!isMobile && (
          <SkAsideHeader>
            <div className="flex items-center justify-between pr-4">
              <Link href="/" passHref id="gotoHomePage">
                <div className="mt-2 mb-3 flex items-center justify-start">
                  <SkLogoAndBrand />
                </div>
              </Link>
              {eventStore.actionsInProgress.length > 0 && (
                <span onClick={() => eventStore.openAndUpdateNotificationActionsInProgress()}>
                  <Spin size="small" />
                </span>
              )}
            </div>
          </SkAsideHeader>
        )}
        <SkAsideBody className={styles.skAsideBody}>
          {!meStore.isTenantAdministrator && !isMobile && (
            <>
              <div className="flex-center mb-3 w-full px-2">
                <Search appStore={appStore} />
                {referentialStore.data.tenantProperties.hasBundle(CodeBundleEnum.stock) && (
                  <>
                    <Tooltip placement="bottom" title="Actions rapides (a)">
                      <div
                        id="skHeaderShortcuts"
                        className="flex-center flex-center relative ml-2 h-8 w-8 cursor-pointer rounded bg-dark-400 p-1 text-xs text-gray-300"
                        onClick={shortcutsStore.openModal}
                      >
                        <SkIconUilBoltAlt width="16" height="16" />
                      </div>
                    </Tooltip>
                    <ModalsForSkShortcutsModal />
                  </>
                )}
              </div>
            </>
          )}
          <Menu
            theme="dark"
            mode="inline"
            openKeys={menuStore.asideMenuOpenKeys}
            selectedKeys={[router.route.replace('/[id]', '')]}
            onOpenChange={menuStore.onAsideMenuOpenChange}
            onSelect={menuStore.onAsideMenuSelect}
            items={menuItems}
          />
        </SkAsideBody>
        <Footer />
      </SkAside>
      {shortcutsStore.modalIsOpen && <SkShortcutsModal />}
      {appStore.modalSearchStoreIsOpen && <SkSearchModal />}
    </>
  )
})

function ModalsForSkShortcutsModal() {
  const { shortcutsStore } = rootStore
  return (
    <>
      <Observer>
        {() => (
          <>
            {shortcutsStore.modalTypeEditIsOpen[ModalTypeEnum.skTransfertsShipments] && (
              <SkTransfertsShipmentsModalEdit onClose={() => shortcutsStore.toggleModal(ModalTypeEnum.skTransfertsShipments)} />
            )}
            {shortcutsStore.modalTypeEditIsOpen[ModalTypeEnum.skTransfertsReceipts] && (
              <SkTransfertsReceiptsModalEdit
                title="Réceptionner une expédition"
                withShipment
                includeVariantsTracked
                includeVariantWithOnlyStockTracking
                previous={shortcutsStore.skTransfertsReceiptsModalPrevious}
                onClose={() => shortcutsStore.toggleModalSkTransfertsReceiptsIsOpen()}
                canColSetCost={referentialStore.data.tenantProperties.articleProperties.withCost}
              />
            )}
            {shortcutsStore.modalTypeEditIsOpen[ModalTypeEnum.skTransfertsAddInStock] && (
              <SkTransfertsReceiptsModalEdit
                title="Ajouter dans le stock"
                withShipment={false}
                includeVariantIsLotTracked
                includeVariantWithOnlyStockTracking
                onClose={() => shortcutsStore.toggleModal(ModalTypeEnum.skTransfertsAddInStock)}
                canColSetCost={referentialStore.data.tenantProperties.articleProperties.withCost}
                canDuplicate
              />
            )}
            {shortcutsStore.modalTypeEditIsOpen[ModalTypeEnum.skTransfertsReturnInStock] && (
              <SkTransfertsChangeStateAndReceiptModalEdit
                title="Retourner dans le stock"
                actionLabel="Retourner ces articles"
                onClose={() => shortcutsStore.toggleModal(ModalTypeEnum.skTransfertsReturnInStock)}
                variantTrackedStateId={VariantTrackedStateEnum.toTest}
              />
            )}
            {shortcutsStore.modalTypeEditIsOpen[ModalTypeEnum.skTransfertsStock] && (
              <SkTransfertsReceiptsModalEdit
                title="Transférer"
                actionLabel="Enregistrer ce transfert"
                includeVariantsTracked
                includeVariantWithOnlyStockTracking
                withDepartureWarehouse
                withShipment={false}
                onClose={() => shortcutsStore.toggleModal(ModalTypeEnum.skTransfertsStock)}
              />
            )}
            {shortcutsStore.modalTypeEditIsOpen[ModalTypeEnum.skTransfertsToSeller] && (
              <SkTransfertsReceiptsModalEdit
                title="Affecter à un revendeur"
                actionLabel="Affecter ces articles"
                includeVariantsTracked
                includeVariantWithOnlyStockTracking
                withDepartureWarehouse
                withShipment={false}
                onClose={() => shortcutsStore.toggleModal(ModalTypeEnum.skTransfertsToSeller)}
              />
            )}
            {shortcutsStore.modalTypeEditIsOpen[ModalTypeEnum.skTransfertsReconditioned] && (
              <SkTransfertsChangeStateAndReceiptModalEdit
                title="Reconditionner"
                actionLabel="Enregistrer ce reconditionnement"
                onClose={() => shortcutsStore.toggleModal(ModalTypeEnum.skTransfertsReconditioned)}
                variantTrackedStateId={VariantTrackedStateEnum.reconditioned}
              />
            )}
            {shortcutsStore.modalTypeEditIsOpen[ModalTypeEnum.skTransfertsScrap] && (
              <SkTransfertsChangeStateAndReceiptModalEdit
                title="Mettre au rebut"
                actionLabel="Enregistrer cette mise en rebut"
                onClose={() => shortcutsStore.toggleModal(ModalTypeEnum.skTransfertsScrap)}
                variantTrackedStateId={VariantTrackedStateEnum.toDestroy}
              />
            )}
            {shortcutsStore.modalTypeEditIsOpen[ModalTypeEnum.skTransfertsDestructions] && (
              <SkTransfertsDestructionsModalEdit
                title="Organiser l'enlèvement"
                actionLabel="Enregistrer l'enlèvement"
                onClose={() => shortcutsStore.toggleModal(ModalTypeEnum.skTransfertsDestructions)}
              />
            )}
            {shortcutsStore.modalTypeEditIsOpen[ModalTypeEnum.skEstimate] && <SkEstimateModalEdit onClose={() => shortcutsStore.toggleModal(ModalTypeEnum.skEstimate)} />}
            {shortcutsStore.modalTypeEditIsOpen[ModalTypeEnum.skTransfertsRegulations] && (
              <SkTransfertsRegulationsModalEdit onClose={() => shortcutsStore.toggleModal(ModalTypeEnum.skTransfertsRegulations)} title="Ajouter en stock chez Socalog" />
            )}
            {shortcutsStore.modalTypeEditIsOpen[ModalTypeEnum.skTransfertsReplenishment] && (
              <SkTransfertsReplenishmentsModalEdit onClose={() => shortcutsStore.toggleModal(ModalTypeEnum.skTransfertsReplenishment)} title="Demander un réappro Socalog" />
            )}
            {shortcutsStore.modalTypeEditIsOpen[ModalTypeEnum.skTransfertsDeletions] && (
              <SkTransfertsDeletionsModalEdit
                onClose={() => shortcutsStore.toggleModal(ModalTypeEnum.skTransfertsDeletions)}
                title="Supprimer"
                actionLabel="Supprimer ces articles"
              />
            )}
            {shortcutsStore.modalTypeEditIsOpen[ModalTypeEnum.skEstimateFullModalEdit] && (
              <SkEstimateFullModalEdit onClose={() => shortcutsStore.toggleModal(ModalTypeEnum.skEstimateFullModalEdit)} />
            )}
          </>
        )}
      </Observer>
    </>
  )
}

const Footer = observer(() => {
  return (
    <SkAsideFooter>
      <Dropdown menu={{ items: getUserMenuItems() }} trigger={['click', 'contextMenu']} placement="topRight">
        <div className="flex cursor-pointer items-center justify-start" onClick={e => e.preventDefault()}>
          <div className={`flex-center relative h-10 w-10 rounded-full font-bold text-white ${coreStore.meStore.isConnectAs ? 'bg-red-600' : 'bg-blue2-600'}`}>
            {coreStore.meStore.data.customer ? (
              <>
                {coreStore.meStore.data.customer?.firstName?.substring(0, 1)}
                {coreStore.meStore.data.customer?.lastName?.substring(0, 1)}
              </>
            ) : (
              <SkIconUilUser width="19" />
            )}
            <div className="bg-dark flex-center absolute absolute bottom-0 right-0 h-4 w-4 rounded-full text-white">
              <UilSetting />
            </div>
          </div>
          <span className="pl-2">{coreStore.meStore.data.customer?.firstName}</span>
        </div>
      </Dropdown>
    </SkAsideFooter>
  )
})

function getUserMenuItems() {
  const items = []
  const logout = async () => {
    await coreStore.meStore.logout()
    coreStore.routerStore.push('/registration/login')
  }
  if (coreStore.meStore.hasRoleAdmin) {
    items.push({
      key: 'appVersion',
      label: (
        <SkMenuItem
          id="appVersion"
          label="Version de l’application"
          tooltip={rootStore.actuatorStore.data && JSON.stringify(rootStore.actuatorStore.data)}
          withTooltipBorderInfo={false}
          icon={<SkIconUilAtom />}
        />
      )
    })
    items.push({
      type: 'divider'
    })
  }

  if (coreStore.meStore.data.providerIsLocal)
    items.push({
      key: 'personalInfo',
      label: <SkMenuItem id="personalInfo" href="/account-settings/personal-info" label="Paramètres du compte" icon={<SkIconUilSetting />} withAngleRight />
    })
  items.push({
    type: 'divider'
  })
  items.push({
    key: 'logout',
    label: (
      <div className="min-w-[300px]" onClick={logout} id="menuLogout">
        <Tooltip title="Se déconnecter">
          <div className="flex w-full items-center justify-between">
            {coreStore.meStore.data.customer ? (
              <div>
                <div className="w-full truncate text-left font-semibold">{coreStore.meStore.data.customer?.label}</div>
                <div className="w-full truncate text-left">{coreStore.meStore.data.customer?.email}</div>
              </div>
            ) : (
              <div>Se déconnecter</div>
            )}
            <div className="justify-end">
              <SkBtnCircle id="logout" icon={<SkIconUilSignout />} onClick={null} />
            </div>
          </div>
        </Tooltip>
      </div>
    )
  })

  if (coreStore.meStore.isConnectAs)
    items.push({
      key: 'connectAsAdmin',
      label: (
        <SkBtn id="connectAsAdmin" size="small" icon={<SkIconUilHistory />} danger={true} onClick={() => coreStore.meStore.connectAsAdmin()}>
          annuler le se connecter en tant que
        </SkBtn>
      )
    })

  return items
}

function Search(props: { appStore: AppStore }) {
  return <SkSearch id="skHeaderSearch" placeholder="Rechercher (r)" onChange={null} iconPrefix={true} className={styles.skSearch} onFocus={props.appStore.openModalSearchStore} />
}

export default SkAsideApp

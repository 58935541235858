import { AppStore } from '@/stores/appStore'
import { ApiStore } from '@core/api/apiStore'
import { MenuStore } from '@/stores/menuStore'
import { ActuatorStore } from '@common/models/actuator/actuatorStore'
import { ShortcutsStore } from '@/stores/shortcutsStore'
import { action, makeObservable, observable } from 'mobx'
import { EventStore } from '@/stores/eventStore'
import { HandleKeyStore } from '@/stores/handleKeyStore'

export class RootStore {
  actuatorStore: ActuatorStore
  menuStore: MenuStore
  currentStore: ApiStore<any>
  shortcutsStore: ShortcutsStore
  appStore: AppStore
  @observable
  isLoaded = false
  isUiMobile = false
  eventStore: EventStore
  handleKeyStore: HandleKeyStore

  constructor() {
    makeObservable(this)
  }

  @action
  init() {
    this.actuatorStore = new ActuatorStore()
    this.menuStore = new MenuStore()
    this.shortcutsStore = new ShortcutsStore()
    this.appStore = new AppStore()
    this.appStore = new AppStore()
    this.eventStore = new EventStore()
    this.isLoaded = true
  }
}

export const rootStore = new RootStore()

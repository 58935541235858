import { PaginationResult, Query, RecursivePartial, RequestConfig } from '@core/global/interfaces'
import { FileTools } from '@core/utils/fileTools'
import axios, { AxiosError, CancelTokenSource } from 'axios'
import { action, computed, makeObservable, observable, runInAction, toJS } from 'mobx'
import { AbstractItem } from '../models/abstractItem'
import { ModalService } from '@core/service/modal'
import { Recursive } from '@core/utils/recursive'
import cloneDeep from 'lodash/cloneDeep'
import { message } from 'antd'
import { FiltersAndViews } from '@core/api/filtersAndViews'
import { Tools } from '@core/utils/tools'
import { Http } from '@core/api/http'
import { ActionInProgress } from '@core/store/eventStore'
import { Message } from '@core/service/message'
import moment from 'moment'
import { coreStore } from '@core/store/coreStore'
import { UseQueryOptions, useQuery } from '@tanstack/react-query'

export abstract class ApiStore<T extends AbstractItem> {
  @observable
  paginationResult: PaginationResult<T>
  @observable
  item: T
  @observable
  modalToEditIsOpen = false
  @observable
  itemIsLoading = false
  @observable
  isSearching = false
  @observable
  $pdfLoading = false
  @observable
  $csvLoading = false
  @observable
  $xlsLoading = false
  @observable
  isDownloading = false
  @observable
  isUploading = false
  @observable
  isDeleting = false
  @observable
  isIndeterminate = false
  @observable
  items: T[]
  filtersAndViews: FiltersAndViews<T>
  @observable
  querySearch = new Query()
  initObjectWhenCreate: any
  searchTypeLight: SearchTypeLight
  withMessageSave = true
  cancelTokens: CancelTokens = {}
  readonly canEdit: boolean
  readonly canRead: boolean
  entityNameSuffix?: string
  queryClient: any
  filterInit: RecursivePartial<T>
  customFilterInit: any

  //#region constructor
  protected saveIsAsync = false
  protected canBePathPublicIfNotMe: boolean

  protected constructor(
    protected baseUrlHost: string,
    public baseUrlResource: string,
    public tCreator: { new (anys?: any): T },
    public objectNameGenre: 'male' | 'female',
    public objectName: string,
    public itemName: string,
    public thisItemName: string,
    public icon: any,
    public route: string = '',
    public typeEdit: 'route' | 'modal' = 'modal'
  ) {
    makeObservable(this)
    if (this.baseUrlResource.charAt(0) !== '/') {
      this.baseUrlResource = '/'.concat(this.baseUrlResource)
    }
    const crudPermission = coreStore.meStore.hasCrudPermission(this.codePermission)
    this.canEdit = crudPermission?.canEdit
    this.canRead = crudPermission?.canRead
    this.filtersAndViews = new FiltersAndViews(this)
  }

  @computed
  get codePermission() {
    const resourceName = this.baseUrlResource.split('/')
    return resourceName[resourceName.length - 1].replace(/[-/]/g, '_').toUpperCase()
  }

  @computed
  get key() {
    return this.codePermission.replace(/_/g, '-')
  }

  @computed
  get keyFirst() {
    return [this.key, 'first']
  }

  @computed
  get baseUrl() {
    if (this.canBePathPublicIfNotMe && !coreStore.meStore?.data) {
      return this.baseUrlHost.concat(this.baseUrlResource).concat('/public')
    }
    return this.baseUrlHost.concat(this.baseUrlResource)
  }

  onBeforeSave?(item: T): Promise<void>

  onAfterSave?(item: T, ui: (typeof item)['ui']): Promise<void>

  onAfterSearch?(paginationResult: PaginationResult<T>): void

  onInvalidateQueries?(): void

  //#region Public Method
  @action
  async $find(id: number | string, query?: Query<T>): Promise<T> {
    if (!id) {
      this.gotoList()
      return Promise.reject('$find had an id not defined')
    }
    try {
      this.itemIsLoading = true
      const data = await Http.$get<T>(`${this.getBaseUrl(query)}/${id}`, this.getOptions(query))
      return runInAction(() => {
        this.item = new this.tCreator(data)
        if (!query) this.setQueryDataForId(Number(id), this.item)
        return this.item
      })
    } catch (error) {
      this.handleErrors(error)
      throw error
    } finally {
      runInAction(() => (this.itemIsLoading = false))
    }
  }

  @action
  async $reload(item: T, query?: Query<T>) {
    if (item.ui) item.ui.isLoading = true
    const data = await this.$find(item.id, query)
    return runInAction(() => {
      Object.assign(item, data)
      return data
    })
  }

  @action
  async loadItemOrCreateNewItem(itemId?: number) {
    return new Promise<T>(async resolve => {
      if (typeof itemId === 'string' || typeof itemId === 'number') {
        await this.$find(Number(itemId))
      } else if (!itemId && !this.item && this.tCreator) {
        this.createNewItem()
      }
      resolve(this.item)
    })
  }

  @action
  createNewItem() {
    this.item = new this.tCreator(this.initObjectWhenCreate)
  }

  @action
  async $get(query?: Query<T>): Promise<T> {
    try {
      this.itemIsLoading = true
      const data = await Http.$get<T>(this.getBaseUrl(query), this.getOptions(query))
      return this.resolve(data, query)
    } catch (error) {
      this.handleErrors(error)
      throw error
    } finally {
      runInAction(() => (this.itemIsLoading = false))
    }
  }

  @action
  async $post(item: T, query?: Query<T>, withConstructQueryFilterAndCustomFilterAccordingFilters = false): Promise<T> {
    try {
      this.itemIsLoading = true
      if (withConstructQueryFilterAndCustomFilterAccordingFilters) this.constructQueryFilterAndCustomFilterAccordingFilters(query)
      const data = await Http.$post<T>(this.getBaseUrl(query), item, this.getOptions(query))
      return this.resolve(data, query)
    } catch (error) {
      this.handleErrors(error)
      throw error
    } finally {
      runInAction(() => (this.itemIsLoading = false))
    }
  }

  @action
  async $postArray(items: T[], query?: Query<T>): Promise<T[]> {
    try {
      const data = await Http.$post<T[]>(this.getBaseUrl(query), items, this.getOptions(query))
      return this.resolveArray(data, query)
    } catch (error) {
      this.handleErrors(error)
      throw error
    }
  }

  @action
  async $put(item: T, query?: Query<T>): Promise<T> {
    try {
      this.itemIsLoading = true
      const data = await Http.$put<T>(this.getBaseUrl(query), item, this.getOptions(query))
      return this.resolve(data, query)
    } catch (error) {
      this.handleErrors(error)
      throw error
    } finally {
      runInAction(() => (this.itemIsLoading = false))
    }
  }

  @action
  async $putWithResponseType<Y>(item: T, query?: Query<T>): Promise<Y> {
    try {
      this.itemIsLoading = true
      return await Http.$put<Y>(this.getBaseUrl(query), item, this.getOptions(query))
    } catch (error) {
      this.handleErrors(error)
      throw error
    } finally {
      runInAction(() => (this.itemIsLoading = false))
    }
  }

  @action
  async $search(query?: Query<T>) {
    try {
      this.isSearching = true
      this.constructQueryFilterAndCustomFilterAccordingFilters(query)
      const data = await Http.$get<PaginationResult<T>>(this.getBaseUrl(query, this.searchTypeLight), this.getOptions(query, 'search'))
      return runInAction(() => {
        this.paginationResult = this.resolvePaginationResult(data, query)
        this.onAfterSearch && this.onAfterSearch(this.paginationResult)
        return this.paginationResult
      })
    } catch (error) {
      this.handleErrors(error)
      throw error
    } finally {
      runInAction(() => (this.isSearching = false))
    }
  }

  cancelAxios(type: 'search' | 'all' | 'first' | 'other') {
    if (this.cancelTokens[type]) this.cancelTokens[type].cancel()
    this.cancelTokens[type] = axios.CancelToken.source()
    return this.cancelTokens[type]
  }

  async $searchWithQuery() {
    return await this.$search(this.querySearch).then(data => {
      if (this.filtersAndViews.withViewAndLocalStorage) this.filtersAndViews.save()
      return data
    })
  }

  loadFiltersAndViews() {
    if (this.filtersAndViews.withViewAndLocalStorage) this.filtersAndViews.loadView()
    this.setQueryToSearch()
  }

  @action
  setQueryToSearch() {
    this.querySearch.params.page = 0
    this.constructQueryFilterAndCustomFilterAccordingFilters(this.querySearch)
    this.filtersAndViews.isInit = true
  }

  @action
  async $download(
    item: T,
    subUrlPath: string,
    typeFile: string,
    extension: string,
    query?: Query<T>,
    withConstructQueryFilterAndCustomFilterAccordingFilters = true,
    fileName: string = null
  ): Promise<any> {
    try {
      this.setDownLoading(true, item)
      if (!query) {
        query = new Query()
      }
      if (!query.subUrlPath) {
        query.subUrlPath = ''
      }
      query.subUrlPath += subUrlPath
      if (withConstructQueryFilterAndCustomFilterAccordingFilters) this.constructQueryFilterAndCustomFilterAccordingFilters(query)
      const data = await Http.$get(this.getBaseUrl(query), {
        ...this.getOptions(query),
        responseType: 'arraybuffer'
      })
      FileTools.download(data, typeFile, fileName ?? `${this.objectName}-${moment().format('DD-MM-YYYY')}`, extension)
    } catch (error) {
      this.handleErrors(error)
    } finally {
      this.setDownLoading(false, item)
    }
  }

  @action
  setDownLoading(value: boolean, item?: T) {
    this.isDownloading = value
    if (item?.ui) item.ui.isDownLoading = value
  }

  @action
  setItem(item?: T) {
    if (this.item) {
      Object.assign(this.item, item)
    } else {
      this.item = item
    }
    return this
  }

  @action
  setNewItem(item?: T) {
    this.item = new this.tCreator(toJS(item))
    return this
  }

  @action
  setSearchTypeLight(type: SearchTypeLight) {
    this.searchTypeLight = type
    return this
  }

  @action
  async $pdf(query?: Query<T>, withConstructQueryFilterAndCustomFilterAccordingFilters = true, fileName: string = null): Promise<any> {
    try {
      this.$pdfLoading = true
      await this.$download(this.item, '/pdf', 'application/pdf', 'pdf', query, withConstructQueryFilterAndCustomFilterAccordingFilters, fileName)
    } finally {
      runInAction(() => (this.$pdfLoading = false))
    }
  }

  @action
  async $csv(query?: Query<T>, withConstructQueryFilterAndCustomFilterAccordingFilters = true, fileName: string = null): Promise<any> {
    try {
      this.$csvLoading = true
      await this.$download(this.item, '/csv', 'text/csv', 'csv', query, withConstructQueryFilterAndCustomFilterAccordingFilters, fileName)
    } finally {
      runInAction(() => (this.$csvLoading = false))
    }
  }

  @action
  async $xls(query?: Query<T>, withConstructQueryFilterAndCustomFilterAccordingFilters = true, fileName: string = null): Promise<any> {
    try {
      this.$xlsLoading = true
      await this.$download(this.item, '/xls', 'application/octet-stream', 'xls', query, withConstructQueryFilterAndCustomFilterAccordingFilters, fileName)
    } finally {
      runInAction(() => (this.$xlsLoading = false))
    }
  }

  @action
  async $first(query?: Query<T>): Promise<T> {
    try {
      query = { ...query, params: { ...query?.params, size: 1, page: 0 } }
      this.constructQueryFilterAndCustomFilterAccordingFilters(query)
      this.isSearching = true
      const data = await Http.$get<PaginationResult<T>>(this.getBaseUrl(query), this.getOptions(query, 'first'))
      runInAction(() => (this.isSearching = false))
      return this.resolvePaginationResultForOne(data)
    } catch (error) {
      this.handleErrors(error)
      throw error
    } finally {
      runInAction(() => (this.isSearching = false))
    }
  }

  useFirstCached(query?: Query<T>, addonQueryKey?: string | number, onSuccess?: (data: T) => void) {
    const queryKey = [this.key]
    if (addonQueryKey) queryKey.push(addonQueryKey.toString())
    // eslint-disable-next-line react-hooks/rules-of-hooks
    return useQuery([queryKey], () => this.$first(query), { staleTime: Infinity, onSuccess: onSuccess })
  }

  useAllCached(query?: Query<T>, addonQueryKey?: string | number, enabled?: boolean) {
    const queryKey: (Query<T> | string)[] = [this.key]
    if (query) queryKey.push(query)
    if (addonQueryKey) queryKey.push(addonQueryKey.toString())
    // eslint-disable-next-line react-hooks/rules-of-hooks
    return useQuery(queryKey, () => this.$all(query), { staleTime: Infinity, enabled })
  }

  useFindItem(id: number, options?: UseQueryOptions<T>) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    return useQuery<T>([`${this.key}`, { id }], () => this.$find(id), { ...options })
  }

  useFirstItem(options?: UseQueryOptions<T>) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    return useQuery<T>([`${this.key}`, 'first'], () => this.$first(), { ...options })
  }

  @action
  async $all(_query?: Query<T>): Promise<T[]> {
    try {
      const query = { ..._query, params: { size: 2000, page: 0 } }
      if (_query && _query.params) {
        Object.assign(query.params, _query.params)
      }
      this.items = null
      this.isSearching = true
      this.constructQueryFilterAndCustomFilterAccordingFilters(query)
      const data = await Http.$get<PaginationResult<T>>(this.getBaseUrl(query, this.searchTypeLight), this.getOptions(query, 'all'))
      const paginationResult = this.resolvePaginationResult(data, query)
      runInAction(() => (this.items = paginationResult.content))
      return this.items
    } catch (error) {
      this.handleErrors(error)
      throw error
    } finally {
      runInAction(() => (this.isSearching = false))
    }
  }

  @action
  async $save(item: T, query?: Query<T>, withMessageSave?: boolean, withEventStoreModified = true): Promise<T> {
    this.onBeforeSave && (await this.onBeforeSave(item))
    const { ui } = item
    if (ui) ui.isSaving = true
    withMessageSave = withMessageSave ?? this.withMessageSave
    if (!query) query = {}
    if (this.saveIsAsync) {
      query.subUrlPath = `${query.subUrlPath ?? ''}/async`
      query.noCreator = true
    }
    query.subUrlPath = `${item.id ? item.id.toString() : ''}${query.subUrlPath ?? ''}`

    return new Promise<T>(async (resolve, reject) => {
      try {
        let data: T
        if (item.id) {
          data = await this.$put(item, query)
        } else {
          data = await this.$post(item, query)
        }
        runInAction(() => {
          resolve(data)
          if (this.saveIsAsync) {
            const eventKey = data as unknown as string
            coreStore.eventStore.addActionInProgress(new ActionInProgress({ uuid: eventKey, label: this.itemName, date: moment() }))
            if (withMessageSave) Message.loading(`${Tools.upperFirstLetter(this.thisItemName)} est en cours`, eventKey)
          } else {
            if (withEventStoreModified) this.setEventStoreModified(item.id, item)
            if (withMessageSave) Message.success(`${Tools.upperFirstLetter(this.thisItemName)} a été enregistré${this.objectNameGenre === 'female' ? 'e' : ''}`, this.key)
          }
          Object.assign(item, data)
        })
        this.onAfterSave && (await this.onAfterSave(item, ui))
      } catch (e) {
        reject(e)
      }
    })
  }

  @action
  setEventStoreModified(id?: number, data?: T) {
    if (!Tools.isNullOrUndefined(id) && data) this.setQueryDataForId(id, data)
    this.invalidateQueries()
  }

  @action
  setQueryDataForId(id: number, data: T) {
    coreStore.queryClient.setQueryData([this.key, { id }], data)
    const queries = coreStore.queryClient.getQueriesData({ queryKey: [this.key] })
    queries.forEach(query => {
      coreStore.queryClient.setQueryData(query[0], updater => {
        const paginationResult = updater as PaginationResult<T>
        if (paginationResult?.content && paginationResult.numberOfElements) {
          const index = paginationResult.content.findIndex(x => x.id === id)
          if (index > -1) paginationResult.content[index] = data
          return { ...paginationResult }
        }
        return undefined
      })
    })
  }

  @action
  invalidateQueries() {
    coreStore.eventStore.storeModified[this.key] = new Date()
    coreStore.queryClient?.invalidateQueries([this.key]).then()
    this.onInvalidateQueries && this.onInvalidateQueries()
  }

  async $deleteById(id: number): Promise<any> {
    return await this.$delete({ subUrlPath: id.toString() }).then(() => this.setEventStoreModified())
  }

  @action
  async $delete(query?: Query<T>): Promise<any> {
    try {
      this.isDeleting = true
      this.constructQueryFilterAndCustomFilterAccordingFilters(query)
      return await Http.$delete<T>(this.getBaseUrl(query), this.getOptions(query))
    } catch (error) {
      this.handleErrors(error)
      throw error
    } finally {
      runInAction(() => (this.isDeleting = false))
    }
  }

  @action
  async $deleteConfirm(item: T) {
    await ModalService.showDeleteConfirm()
    try {
      runInAction(() => (item.ui.isDeleting = true))
      await this.$deleteById(item.id)
    } finally {
      runInAction(() => (item.ui.isDeleting = false))
    }
  }

  @action
  $cancelDeleted = async (item: T) => {
    item.isDeleted = false
    await this.$save(item)
  }

  $deleteConfirmAndGotoList = async (item: T) => {
    await this.$deleteConfirm(item)
    this.gotoList()
  }

  @action
  closeModalToEdit = () => {
    this.modalToEditIsOpen = false
  }

  @action
  openModalToEdit(item?: T) {
    this.item = new this.tCreator(item ? toJS(item) : this.initObjectWhenCreate)
    this.modalToEditIsOpen = true
  }

  @action
  async exportToCsv() {
    const query = cloneDeep(this.querySearch)
    if (this.isIndeterminate) {
      query.params.customFilter.ids = this.paginationResult.content.filter(x => x.ui.isChecked).map(x => x.id)
    } else if (this.paginationResult.totalElements > 2000) {
      message.error('Vous ne pouvez pas exporter plus de 2 000 résultats')
      return
    }
    await this.$csv(query)
  }

  setSort(sorterOrder: 'ascend' | 'descend', field: string) {
    if (!this.querySearch) this.querySearch = { params: {} }
    this.querySearch.params.sort = []
    const value = sorterOrder.replace('ascend', 'asc').replace('descend', 'desc')
    this.querySearch.params.sort.push(field + ',' + value)
  }

  gotoList() {
    coreStore.routerStore.push(this.route)
  }

  gotoEdit = (item: T, openByWindowOpen = false) => {
    if (openByWindowOpen) {
      window.open(`${this.route}/${item.id}`, '_ blank')
      return
    }
    coreStore.routerStore.push(`${this.route}/${item.id}`)
  }

  //#region private method
  constructQueryFilterAndCustomFilterAccordingFilters(query?: Query<T>) {
    if (!query || !query.params) return
    query.params.filter = cloneDeep({ ...query.params.filterInit, ...this.filterInit })
    query.params.customFilter = cloneDeep({ ...(query.params.customFilterInit as any), ...this.customFilterInit })
    if (this.filtersAndViews.items) {
      this.filtersAndViews.items
        .filter(x => !x.isCustom)
        .forEach(filter => {
          if (filter.isActive && filter.data !== undefined && filter.data !== null && filter.data !== '') {
            Recursive.setData(query.params.filter, filter.propertyToFilter, filter.data)
          }
        })

      this.filtersAndViews.items
        .filter(x => x.isCustom)
        .forEach(filter => {
          if (filter.isActive && filter.data !== undefined && filter.data !== null) {
            Recursive.setData(query.params.customFilter, filter.propertyToFilter, filter.data)
          }
        })
    }
    return query
  }

  getUrlForSearchLight(searchLight: SearchTypeLight) {
    switch (searchLight) {
      case 'light':
        return '/light'
      case 'superLight':
        return '/super-light'
      case 'ultraLight':
        return '/ultra-light'
    }
  }
  //#endregion

  protected getOptions(query: Query<any>, cancelGet: 'search' | 'all' | 'first' = null) {
    let cancelTokenSource = null
    if (!query) {
      return
    }
    if (cancelGet) {
      cancelTokenSource = this.cancelAxios(cancelGet)
    }
    const options = new RequestConfig()
    options.params = {}
    options.params = { ...query.params }
    delete options.params.filterInit
    delete options.params.customFilterInit
    if (options.params.filter === undefined) delete options.params.filter
    if (options.params.customFilter === undefined) delete options.params.customFilter
    if (options.params.page === undefined) delete options.params.page
    if (options.params.size === undefined) delete options.params.size
    if (options.params.sort === undefined) delete options.params.sort
    options.cancelTokenSource = query.cancelTokenSource ?? cancelTokenSource
    options.hideGlobalMessageError = query.hideGlobalMessageError
    return options
  }

  private getBaseUrl(query?: Query<T>, searchTypeLight?: SearchTypeLight): string {
    let urlConcat = `${this.baseUrl}`
    if (searchTypeLight) urlConcat = urlConcat.concat(this.getUrlForSearchLight(searchTypeLight))
    // subPath ?
    if (!!query && !!query.subUrlPath) {
      if (query.subUrlPath.charAt(0) !== '/') {
        query.subUrlPath = '/'.concat(query.subUrlPath)
      }
      urlConcat = urlConcat.concat(query.subUrlPath)
    }
    return urlConcat
  }

  private resolvePaginationResult(data: PaginationResult<T>, query?: Query<T>): PaginationResult<T> {
    if (!!query && query.noCreator === true) {
      return data
    }
    data.content.forEach((item, index, collection) => {
      collection[index] = new this.tCreator(item)
      if (collection[index].ui) collection[index].ui.index = index
    })
    return data
  }

  protected resolve(data: T, query?: Query<T>): AbstractItem | any {
    if (!!query && query.noCreator === true) {
      return data
    }
    return runInAction(() => {
      return new this.tCreator(data)
    })
  }

  private resolveArray(data: T[], query?: Query<T>): Array<T> {
    if (!!query && query.noCreator === true) {
      return data
    }
    const result: any[] = []
    data.forEach((item, index) => {
      const creator = new this.tCreator(item)
      creator.ui.index = index
      result.push(creator)
    })
    return result
  }

  private resolvePaginationResultForOne(data: PaginationResult<T>): T {
    return runInAction(() => {
      if (data.content && data.content.length) {
        return new this.tCreator(data.content[0])
      }
      return null
    })
  }

  protected handleErrors = (error: AxiosError) => {
    if (error?.response) {
      console.error('Api Store Error', error.message, error.response.status, error.response.data)
    } else if (error?.request) {
      console.error('Api Store Error', error.message, error.request)
    }
  }

  //#endregion
}

type CancelTokens = {
  search?: CancelTokenSource
  first?: CancelTokenSource
  all?: CancelTokenSource
}

export type SearchTypeLight = 'light' | 'superLight' | 'ultraLight'

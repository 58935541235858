import React, { useEffect } from 'react'
import { rootStore } from '@/stores/rootStore'
import { enableStaticRendering, observer } from 'mobx-react-lite'
import { useRouter } from 'next/router'
import { coreStore } from '@core/store/coreStore'
import { User } from '@common/models/user/user'
import { MeStore } from '@core/store/meStore'

import '../styles/variables.less'
import 'tailwindcss/tailwind.css'
import '../styles/index.scss'
import SkLayoutWrapper from '@/layouts/skLayoutWrapper'
import { setDefault } from '@common/global/default'
import { ConfigProvider } from 'antd'
import frFR from 'antd/lib/locale/fr_FR'
import { SkRouterWrapper } from '@/layouts/skRouterWrapper'
import NiceModal from '@ebay/nice-modal-react'
import Head from 'next/head'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ClickToComponent } from 'click-to-react-component-intellij'

enableStaticRendering(typeof window === 'undefined')

setDefault()

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false
      // structuralSharing: (oldDta, newData) => {
      //   return deepEqual(Tools.cleanData(oldDta), Tools.cleanData(newData))
      // }
    }
  }
})

function SkApp({ Component, pageProps }) {
  const router = useRouter()

  const setDocVariables = () => {
    document.documentElement.style.setProperty('--vh', `${window.innerHeight}px`)
  }

  const interceptError = () => {
    if (!coreStore.meStore.data) coreStore.routerStore.push('/registration/login')
  }

  const unregisterServiceWorker = () => {
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.getRegistrations().then(registrations => {
        for (const registration of registrations) {
          registration.unregister()
        }
      })
    }
  }

  useEffect(() => {
    coreStore.init('Gescom', new MeStore(User), router, true, interceptError, null, queryClient)
    rootStore.init()
    setDocVariables()
    window.addEventListener('resize', setDocVariables)
    window.addEventListener('orientationchange', setDocVariables)
    unregisterServiceWorker()
  }, [])

  if (!rootStore.isLoaded) {
    return <></>
  }

  coreStore.routerStore = router

  return (
    <>
      <Head>
        <title>Gescom</title>
        <meta name="viewport" content="minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no, user-scalable=no, viewport-fit=cover" />
      </Head>
      <ClickToComponent />
      <ConfigProvider locale={frFR}>
        <QueryClientProvider client={queryClient}>
          <NiceModal.Provider>
            <SkRouterWrapper>
              <SkLayoutWrapper>
                <Component {...pageProps} />
              </SkLayoutWrapper>
            </SkRouterWrapper>
          </NiceModal.Provider>
        </QueryClientProvider>
      </ConfigProvider>
    </>
  )
}

export default observer(SkApp)

import { DatePicker } from 'antd'
import * as React from 'react'
import { SizeType } from 'antd/es/config-provider/SizeContext'
import moment from 'moment'
import { useEffect, useState } from 'react'
import classNames from 'classnames'
import { useFormContext } from '@core/contexts/formContext'
import { IPropsInput, InitInput } from '@core/utils/initInput'
import { ListenInput } from '@core/utils/listenInput'
import { WithFormItem } from '@core/components/form'

type IProps<T, Y> = IPropsInput<T, Y> & {
  placeholder?: string
  size?: SizeType
  autoFocus?: boolean
  required?: boolean
  widthFull?: boolean
  clearAfterChange?: boolean
}

SkMonthPicker.defaultProps = {
  widthFull: true
}
export function SkMonthPicker<T>(props: IProps<T, Date>) {
  const { id } = InitInput(props, 'SkMonthPicker')

  return (
    <>
      <SkMonthPickerDesktop id={id} {...props} />
    </>
  )
}

const SkMonthPickerDesktop = (props: IProps<unknown, Date>) => {
  const formContext = useFormContext()
  const [allowClear, setAllowClear] = useState(true)

  const { onChangeInput, value, setFieldsValue } = ListenInput({ ...props, withInitModel: (model: any) => moment(model) }, props.id)

  useEffect(() => {
    if (props.rules || props.required) {
      setAllowClear(!(props.rules?.some((x: any) => x['required']) || props.required))
    }
  }, [props.rules])

  useEffect(() => {
    setFieldsValue(value)
    if (props.clearAfterChange) {
      setTimeout(() => {
        setFieldsValue(null)
      }, 300)
    }
  }, [value])

  return (
    <WithFormItem {...props} value={value ? moment(value) : undefined} id={props.id}>
      <DatePicker
        id={props.id}
        value={value ? moment(value) : undefined}
        allowClear={allowClear}
        onChange={v => onChangeInput(v ? moment(v).startOf('month') : null)}
        className={classNames({ 'w-full': props.widthFull }, { 'w-[200px]': !props.widthFull })}
        disabled={props.disabled || formContext?.props.disabled}
        format="MMMM YYYY"
        size={props.size}
        placeholder={props.placeholder}
        picker={'month'}
      />
    </WithFormItem>
  )
}

import React, { useEffect } from 'react'
import { Input } from 'antd'
import { SwitchSize } from 'antd/es/switch'
import { WithFormItem } from '@core/components/form'
import { useFormContext } from '@core/contexts/formContext'
import { IPropsInput, InitInput } from '@core/utils/initInput'
import { ListenInput } from '@core/utils/listenInput'

type IProps<T, Y> = IPropsInput<T, Y> & {
  size?: SwitchSize
  required?: boolean
}

export function SkTimePicker<T>(props: IProps<T, string>) {
  const formContext = useFormContext()
  const { id } = InitInput(props, 'SkTimePicker')
  const { onChangeInput, value, setFieldsValue } = ListenInput(props, id)

  useEffect(() => {
    setFieldsValue(value)
  }, [value])

  return (
    <WithFormItem {...props} id={id} value={value}>
      <Input type="time" onChange={onChangeInput} value={value} disabled={props.disabled || formContext.props.disabled} />
    </WithFormItem>
  )
}

import React from 'react'
import { useIsMobile } from '@core/utils/hooks/useIsMobile'
import classNames from 'classnames'

interface IProps {
  children: React.ReactNode
}

export const SkPageEditHeader = (props: IProps) => {
  const isMobile = useIsMobile()
  return <div className={classNames('container mx-auto bg-white', { 'px-8 pt-4': !isMobile }, { 'px-3 pt-6': isMobile })}>{props.children}</div>
}

import { makeObservable, observable } from 'mobx'
import { GeneralAccountingAccount } from '@common/models/accounting/generalAccountingAccount'
import { AbstractItem } from '@core/models/abstractItem'
import { ThirdPartyAccountingAccount } from '@common/models/accounting/thirdPartyAccountingAccount'

export class Accounting extends AbstractItem {
  @observable
  generalAccountingAccount: GeneralAccountingAccount
  @observable
  thirdPartyAccountingAccount: ThirdPartyAccountingAccount

  constructor(data?: Partial<Accounting>) {
    super(data)
    makeObservable(this)
    Object.assign(this, data)
  }
}

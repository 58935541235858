import React from 'react'
import { Tooltip } from 'antd'
import Link from 'next/link'
import { SkBtn } from '@core/components/btns'
import { SizeType } from 'antd/es/config-provider/SizeContext'
import classNames from 'classnames'

type Props = {
  className?: string
  children: React.ReactNode
  tooltip?: string
  loading?: boolean
  targetBlank?: boolean
  disabled?: boolean
  size?: SizeType
} & (
  | {
      id?: string
      href: string
      onClick?: (event: any) => void
    }
  | {
      id: string
      href?: never
      onClick?: (event: any) => void
    }
)
export const SkLink = (props: Props) => {
  const GetLink = () => (
    <>
      {!props.disabled ? (
        <>
          {props.href ? (
            <Link
              href={props.href}
              onClick={props.onClick}
              className={classNames('inline-flex items-center', props.className, { 'hover:underline': props.targetBlank })}
              id={props.id ?? props.href}
              target={props.targetBlank ? '_blank' : undefined}
            >
              {props.children}
            </Link>
          ) : (
            <SkBtn type="link" loading={props.loading} className={`inline-flex items-center p-0 ${props.className}`} onClick={props.onClick} id={props.id} size={props.size}>
              {props.children}
            </SkBtn>
          )}
        </>
      ) : (
        <span className={`inline-flex items-center ${props.className}`}>{props.children}</span>
      )}
    </>
  )

  return props.tooltip ? (
    <Tooltip title={props.tooltip}>
      <div className="inline">
        <GetLink />
      </div>
    </Tooltip>
  ) : (
    <GetLink />
  )
}

import { makeAutoObservable } from 'mobx'

export class ParamAppStore {
  applicationName = 'GesCom'
  codeApplication = 'gescom'
  apiUrl = '/api'
  mediaUrl = '/media'

  constructor() {
    makeAutoObservable(this)
  }
}

import { DiscountCodeEnum } from '@common/global/enums'
import { Price } from '@common/models/price/price'

export class EstimateTools {
  static calculatePrice = (
    quantity: number,
    unitPriceExcludingTaxes: Price,
    taxRates: number[],
    globalDiscountPercentage = 0,
    discountCodeEnum: DiscountCodeEnum = null,
    discountPercentage = 0,
    discountAmount = 0
  ) => {
    let atEveryQuantity
    if (discountCodeEnum === DiscountCodeEnum.firstArticleDiscount) {
      atEveryQuantity = 1
    } else if (discountCodeEnum === DiscountCodeEnum.secondArticleDiscount) {
      atEveryQuantity = 2
    } else {
      atEveryQuantity = 0
    }

    if (discountPercentage && discountAmount) {
      throw 'Vous ne pouvez pas mettre un discountPercentage et un discountAmount'
    }

    const discount = EstimateTools.getDiscount(discountPercentage, unitPriceExcludingTaxes, quantity, atEveryQuantity, discountAmount)
    const priceWithDiscount = EstimateTools.applyDiscount(quantity, unitPriceExcludingTaxes, discount)
    return Math.round(priceWithDiscount * (1 + taxRates.reduce((total, rate) => total + rate, 0) / 100))
  }

  private static getDiscount(discountPercentage: number, unitPriceExcludingTaxes: Price, quantity: number, atEveryQuantity: any, discountAmount: number) {
    const numberOfDiscount = EstimateTools.getNumberOfDiscount(quantity, atEveryQuantity)
    if (discountPercentage !== 0) {
      return EstimateTools.getTotalDiscountFromPercentage(unitPriceExcludingTaxes.amount, numberOfDiscount, discountPercentage)
    } else {
      return EstimateTools.getTotalDiscountFromAmount(numberOfDiscount, discountAmount)
    }
  }

  private static applyDiscount = (quantity: number, unitPrice: Price, totalDiscount: number): number => {
    return unitPrice.amount * quantity - totalDiscount
  }

  private static getNumberOfDiscount = (quantity: number, atEveryQuantity: number): number => {
    return atEveryQuantity === 0 ? 0 : Math.floor(quantity / atEveryQuantity)
  }

  private static getTotalDiscountFromPercentage = (unitPriceAmount: number, numberOfDiscount: number, discountPercentage: number): number => {
    return numberOfDiscount * EstimateTools.getDiscountFromPercentage(unitPriceAmount, discountPercentage)
  }

  private static getDiscountFromPercentage = (unitPriceAmount: number, discountPercentage: number): number => {
    return unitPriceAmount * (discountPercentage / 100)
  }

  private static getTotalDiscountFromAmount = (numberOfDiscount: number, discountAmount: number): number => {
    return numberOfDiscount * discountAmount
  }
}

import { coreStore } from '@core/store/coreStore'
import { Discount } from '@common/models/discount/discount'
import { SkIconUilGift } from '@core/global/icons'
import { ApiStore } from '@core/api/apiStore'

export class DiscountStore extends ApiStore<Discount> {
  constructor() {
    super(coreStore.paramAppStore.apiUrl, '/discount', Discount, 'female', 'Réductions', 'une réduction', 'cette réduction', SkIconUilGift)
  }

  $getForOnlyVariant(variantId: number) {
    return this.$first({
      params: { filterInit: { variant: { id: variantId } }, customFilterInit: { withVariant: true, withCustomer: false, withPackage: false, withCode: false, isActive: true } }
    })
  }
}

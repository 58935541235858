import * as React from 'react'
import { ApiStore } from '@core/api/apiStore'
import { SkIconUilEditAlt, SkIconUilMultiply } from '@core/global/icons'
import { AbstractItem } from '@core/models/abstractItem'

interface IProps {
  title?: React.ReactNode
  isEditing?: boolean
  titleForCreateOrEdit?: string
  onCancel: () => void
  icon?: (props: any) => JSX.Element
}

export function SkModalHeader<Y extends AbstractItem, T extends ApiStore<Y>>(props: IProps) {
  const Icon = props.icon ?? SkIconUilEditAlt
  return (
    <div className="bg-dark text-white">
      <div className="pr-3">
        <div className="flex items-center justify-between p-3">
          <div className="flex items-center">
            <div className="flex justify-center rounded-full bg-white p-2">
              <Icon size="20" className="text-blue2-800" />
            </div>
            {props.title ? (
              <h2 className="pl-3 text-xl text-white">{props.title}</h2>
            ) : (
              <h2 className="pl-3 text-xl text-white">
                {props.isEditing ? 'Modifier' : 'Créer'} {props.titleForCreateOrEdit}
              </h2>
            )}
          </div>
          <a className="justify-end text-white" onClick={props.onCancel} title="fermer">
            <SkIconUilMultiply size="20" />
          </a>
        </div>
      </div>
    </div>
  )
}

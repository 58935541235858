import { Variant } from '@common/models/variant/variant'
import { SkIconUilTagAlt } from '@core/global/icons'
import { coreStore } from '@core/store/coreStore'
import { ApiStore } from '@core/api/apiStore'
import { PaginationResult, Query } from '@core/global/interfaces'
import { action, observable, runInAction } from 'mobx'
import { Http } from '@core/api/http'
import { Message } from '@core/service/message'
import { DiscountStore } from '@common/models/discount/discountStore'
import { referentialStore } from '@common/models/referential/referentialStore'

export class VariantStore extends ApiStore<Variant> {
  @observable
  $updateVariantsFullLabelIsLoading: boolean

  constructor() {
    super(coreStore.paramAppStore.apiUrl, '/variant', Variant, 'male', 'Variants', 'un variant', 'ce variant', SkIconUilTagAlt, '/variants')
    this.canBePathPublicIfNotMe = true
    this.searchTypeLight = 'light'
  }

  @action
  $getLightWithPlanning = async (query?: Query<Variant>) => {
    try {
      this.isSearching = true
      this.constructQueryFilterAndCustomFilterAccordingFilters(query)
      return await Http.$get<PaginationResult<Variant>>(`${this.baseUrl}/light-with-planning`, this.getOptions(query, 'search'))
    } catch (error) {
      this.handleErrors(error)
      throw error
    } finally {
      runInAction(() => (this.isSearching = false))
    }
  }

  @action
  $updateVariantsFullLabel = async () => {
    try {
      this.$updateVariantsFullLabelIsLoading = true
      return await Http.$get<PaginationResult<Variant>>(`${this.baseUrl}/update-full-label`)
    } catch (error) {
      this.handleErrors(error)
      throw error
    } finally {
      runInAction(() => (this.$updateVariantsFullLabelIsLoading = false))
    }
  }

  onBeforeSave(item: Variant) {
    if (!item.transactionPrices?.length && referentialStore.data.tenantProperties.articleProperties.withPrice) {
      Message.error('Veuillez ajouter un prix')
      return Promise.reject()
    }
    return Promise.resolve()
  }

  @action
  async onAfterSave(variant: Variant, ui: (typeof variant)['ui']): Promise<void> {
    const { discount } = ui
    if (!discount) return Promise.resolve()
    if (discount) {
      discount.variant = variant
      this.itemIsLoading = true
      try {
        await new DiscountStore().$save(discount, null, false)
      } finally {
        runInAction(() => (this.itemIsLoading = false))
      }
    }
  }

  async $allForArticle(articleId: number) {
    return this.$all({ params: { filterInit: { article: { id: articleId } }, sort: ['id,asc'] } })
  }
}

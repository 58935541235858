import { AbstractUser } from '@core/models/abstractUser'
import { action, makeObservable, observable } from 'mobx'
import { UserCompany } from '@common/models/user/userCompany'
import { Company } from '@core/models/company'

export class User extends AbstractUser {
  @observable
  userCompanies: UserCompany[]

  constructor(data?: Partial<User>) {
    super(data)
    makeObservable(this)
    if (data?.userCompanies) {
      this.userCompanies = []
      data.userCompanies.forEach(item => {
        this.userCompanies.push(new UserCompany(item))
      })
    }
  }

  @action
  setCompanyDefault = (company: Company) => {
    this.userCompanies.forEach(value => (value.isDefault = false))
    const index = this.userCompanies.findIndex(x => x.company.id === company.id)
    this.userCompanies[index].isDefault = true
  }

  @action
  addCompany = (company: Company) => {
    if (!this.userCompanies) this.userCompanies = []
    this.userCompanies.push(new UserCompany({ company }))
  }

  @action
  removeCompany = (company: Company) => {
    this.userCompanies = this.userCompanies.filter(x => x.company.id !== company.id)
    if (this.userCompanies.length === 1) this.userCompanies[0].isDefault = true
  }
}

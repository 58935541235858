import { BankAccount } from '@core/models/bankAccount'
import { makeObservable, observable } from 'mobx'
import { AbstractLabel } from '@core/models/abstractLabel'
import { TaxTag } from '@common/models/tax/taxTag'
import { Accounting } from '@common/models/accounting/accounting'

export class Tax extends AbstractLabel {
  @observable
  rate: number
  @observable
  account: BankAccount
  @observable
  taxTags: TaxTag[]
  @observable
  accounting: Accounting

  constructor(data?: Partial<Tax>) {
    super(data)
    makeObservable(this)
    Object.assign(this, data)
    if (this.account) {
      this.account = new BankAccount(this.account)
    }
    if (this.accounting) {
      this.accounting = new Accounting(this.accounting)
    }
    if (this.taxTags) {
      this.taxTags.forEach((item, index) => {
        this.taxTags[index] = new TaxTag(item)
      })
    }
  }
}

import { AbstractLabel } from '@core/models/abstractLabel'
import { makeObservable } from 'mobx'

export class ArticleUnitGroup extends AbstractLabel {
  constructor(data?: Partial<ArticleUnitGroup>) {
    super(data)
    makeObservable(this)
    Object.assign(this, data)
  }
}

import * as React from 'react'
import { ApiStore } from '@core/api/apiStore'
import { SkTable } from '@core/components'
import { AbstractItem } from '@core/models/abstractItem'
import { useIsMobile } from '@core/utils/hooks/useIsMobile'
import { SkColumnType } from '@core/global/interfaces'
import dynamic from 'next/dynamic'
import { IReactionDisposer, makeAutoObservable } from 'mobx'
import { sorter } from '@core/utils/nameOf'
import { SkCardsProps } from '@core/components/cards/skCards'
import { SkTableProps } from '@core/components/table/skTable'
import { SkLoader } from '@core/components/loader/skLoader'

const SkCards = dynamic(() => import('@core/components/cards/skCards'), { ssr: false })

export type SkTableOrCardProps<Y extends ApiStore<Y['item']>> = SkTableProps<Y> | SkCardsProps<Y>

export class SkTableOrCardsContext<Y extends ApiStore<Y['item']>> {
  filter: any
  customFilter: any
  rowClassName: string
  disposer: IReactionDisposer
  columns: SkColumnType<Y['item']>[]
  isInited = false

  constructor(public props: SkTableOrCardProps<Y>) {
    makeAutoObservable(this)
  }

  setSorters = (columns: SkColumnType<Y['item']>[]) => {
    columns?.forEach((col, index) => {
      if (col.sorterName) {
        columns[index].dataIndex = sorter<Y>(col.sorterName as any)
        columns[index].sorter = true
      }
    })
    this.columns = columns
  }

  initSearch = () => {
    const { store } = this.props
    store.querySearch.params.page = 0
    if (this.props.size) store.querySearch.params.size = this.props.size
    if (!store.querySearch.params.filterInit) store.querySearch.params.filterInit = this.props.filterInit
    if (!store.querySearch.params.customFilterInit) store.querySearch.params.customFilterInit = this.props.customFilterInit
    store.querySearch.subUrlPath = this.props.subUrlPath
    const col = this.columns?.find(x => x.sortOrder || x.defaultSortOrder) as any

    if (col) {
      store.setSort(col.sortOrder ?? col.defaultSortOrder, col.dataIndex)
    }
    if (this.props.onRow) {
      this.rowClassName = 'cursor-pointer'
    }
    store.loadFiltersAndViews()
    this.isInited = true
  }

  handleTableChange = (pagination: { current: number }, filters, sorter: { order: 'ascend' | 'descend'; field: string }) => {
    const props = this.props as SkTableProps<Y>
    props.store.querySearch.params.page = pagination.current - 1
    if (!props.isSortInFront) {
      props.store.querySearch.params.sort = []
      if (sorter?.order) {
        const value = sorter.order.replace('ascend', 'asc').replace('descend', 'desc')
        props.store.querySearch.params.sort.push(sorter.field + ',' + value)
        props.store.setSort(sorter.order, sorter.field)
      }
    }
  }

  initDefaultSort = () => {
    if (!this.columns?.some(x => x.defaultSortOrder)) {
      const col2 = this.columns?.find(x => !!x.sorterName)
      if (col2) col2.defaultSortOrder = 'ascend'
    }
  }
}

function SkTableOrCards<Y extends ApiStore<Z>, Z extends AbstractItem>(props: SkTableOrCardProps<Y>) {
  const isMobile = useIsMobile()
  if ((!isMobile && !props.columns) || (isMobile && !props.columns && !(props as SkCardsProps<Y>).renderCard)) return <SkLoader isLoading />
  return <>{!isMobile ? <SkTable {...(props as SkTableProps<Y>)} /> : <SkCards {...props} />}</>
}

export default SkTableOrCards

import React from 'react'
import { useIsMobile } from '@core/utils/hooks/useIsMobile'
import classNames from 'classnames'

interface IProps {
  children: React.ReactNode
}

export const SkPageEditBody = (props: IProps) => {
  const isMobile = useIsMobile()

  return (
    <div className="bg-gray-100" style={{ minHeight: 'calc(100vh - 154px)' }}>
      <div className={classNames('container mx-auto pb-24', { 'px-8': !isMobile }, { 'px-3': isMobile })}>{props.children}</div>
    </div>
  )
}

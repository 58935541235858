import React from 'react'
import { referentialStore } from '@common/models/referential/referentialStore'
import logo from '@/public/images/brand/logo-G.svg'
import Image from 'next/image'
import { CodeBundleEnum } from '@common/global/enums'
import classNames from 'classnames'

export const SkLogoAndBrand = ({ color = 'text-white' }: { color?: 'text-white' | 'text-text' }) => {
  return (
    <div className="flex-center">
      <div className="flex items-center justify-start">
        <div className="ml-4 mr-1">
          <Image src={logo} alt={'Gestock'} width={30} height={35} />
        </div>
        <span className={classNames('text-xl font-bold', color)}>
          {!referentialStore.data?.tenantProperties || referentialStore.data?.tenantProperties.hasBundle(CodeBundleEnum.invoice) ? 'gescom' : 'gestock'}
        </span>
      </div>
    </div>
  )
}

import React from 'react'
import {
  UilAdjustCircle,
  UilAnalytics,
  UilAngleDown,
  UilAngleLeft,
  UilAngleRightB,
  UilAngleUp,
  UilArchive,
  UilArrowDown,
  UilArrowFromRight,
  UilArrowGrowth,
  UilArrowLeft,
  UilArrowRandom,
  UilArrowRight,
  UilArrowToRight,
  UilArrowsVAlt,
  UilAt,
  UilAtom,
  UilBan,
  UilBars,
  UilBoltAlt,
  UilBookAlt,
  UilBookOpen,
  UilBox,
  UilCalendarAlt,
  UilCameraSlash,
  UilCar,
  UilCarSideview,
  UilChannel,
  UilChartPie,
  UilChatBubbleUser,
  UilCheck,
  UilClipboardAlt,
  UilClockEight,
  UilCommentAdd,
  UilCommentDots,
  UilCopyAlt,
  UilCreditCard,
  UilDirections,
  UilDollarAlt,
  UilEditAlt,
  UilEllipsisH,
  UilEllipsisV,
  UilEnvelopeCheck,
  UilEnvelopeSend,
  UilEstate,
  UilExchange,
  UilExclamationCircle,
  UilExport,
  UilExternalLinkAlt,
  UilFileCheck,
  UilFileDownload,
  UilFileDownloadAlt,
  UilFileLandscapeAlt,
  UilFilePlusAlt,
  UilFocusAdd,
  UilGift,
  UilHistory,
  UilImagePlus,
  UilInfoCircle,
  UilInvoice,
  UilLaptopCloud,
  UilLeftArrowFromLeft,
  UilListUl,
  UilLocationPinAlt,
  UilMobileAndroid,
  UilMoneyInsert,
  UilMoneyWithdrawal,
  UilMultiply,
  UilParcel,
  UilPen,
  UilPercentage,
  UilPlus,
  UilPlusSquare,
  UilPrint,
  UilProcess,
  UilQrcodeScan,
  UilQuestionCircle,
  UilRedo,
  UilRefresh,
  UilRestaurant,
  UilSave,
  UilSearch,
  UilSetting,
  UilShieldPlus,
  UilShop,
  UilShoppingBag,
  UilShoppingBasket,
  UilShoppingCart,
  UilShoppingCartAlt,
  UilSignout,
  UilSlidersV,
  UilSlidersVAlt,
  UilStoreAlt,
  UilSuitcase,
  UilTagAlt,
  UilTimes,
  UilTimesCircle,
  UilTimesSquare,
  UilTransaction,
  UilTrashAlt,
  UilTruck,
  UilTruckLoading,
  UilUser,
  UilUserPlus,
  UilUserSquare
} from '@iconscout/react-unicons'
import classNames from 'classnames'

export const SkIconUilArrowFromRight = (props: any) => <UilArrowFromRight {...props} />
export const SkIconUilQuestionCircle = (props: any) => <UilQuestionCircle {...props} />
export const SkIconUilArrowRandom = (props: any) => <UilArrowRandom {...props} />
export const SkIconUilChannel = (props: any) => <UilChannel {...props} />
export const SkIconUilFileDownloadAlt = (props: any) => <UilFileDownloadAlt {...props} />
export const SkIconUilLaptopCloud = (props: any) => <UilLaptopCloud {...props} />
export const SkIconUilClockEight = (props: any) => <UilClockEight {...props} />
export const SkIconUilPlusSquare = (props: any) => <UilPlusSquare {...props} />
export const SkIconUilRedo = (props: any) => <UilRedo {...props} />
export const SkIconUilTrashAlt = (props: any) => <UilTrashAlt {...props} />
export const SkIconUilEnvelopeSend = (props: any) => <UilEnvelopeSend {...props} />
export const SkIconUilEnvelopeCheck = (props: any) => <UilEnvelopeCheck {...props} />
export const SkIconUilAt = (props: any) => <UilAt {...props} />
export const SkIconUilMobileAndroid = (props: any) => <UilMobileAndroid {...props} />
export const SkIconUilSave = (props: any) => <UilSave {...props} />
export const SkIconUilTruckLoading = (props: any) => <UilTruckLoading {...props} />
export const SkIconUilPlus = (props: any) => <UilPlus {...props} />
export const SkIconUilChartPie = (props: any) => <UilChartPie {...props} />
export const SkIconUilShop = (props: any) => <UilShop {...props} />
export const SkIconUilCheck = (props: any) => <UilCheck {...props} />
export const SkIconUilProcess = (props: any) => <UilProcess {...props} />
export const SkIconUilCopyAlt = (props: any) => <UilCopyAlt {...props} />
export const SkIconUilListUl = (props: any) => <UilListUl {...props} />
export const SkIconUilTimes = (props: any) => <UilTimes {...props} />
export const SkIconUilTimesCircle = (props: any) => <UilTimesCircle {...props} />
export const SkIconUilEstate = (props: any) => <UilEstate {...props} />
export const SkIconUilBookAlt = (props: any) => <UilBookAlt {...props} />
export const SkIconUilBookOpen = (props: any) => <UilBookOpen {...props} />
export const SkIconUilBox = (props: any) => <UilBox {...props} />
export const SkIconUilRestaurant = (props: any) => <UilRestaurant {...props} />
export const SkIconUilArchive = (props: any) => <UilArchive {...props} />
export const SkIconUilShoppingCartAlt = (props: any) => <UilShoppingCartAlt {...props} />
export const SkIconUilExchange = (props: any) => <UilExchange {...props} />
export const SkIconUilSlidersV = (props: any) => <UilSlidersV {...props} />
export const SkIconUilSlidersVAlt = (props: any) => <UilSlidersVAlt {...props} />
export const SkIconUilTagAlt = (props: any) => <UilTagAlt {...props} />
export const SkIconUilPen = (props: any) => <UilPen {...props} />
export const SkIconPercentage = (props: any) => <UilPercentage {...props} />
export const SkIconUilArrowsVAlt = (props: any) => <UilArrowsVAlt {...props} />
export const SkIconUilArrowToRight = (props: any) => <UilArrowToRight {...props} />
export const SkIconUilUserPlus = (props: any) => <UilUserPlus {...props} />
export const SkIconUilTimesSquare = (props: any) => <UilTimesSquare {...props} />
export const SkIconUilCommentDots = (props: any) => <UilCommentDots {...props} />
export const SkIconUilCommentAdd = (props: any) => <UilCommentAdd {...props} />
export const SkIconUilAdjustCircle = (props: any) => <UilAdjustCircle {...props} />
export const SkIconUilAtom = (props: any) => <UilAtom {...props} />
export const SkIconUilSetting = (props: any) => <UilSetting {...props} />
export const SkIconUilEllipsisV = (props: any) => <UilEllipsisV {...props} />
export const SkIconUilCameraSlash = (props: any) => <UilCameraSlash {...props} />
export const SkIconUilBan = (props: any) => <UilBan {...props} />
export const SkIconUilGift = (props: any) => <UilGift {...props} />
export const SkIconUilFocusAdd = (props: any) => <UilFocusAdd {...props} />
export const SkIconUilShoppingBag = (props: any) => <UilShoppingBag {...props} />
export const SkIconUilTransaction = (props: any) => <UilTransaction {...props} />
export const SkIconUilMoneyWithdrawal = (props: any) => <UilMoneyWithdrawal {...props} />
export const SkIconUilMoneyInsert = (props: any) => <UilMoneyInsert {...props} />
export const SkIconUilExclamationCircle = (props: any) => <UilExclamationCircle {...props} />
export const SkIconUilFileDownload = (props: any) => <UilFileDownload {...props} />
export const SkIconUilPrint = (props: any) => <UilPrint {...props} />
export const SkIconUilFileCheck = (props: any) => <UilFileCheck {...props} />
export const SkIconUilEllipsisH = (props: any) => <UilEllipsisH {...props} />
export const SkIconUilHistory = (props: any) => <UilHistory {...props} />
export const SkIconUilSignout = (props: any) => <UilSignout {...props} />
export const SkIconUilSearch = (props: any) => <UilSearch {...props} />
export const SkIconUilMultiply = (props: any) => <UilMultiply {...props} />
export const SkIconUilArrowRight = (props: any) => <UilArrowRight {...props} />
export const SkIconUilArrowDown = (props: any) => <UilArrowDown {...props} />
export const SkIconUilArrowGrowth = (props: any) => <UilArrowGrowth {...props} />
export const SkIconUilArrowLeft = (props: any) => <UilArrowLeft {...props} />
export const SkIconUilAngleDown = (props: any) => <UilAngleDown {...props} />
export const SkIconUilAngleUp = (props: any) => <UilAngleUp {...props} />
export const SkIconUilAngleLeft = (props: any) => <UilAngleLeft {...props} />
export const SkIconUilAngleRightB = (props: any) => <UilAngleRightB {...props} />
export const SkIconUilEditAlt = (props: any) => <UilEditAlt {...props} />
export const SkIconUilLeftArrowFromLeft = (props: any) => <UilLeftArrowFromLeft {...props} />
export const SkIconUilBoltAlt = (props: any) => <UilBoltAlt {...props} />
export const SkIconUilShoppingCart = (props: any) => <UilShoppingCart {...props} />
export const SkIconUilCreditCard = (props: any) => <UilCreditCard {...props} />
export const SkIconUilTruck = (props: any) => <UilTruck {...props} />
export const SkIconUilShoppingBasket = (props: any) => <UilShoppingBasket {...props} />
export const SkIconUilParcel = (props: any) => <UilParcel {...props} />
export const SkIconUilShieldPlus = (props: any) => <UilShieldPlus {...props} />
export const SkIconUilCar = (props: any) => <UilCar {...props} />
export const SkIconUilCarSideView = (props: any) => <UilCarSideview {...props} />
export const SkIconUilClipboardAlt = (props: any) => <UilClipboardAlt {...props} />
export const SkIconUilQrcodeScan = (props: any) => <UilQrcodeScan {...props} />
export const SkIconUilChatBubbleUser = (props: any) => <UilChatBubbleUser {...props} />
export const SkIconUilImagePlus = (props: any) => <UilImagePlus {...props} />
export const SkIconUilStoreAlt = (props: any) => <UilStoreAlt {...props} />
export const SkIconUilUser = (props: any) => <UilUser {...props} />
export const SkIconUilExternalLinkAlt = (props: any) => <UilExternalLinkAlt {...props} />
export const SkIconUilUserSquare = (props: any) => <UilUserSquare {...props} />
export const SkIconUilCalendarAlt = (props: any) => <UilCalendarAlt {...props} />
export const SkIconUilBars = (props: any) => <UilBars {...props} />
export const SkIconUilExport = (props: any) => <UilExport {...props} />
export const SkIconUilLocationPinAlt = (props: any) => <UilLocationPinAlt {...props} />
export const SkIconUilDirections = (props: any) => <UilDirections {...props} />
export const SkIconUilRefresh = (props: any) => <UilRefresh {...props} />
export const SkIconUilInvoice = (props: any) => <UilInvoice {...props} />
export const SkIconUilAnalytics = (props: any) => <UilAnalytics {...props} />
export const SkIconUilInfoCircle = (props: any) => <UilInfoCircle {...props} />
export const SkIconUilFilePlusAlt = (props: any) => <UilFilePlusAlt {...props} />
export const SkIconUilSuitcase = (props: any) => <UilSuitcase {...props} />
export const SkIconUilLandscapeAlt = (props: any) => <UilFileLandscapeAlt {...props} />
export const SkIconUilDollarAlt = (props: any) => <UilDollarAlt {...props} />

export const SkImageFake = (props: { size: 'sm' | 'md' | 'lg' | 'xl' }) => (
  <SkIconUilCameraSlash
    className={classNames(
      'text-muted',
      { 'h-4 w-4': props.size === 'sm' },
      { 'h-6 w-6': props.size === 'md' },
      { 'h-16 w-16': props.size === 'lg' },
      { 'h-24 w-24': props.size === 'xl' }
    )}
  />
)

import React from 'react'
import { SkLink } from '@core/components'
import { SkIconUilPlus } from '@core/global/icons'

interface IProps {
  onClick: () => void
  className?: string
}

export const SkLinkAdd = (props: IProps) => {
  return (
    <SkLink onClick={props.onClick} className={props.className} id="add">
      <SkIconUilPlus className="pr-1" />
      Ajouter
    </SkLink>
  )
}

import { WarehouseVariant } from '@common/models/warehouseVariant/warehouseVariant'
import { makeObservable, observable } from 'mobx'
import { User } from '@common/models/user/user'
import { AbstractAddress } from '@core/models/abstractAddress'
import { AbstractLabel } from '@core/models/abstractLabel'

export class Warehouse extends AbstractLabel {
  @observable
  type: AbstractLabel
  @observable
  address: AbstractAddress
  @observable
  warehouseArticles: WarehouseVariant[]
  @observable
  users: User[]
  @observable
  isMailEnabled: boolean
  @observable
  mailRecipients: string

  constructor(data?: Partial<Warehouse>) {
    super(data)
    makeObservable(this)
    Object.assign(this, data)
    this.address = new AbstractAddress(this.address)
    if (this.type) this.type = new AbstractLabel(this.type)
    if (this.warehouseArticles) {
      this.warehouseArticles.forEach((item, index) => {
        this.warehouseArticles[index] = new WarehouseVariant(item)
      })
    }
    if (this.users) {
      this.users.forEach((item, index) => {
        this.users[index] = new User(item)
      })
    }
  }
}

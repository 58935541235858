import { AbstractPerson } from '@core/models/abstractPerson'
import { AbstractPersonContacts } from '@core/models/abstractPersonContacts'

export class AbstractContact extends AbstractPerson {
  customer: AbstractPersonContacts

  constructor(data?: Partial<AbstractContact>) {
    super(data)
  }
}

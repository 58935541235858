import { computed, makeObservable, observable } from 'mobx'
import { AbstractItem } from '@core/models/abstractItem'

export class AttributeValue extends AbstractItem {
  attribute: { id: number }
  @observable
  label: { fr: string }
  idx: number

  constructor(data?: Partial<AttributeValue>) {
    super(data)
    Object.assign(this, data)
    makeObservable(this)
  }

  @computed
  get labelT() {
    if (this.label?.fr) return this.label.fr
    return ''
  }
}

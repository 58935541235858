import React from 'react'
import { Tooltip } from 'antd'
import { SkIconUilInfoCircle } from '@core/global/icons'

export function SkTooltip(props: { label: React.ReactNode; withBorderInfo?: boolean; children: React.ReactNode; withIconInfo?: boolean }) {
  return (
    <Tooltip title={props.label}>
      {props.withBorderInfo ? (
        <span className="border-b border-dashed border-pink-400">{props.children}</span>
      ) : (
        <span className="flex items-center gap-1">
          {props.children}
          {props.withIconInfo && (
            <span className="text-primary">
              <SkIconUilInfoCircle width="20" />
            </span>
          )}
        </span>
      )}
    </Tooltip>
  )
}

import React, { CSSProperties, ReactNode } from 'react'
import { Drawer } from 'antd'
import { SkIconUilMultiply } from '@core/global/icons'

declare const PlacementTypes: ['top', 'right', 'bottom', 'left']
declare type placementType = typeof PlacementTypes[number]

interface Props {
  title: ReactNode
  onClose: () => void
  placement?: placementType
  children?: any
  footer?: ReactNode
  height?: number | string
  width?: number | string
  style?: CSSProperties
}

SkDrawer.defaultProps = {
  width: 600,
  placement: 'right'
}

export function SkDrawer(props: Props) {
  return (
    <>
      <Drawer
        title={props.title}
        footer={props.footer}
        open={true}
        placement={props.placement}
        width={props.width}
        onClose={props.onClose}
        height={props.height}
        closeIcon={<SkIconUilMultiply />}
        destroyOnClose={true}
        style={props.style}
      >
        {props.children}
      </Drawer>
    </>
  )
}

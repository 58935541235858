import { makeObservable } from 'mobx'
import { AbstractItem } from '@core/models/abstractItem'
import { Tag } from '@common/models/tag/tag'

export class ArticleTag extends AbstractItem {
  idx: number
  tag: Tag

  constructor(data?: Partial<ArticleTag>) {
    super(data)
    makeObservable(this)
    Object.assign(this, data)
    if (this.tag) {
      this.tag = new Tag(this.tag)
    }
  }
}

import { useFormContext } from '@core/contexts/formContext'
import { useEffect, useState } from 'react'
import { propertyName } from '@core/utils/nameOf'
import { action, reaction } from 'mobx'
import { Recursive } from '@core/utils/recursive'
import { useEditContext } from '@core/components'
import { useStoreContext } from '@core/contexts/storeContext'
import { useDebouncedCallback } from '@core/utils/hooks/useDebouncedCallback'
import { Tools } from '@core/utils/tools'

export type IPropsInput<T, Y> = {
  withInitModel?: (item: any) => any
  valueForClear?: unknown
} & (
  | {
      id?: string
      value?: never
      model: T
      property: (x: T) => Y
      onChange?: (value: Y) => void
    }
  | {
      id: string
      value: Y
      model?: never
      property?: never
      onChange: (value: Y) => void
    }
)

export function ListenInput<T, Y>(props: IPropsInput<T, Y>, id: string) {
  const editContext = useEditContext()
  const formContext = useFormContext()
  const storeContext = useStoreContext()

  const [value, setValue] = useState(() => {
    if (!props.property) return props.value
    return Recursive.getData(props.model, propertyName(props.property))
  })

  const setFieldsValue = (data: any) => {
    if (formContext.fields.instance && formContext.fields.instance.getFieldValue(id) !== data) {
      formContext.fields.instance.setFieldsValue({ [id]: !Tools.isNullOrUndefined(data) ? (props.withInitModel ? props.withInitModel(data) : data) : props.valueForClear ?? null })
      formContext.fields.instance
        .validateFields([id])
        .then()
        .catch(() => {
          // silent
        })
    }
  }

  const onDebounceChange = useDebouncedCallback(() => editContext?.checkIfItemIsItemChanged(), [], 100)

  const onChangeInput = (eventOrValue: any) => {
    const newValue = eventOrValue && typeof eventOrValue === 'object' && eventOrValue.target ? eventOrValue.target.value : eventOrValue
    setValueAndOnChange(newValue)
  }

  const setValueAndOnChange = action((value: Y) => {
    setValue(value)
    if (props.property) Recursive.setData(props.model, propertyName(props.property), value ?? null)
    props.onChange?.(value)
    onDebounceChange()
  })

  if (!props.property) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
      if (props.value !== value) setValue(props.value)
    }, [props.value])
  } else {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
      let disposerValueModified: any = null
      if (storeContext?.item) {
        disposerValueModified = reaction(
          () => Recursive.getData(props.model, propertyName(props.property)),
          data => {
            // reaction is faster than setValue, so we wrapped in setTimeout
            setTimeout(() => {
              if (value !== data && disposerValueModified) {
                setValue(data)
                onDebounceChange()
              }
            })
          }
        )
      }
      return () => {
        if (disposerValueModified) {
          disposerValueModified()
          disposerValueModified = null
        }
      }
    }, [value, id])
  }

  return { onChangeInput, setValue, value, setFieldsValue, setValueAndOnChange }
}

import * as React from 'react'
import { Button } from 'antd'
import { SkIconUilCheck, SkIconUilTimes } from '@core/global/icons'

export function SkDrawerFooter(props: { onCancel: () => void; onSubmit: () => void }) {
  return (
    <div className="flex justify-center bg-gray-100 p-3">
      <Button className="min-w-100 mx-3" type={'primary'} onClick={props.onSubmit}>
        <SkIconUilCheck className="mr-2" />
        Créer
      </Button>
      <Button className="min-w-100 mx-3 border-none" onClick={props.onCancel}>
        <SkIconUilTimes className="mr-2 text-red-600" />
        Annuler
      </Button>
    </div>
  )
}

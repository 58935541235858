import { referentialStore } from '@common/models/referential/referentialStore'
import { EstimateFieldNamingEnum } from '@common/global/enums'

export const FieldsNaming = {
  get estimate(): FieldsNamingNames {
    if (referentialStore.data.tenantProperties.fieldNamingProperties?.estimateFieldNaming?.code === EstimateFieldNamingEnum.estimate) {
      return {
        genre: 'female',
        objectName: t('Commande', 'Commandes'),
        anItemName: t('Les commandes', 'La commande'),
        itemName: t('Une commande', 'Des commandes'),
        thisItemName: t('Cette commande', 'Ces commandes'),
        ofThisItemName: t('de la commande', 'des commandes')
      }
    } else {
      return {
        genre: 'male',
        objectName: t('Devis', 'Devis'),
        anItemName: t('Le devis', 'Les devis'),
        itemName: t('Un devis', 'Des devis'),
        thisItemName: t('Ce devis', 'Ces devis'),
        ofThisItemName: t('du devis', 'des devis')
      }
    }
  }
}

type FieldsNamingNames = {
  genre: 'male' | 'female'
  objectName: SingularPlural
  anItemName: SingularPlural
  itemName: SingularPlural
  thisItemName: SingularPlural
  ofThisItemName: SingularPlural
}

type SingularPlural = {
  singular: string
  plural: string
}

const t = (singular: string, plural: string) => {
  return { singular, plural }
}

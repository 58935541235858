import { AbstractLabel } from '@core/models/abstractLabel'
import { makeObservable } from 'mobx'
import { City } from '@core/models/city'

export class Province extends AbstractLabel {
  cities: City[]
  constructor(data?: Partial<Province>) {
    super(data)
    makeObservable(this)
    Object.assign(this, data)
    if (this.cities) {
      this.cities.forEach((item, index) => {
        this.cities[index] = new City(item)
      })
      this.cities.sort((x, y) => x.label.localeCompare(y.label))
    }
  }
}

import React from 'react'
import { Menu } from 'antd'
import { SkBtn } from '@core/components/btns'
import { SkAside, SkAsideBody, SkAsideHeader } from '@core/components/aside/skAside'
import { rootStore } from '@/stores/rootStore'
import { SkIconUilArrowLeft } from '@core/global/icons'
import { SkLoadMeAndReferential } from '@/layouts/default/skDefaultLayout'
import { useRouter } from 'next/router'
import Link from 'next/link'
import { observer } from 'mobx-react-lite'

const SkMenuParamLinks = observer(() => {
  const router = useRouter()

  const menuItems = []
  rootStore.menuStore.adminLinks?.forEach(adminLink => {
    const children = []
    adminLink.children?.forEach(child => {
      children.push({
        key: child.path,
        id: `submenu${child.id}`,
        label: <Link href={child.path}>{child.label}</Link>
      })
    })
    const groupItem = {
      id: `menu-${adminLink.id}`,
      type: 'group',
      label: adminLink.label.toUpperCase(),
      className: 'mb-5',
      children
    }
    menuItems.push(groupItem)
  })

  return (
    <SkAside>
      <SkAsideHeader>
        <div className="pt-3 pl-3">
          <SkBtn id="gotoHomePage" gray={true} linkTo="/" icon={<SkIconUilArrowLeft />}>
            Retour
          </SkBtn>
        </div>
      </SkAsideHeader>
      <SkAsideBody>
        <div className="p-3 text-xl font-bold text-gray-300">Paramètres</div>
        <Menu theme="dark" mode="inline" items={menuItems} selectedKeys={[router.route.replace('/[id]', '')]} />
      </SkAsideBody>
    </SkAside>
  )
})

export default function SkAdminLayout(props: { children }) {
  return (
    <SkLoadMeAndReferential>
      <div className="flex">
        <SkMenuParamLinks />
        <div className="w-full">{props.children}</div>
      </div>
    </SkLoadMeAndReferential>
  )
}

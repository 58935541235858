import { action, makeObservable, observable } from 'mobx'
import moment from 'moment'
import { Guid } from '@core/utils/guid'

export class AbstractItem<T = number> {
  @observable
  id?: T
  createdBy?: string
  @observable
  createdByLabel?: string
  createdDate?: string
  lastModifiedBy?: string
  lastModifiedDate?: string
  @observable
  isDeleted?: boolean
  @observable
  ui?: AbstractUi
  createdDateMoment?: moment.Moment

  constructor(data: Partial<AbstractItem<T>>) {
    Object.assign(this, data)
    this.ui = new AbstractUi()
    if (this.createdDate) {
      this.createdDateMoment = moment(this.createdDate)
    }
  }
}

export class AbstractUi {
  @observable
  isChecked?: boolean
  @observable
  isExpanded?: boolean
  @observable
  isLoading?: boolean
  @observable
  isSaving?: boolean
  @observable
  isDownLoading?: boolean
  @observable
  isDeleting?: boolean
  @observable
  actionIsLoading?: boolean
  @observable
  isDisabled?: boolean
  @observable
  pdfIsDownLoading?: boolean
  index?: number

  constructor() {
    makeObservable(this)
  }

  @action
  toggleIsChecked? = () => {
    this.isChecked = !this.isChecked
  }

  @action
  toggleIsExpanded? = () => {
    this.isExpanded = !this.isExpanded
  }

  private _fakeId?: string
  get fakeId() {
    if (!this._fakeId) this._fakeId = Guid.newGuid()
    return this._fakeId
  }
}

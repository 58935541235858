import { ApiStore } from '@core/api/apiStore'
import { action, computed, makeObservable, observable, runInAction, toJS } from 'mobx'
import React from 'react'
import { deepEqual } from 'fast-equals'
import { AbstractItem } from '../models/abstractItem'
import { Message } from '@core/service/message'
import { coreStore } from '@core/store/coreStore'
import { Tools } from '@core/utils/tools'
import { FormContext } from '@core/contexts/formContext'
import { SkTab } from '@core/global/interfaces'
import { useObjectMemo } from '@core/utils/hooks'
import { SkStoreProvider } from '@core/contexts/storeContext'
import { ModalContext } from '@core/components/modal/skModal'

export const SkEditContext = React.createContext(null)

export function useEditContext<T extends EditContextModalAndPage<ApiStore<AbstractItem>> | EditContext<ApiStore<AbstractItem>>>() {
  return React.useContext<T>(SkEditContext)
}

export const SkEditProvider = ({
  editContext,
  children
}: {
  editContext: EditContextModalAndPage<ApiStore<AbstractItem>> | EditContext<ApiStore<AbstractItem>>
  children: React.ReactNode
}) => {
  const contextValue = useObjectMemo(editContext)
  return (
    <SkStoreProvider store={editContext?.store}>
      <SkEditContext.Provider value={contextValue}>{children}</SkEditContext.Provider>
    </SkStoreProvider>
  )
}

export class EditContext<T extends ApiStore<AbstractItem>> {
  startPath: string
  formContext: FormContext
  @observable
  tabs: SkTab[] = []
  @observable
  isItemChanged: boolean
  dontCheckIfItemIsChanged: boolean
  itemClone: any
  @observable
  activeKeyTab: string
  @observable
  isEditing: boolean
  @observable
  isModal = false
  @observable
  isLoading = false
  onExitModalStep: () => Promise<void>
  modalContext: ModalContext
  activeKeyTabInProgress: boolean

  constructor(public store: T) {
    makeObservable(this)
  }

  @action
  async loadItemOrCreateNewItem(itemId?: number) {
    this.isEditing = !!itemId
    await this.store.loadItemOrCreateNewItem(itemId)
    runInAction(() => {
      this.itemClone = toJS(this.store.item)
    })
  }

  setNewItem() {
    this.store.setNewItem()
    return this
  }

  async loadFirstItemOrCreateNewItem() {
    const data = await this.store.$first()
    if (data) {
      this.store.setItem(data)
    } else {
      this.store.createNewItem()
    }
    runInAction(() => {
      this.itemClone = toJS(this.store.item)
    })
  }

  @computed
  get tabActive() {
    if (this.tabsAvailable.length) {
      this.modalContext?.scrollTop()
      if (!this.activeKeyTab && this.urlParamTabKeyValue && this.tabsAvailable.some(x => x.key === this.urlParamTabKeyValue)) {
        this.activeKeyTab = this.urlParamTabKeyValue
      }
      return this.tabsAvailable.find(x => x.key === this.activeKeyTab) ?? this.tabsAvailable[0]
    }
    return null
  }

  @computed
  get tabActiveIndex() {
    if (this.tabsAvailable.length) {
      return this.tabsAvailable.findIndex(x => x.key === this.tabActive.key)
    }
    return 0
  }

  @computed
  get tabsAvailable() {
    if (this.isEditing) {
      return this.tabs.filter(x => x.needForEditing === true)
    }
    return this.tabs.filter(x => x.needForCreation === true)
  }

  @computed
  get withNextTab() {
    return this.tabActiveIndex < this.tabs.length - 1
  }

  @action
  setIsModal = (value: boolean) => {
    this.isModal = value
  }

  @action
  reset = () => {
    this.isItemChanged = false
    this.formContext?.fields?.reset()
    Object.assign(this.store.item, new this.store.tCreator(toJS(this.itemClone)))
  }

  @action
  setItemClone = (data: T['item']) => {
    this.isItemChanged = false
    this.itemClone = toJS(data)
    this.checkIfItemIsItemChanged()
  }

  @action
  setItem = (data: T['item']) => {
    this.store.setNewItem(data)
    this.itemClone = toJS(this.store.item)
    return this
  }

  async submit() {
    try {
      await this.saveIfFormValid(true)
    } catch (e) {
      // silent
      console.log(e)
    }
  }

  onValuesChange = () => {}

  saveIfFormValid = (withMessageSave?: boolean) => {
    return new Promise<boolean>(async (resolve, reject) => {
      try {
        await this.formIsValid()
        await this.$save(withMessageSave)
        resolve(true)
      } catch (error) {
        reject(error)
      }
    })
  }

  saveIfItemChanged = async () => {
    if (this.isItemChanged) await this.saveIfFormValid(false)
  }

  formIsValid = async () => {
    if (this.formContext?.fields?.instance) {
      await this.formContext.fields.areValid()
    }
  }

  @action
  $save = async (withMessageSave?: boolean) => {
    try {
      this.isLoading = true
      this.isItemChanged = false
      this.itemClone = toJS(await this.store.$save(this.store.item, null, withMessageSave ?? !this.isModal))
    } catch (e) {
      runInAction(() => this.checkIfItemIsItemChanged())
      throw e
    } finally {
      runInAction(() => (this.isLoading = false))
    }
  }

  @action
  $saveAndCloneNow = async (withMessageSave?: boolean) => {
    let itemCloned: any
    try {
      itemCloned = toJS(this.itemClone)
      this.setItemClone(this.store.item)
      await this.$save(withMessageSave)
    } catch (e) {
      this.setItemClone(itemCloned)
      this.reset()
      throw e
    }
  }

  @action
  $delete = async () => {
    try {
      this.isLoading = true
      await this.store.$deleteById(this.store.item.id)
      runInAction(() => (this.store.item = null))
    } catch (e) {
      Message.error('Suppression impossible')
      console.error(`Suppression impossible ${this.store.baseUrl} ${this.store.item.id}   - '${e}`)
    } finally {
      runInAction(() => (this.isLoading = false))
    }
  }

  @action
  $reload = async () => {
    try {
      this.isLoading = true
      await this.store.$reload(this.store.item)
      this.itemClone = toJS(this.store.item)
    } finally {
      this.isLoading = false
    }
  }

  @action
  setTabActive = async (tabKey: string, replace = false) => {
    try {
      if (this.activeKeyTabInProgress) return
      if (this.activeKeyTab === tabKey || (!tabKey && this.tabActiveIndex === 0)) return
      this.activeKeyTabInProgress = true
      if (this.tabsAvailable?.length) {
        const index = this.tabsAvailable.findIndex(x => x.key === tabKey)
        const indexByDefault = index > 0 ? index : 0
        const valueToSet = indexByDefault > 0 ? tabKey : null
        this.onExitModalStep && (await this.onExitModalStep())
        await runInAction(async () => {
          const tab = this.tabsAvailable[indexByDefault]
          this.activeKeyTab = tab.key
          if (this.urlParamTabKeyValue !== valueToSet) await this.addQueryParam(this.urlParamTabKey, valueToSet, replace)
        })
      } else {
        this.activeKeyTab = tabKey
      }
    } finally {
      this.activeKeyTabInProgress = false
    }
  }

  @action
  setIsEditing(value: boolean) {
    this.isEditing = value
    return this
  }

  next = async () => {
    await this.checkFormFields(this.tabNext)
  }

  prev = async () => {
    await this.checkFormFields(this.tabPrev)
  }

  @computed
  get urlParamTabKey() {
    return `${this.store?.key.toLowerCase()}-${this.isModal ? 'step' : 'tab'}`
  }

  get urlParamTabKeyValue() {
    const params = new URLSearchParams(location.search)
    return params.get(this.urlParamTabKey)
  }

  @action
  checkIfItemIsItemChanged() {
    if (!this.store || this.dontCheckIfItemIsChanged) return
    this.isItemChanged = !deepEqual(Tools.cleanData(this.itemClone), Tools.cleanData(this.store.item))
  }

  @action
  itemIsDifferentFrom(item: any) {
    return !deepEqual(Tools.cleanData(item), Tools.cleanData(this.store.item))
  }

  removeQueryParam = async (param: string) => {
    const { pathname, query } = coreStore.routerStore
    const params = new URLSearchParams(query)
    if (params.get(param)) {
      params.delete(param)
      await coreStore.routerStore.replace({ pathname, query: params.toString() }, undefined, { shallow: true })
    }
  }

  addQueryParam = async (param: string, value: string, replace: boolean = false) => {
    const { pathname, query } = coreStore.routerStore
    const params = new URLSearchParams(query)
    if (params.get(param)) {
      params.delete(param)
    }
    if (value) params.append(param, value)
    if (replace) {
      await coreStore.routerStore.replace({ pathname, query: params.toString() }, undefined, { shallow: true })
    } else {
      await coreStore.routerStore.push({ pathname, query: params.toString() }, undefined, { shallow: true })
    }
  }

  protected tabNext = async () => {
    await this.setTabActive(this.tabsAvailable[this.tabActiveIndex + 1].key)
  }

  protected tabPrev = async () => {
    await this.setTabActive(this.tabsAvailable[this.tabActiveIndex - 1].key)
  }

  protected checkFormFields = async (actionIfValid: () => void) => {
    if (this.formContext?.fields.instance) {
      setTimeout(() => {
        this.formContext.fields
          .areValid()
          .then(async () => actionIfValid())
          .catch(() => {
            // silent
          })
      })
    } else {
      await actionIfValid()
    }
  }
}

export type PropsEditContext = {
  itemId?: number
  isEditing?: boolean
}

export type PropsEditContextModalAndPage<TItem> = {
  itemId?: number
  isEditing?: boolean
  title?: React.ReactNode
  actionLabel?: string
  onClose?: () => void
  onSubmit?: (data: TItem) => void
  noSave?: boolean
  titleForCreateOrEdit?: string
  withSaveOnExit?: boolean
  withNoActionOnExit?: boolean
  removeItemOnCancel?: boolean
  setEventStoreModifiedOnClose?: boolean
}

export class EditContextModalAndPage<TStore extends ApiStore<AbstractItem>> extends EditContext<TStore> {
  constructor(public props: PropsEditContextModalAndPage<TStore['item']>, store: TStore) {
    super(store)
  }

  onSubmit() {
    this.onClose()
    this.props?.onSubmit?.(this.store.item)
  }

  onClose() {
    if (this.props?.setEventStoreModifiedOnClose && this.store.item.id) this.store.setEventStoreModified(this.store.item.id, this.store.item)
    if (this.startPath && coreStore.routerStore.asPath !== this.startPath) coreStore.routerStore.replace(this.startPath)
    this.props?.onClose && this.props.onClose()
  }

  @action
  async submit() {
    this.onExitModalStep && (await this.onExitModalStep())
    if (this.props?.noSave) {
      await this.formIsValid()
    } else {
      await this.saveIfFormValid(true)
    }
    this.onSubmit()
  }
}

import React from 'react'
import { SkIconUilPen } from '@core/global/icons'
import { SkBtn } from '@core/components/btns'
import { BtnProps } from '@core/components/btns/skBtn'

export function SkBtnEdit(props: BtnProps) {
  return (
    <SkBtn {...props} icon={<SkIconUilPen />}>
      Modifier
    </SkBtn>
  )
}

import React from 'react'

export function SkLabelInfo({ label, value, id }: { label: React.ReactNode; value: React.ReactNode; id?: string }) {
  return (
    <div>
      <div className="text-muted pb-2 text-xs">{label}</div>
      <div data-id={`SkLabelInfo_${id}`} className="font-semibold">
        {value}
      </div>
    </div>
  )
}

import React from 'react'
import { observer } from 'mobx-react-lite'
import styles from '@core/components/pageList/header/skPageListHeader.module.scss'
import { ApiStore } from '@core/api/apiStore'
import classNames from 'classnames'
import { SkIconUilArrowLeft } from '@core/global/icons'
import { AbstractItem } from '@core/models/abstractItem'
import { Space } from 'antd'

interface IProps<T extends AbstractItem> {
  title: string
  store?: ApiStore<T>
  isSubHeader?: boolean
  withGoBack?: boolean
  withRadius?: false
  leftTemplate?: any
  rightTemplate?: any
  children?: any
  className?: string
  icon?: any
}

export const SkPageListHeader = observer(function SkPageListHeader<T extends AbstractItem>(props: IProps<T>) {
  const entityName = props.store?.key ?? ''
  const entityNameSuffix = props.store?.entityNameSuffix ? `_${props.store?.entityNameSuffix}` : ''

  return (
    <>
      <div className={classNames('relative bg-white px-3 pt-4 md:px-6 md:pt-2 lg:bg-gray-100', props.className)}>
        {props.withGoBack && (
          <a className={`absolute ${styles.iconGoBack}`}>
            <SkIconUilArrowLeft size="30" className="text-gray-500" />
          </a>
        )}
        <div className="flex items-center justify-between pb-3">
          <div className="flex items-center justify-start">
            <h1 id={`SkPageListHeaderTitle_${entityName}${entityNameSuffix}`} className="mr-3 text-2xl font-bold">
              {props.title}
            </h1>
            {props.leftTemplate}
          </div>
          <Space size="middle">{props.rightTemplate}</Space>
        </div>
        <div>{props.children}</div>
      </div>
    </>
  )
})

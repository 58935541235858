import { coreStore } from '@core/store/coreStore'

export class LocalStorage {
  public static setItem(key: string, value: any) {
    if (value === null || value === undefined) {
      LocalStorage.removeItem(key)
    } else {
      localStorage.setItem(`${coreStore.paramAppStore.applicationName}-${key}`, JSON.stringify(value))
    }
  }
  public static getItem(key: string) {
    return JSON.parse(localStorage.getItem(`${coreStore.paramAppStore.applicationName}-${key}`))
  }
  public static removeItem(key: string) {
    localStorage.removeItem(`${coreStore.paramAppStore.applicationName}-${key}`)
  }
}

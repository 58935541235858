import * as React from 'react'
import { Rule } from 'rc-field-form/lib/interface'
import { SizeType } from 'antd/es/config-provider/SizeContext'
import { Input } from 'antd'
import { useEffect, useState } from 'react'
import { runInAction } from 'mobx'

type IProps<T> = {
  label?: string
  placeholder?: string
  rules?: Rule[]
  size?: SizeType
  min?: number
  max?: number
  disabled?: boolean
  autoFocus?: boolean
  inputClassName?: string
  required?: boolean
} & (
  | {
      value?: never
      property: (x: T) => number
      model: T
      onChange?: (value: number) => void
    }
  | {
      value: number
      property?: never
      model?: never
      onChange: (value: number) => void
    }
)

export function SkNumberStep<T>(props: IProps<T>) {
  const [value, setValue] = useState<number>(props.value)
  const onChange = (e: any) => {
    const { value } = e.target
    const reg = /^-?\d*(\.\d*)?$/
    if ((!isNaN(value) && reg.test(value)) || value === '' || value === '-') {
      runInAction(() => {
        setValue(parseInt(value))
        props.onChange(value)
      })
    }
  }
  const addValue = (increment: number) => {
    runInAction(() => {
      if (increment === -1 && (!value || (props.min === 1 && value === 1))) return
      if (increment === 1 && !value) {
        setValue(1)
        props.onChange(1)
        return
      }
      const val = value + increment
      setValue(val)
      props.onChange(val)
    })
  }

  useEffect(() => {
    setValue(props.value)
  }, [props.value])

  return (
    <div className="flex-center w-20 rounded-md border-2 bg-white p-1 text-center">
      <a className="text-primary px-2" onClick={() => addValue(-1)}>
        -
      </a>
      <Input bordered={false} value={value} className="w-10 p-0 text-center !shadow-none" onChange={onChange} />
      <a className="text-primary px-2" onClick={() => addValue(1)}>
        +
      </a>
    </div>
  )
}

import { SkFilterNumber, SkFilterSelect, SkFilterText } from '@core/components'
import { makeObservable, observable } from 'mobx'
import { SkFilterSelectMultiple } from '@core/components/filters/selectMultiple/skFilterSelectMultiple'
import { ApiStore } from '@core/api/apiStore'
import { SkFilterDatePickerAndDuration } from '@core/components/filters/datePickerAndDuration/skFilterDatePickerAndDuration'
import { SkFilterDatePicker } from '@core/components/filters/datePicker/skFilterDatePicker'
import { SkFilterFrequency } from '@core/components/filters/frequency/skFilterFrequency'
import { SkFilterMonthPicker } from '@core/components/filters/monthPicker/skFilterMonthPicker'

export abstract class Filter {
  label: string
  component?: any
  @observable
  isActive?: boolean
  propertyToFilter: string
  @observable
  data?: any
  isCustom? = false
  abstract width?: number
  componentName?: string
  disabled?: boolean

  constructor(data: Partial<Filter>, componentName: string, component: any) {
    Object.assign(this, data)
    this.componentName = componentName
    this.component = component
    makeObservable(this)
  }
}

export class FilterStoreText extends Filter {
  width? = 260

  constructor(data: FilterStoreText) {
    super(data, 'SkFilterText', SkFilterText)
  }
}

export class FilterStoreNumber extends Filter {
  width? = 260

  constructor(data: FilterStoreNumber) {
    super(data, 'SkFilterNumber', SkFilterNumber)
    Object.assign(this, data)
  }
}

export class FilterStoreSelect<Y> extends Filter {
  width? = 260
  items: Y[]

  constructor(data: FilterStoreSelect<Y>) {
    super(data, 'SkFilterSelect', SkFilterSelect)
    Object.assign(this, data)
  }
}

export class FilterStoreApiSelect extends Filter {
  width? = 260
  store: ApiStore<any>
  propertyToSearch?: string
  customPropertyToSearch?: string
  propertyToOrder?: string

  constructor(data: FilterStoreApiSelect) {
    super(data, 'FilterStoreApiSelect', SkFilterSelect)
    Object.assign(this, data)
  }
}

export class FilterStoreDatePicker extends Filter {
  width? = 200

  constructor(data: FilterStoreDatePicker) {
    super(data, 'SkFilterDatePicker', SkFilterDatePicker)
    Object.assign(this, data)
  }
}

export class FilterStoreDatePickerAndDuration extends Filter {
  width? = 260

  constructor(data: FilterStoreDatePicker) {
    super(data, 'SkFilterDatePickerAndDuration', SkFilterDatePickerAndDuration)
    Object.assign(this, data)
  }
}

export class FilterStoreMonthPicker extends Filter {
  width? = 260

  constructor(data: FilterStoreMonthPicker) {
    super(data, 'SkFilterMonthPicker', SkFilterMonthPicker)
    Object.assign(this, data)
  }
}

export class FilterStoreSelectMultiple extends Filter {
  width? = 260
  items: any[]

  constructor(data: FilterStoreSelectMultiple) {
    super(data, 'SkFilterSelectMultiple', SkFilterSelectMultiple)
    Object.assign(this, data)
  }
}

export class FilterStoreFrequency extends Filter {
  width? = 260

  constructor(data: FilterStoreFrequency) {
    super(data, 'SkFilterFrequency', SkFilterFrequency)
    Object.assign(this, data)
  }
}

import { AbstractLabel } from './abstractLabel'
import { makeObservable, observable } from 'mobx'

export class Role extends AbstractLabel {
  arcgisLogin: string
  permissions: Permission[]
  crudPermissions: Permission[]
  admin: boolean

  constructor(data?: Partial<Role>) {
    super(data)
    makeObservable(this)
    Object.assign(this, data)
    if (!this.label) {
      this.label = this.code
    }
  }
}

export class Permission {
  id: number
  type: PermissionType
  canEdit?: boolean
  canRead?: boolean
  onlySelf?: boolean
  constructor(data?: Partial<Permission>) {
    Object.assign(this, data)
  }
}

export class PermissionType {
  id: number
  code: string
  label: string
  withReadEditOptions: boolean
  withOnlySelf: boolean
  @observable
  canEdit: boolean
  @observable
  onlySelf: boolean
  @observable
  canRead?: boolean
  uiPermissionId?: number

  constructor(data?: Partial<Permission>) {
    Object.assign(this, data)
    makeObservable(this)
    if (!this.label) this.label = this.code
  }
}

import { DatePicker } from 'antd'
import * as React from 'react'
import { SizeType } from 'antd/es/config-provider/SizeContext'
import { WithFormItem } from '@core/components/form'
import moment from 'moment'
import { useEffect, useRef, useState } from 'react'
import classNames from 'classnames'
import { useFormContext } from '@core/contexts/formContext'
import { IPropsInput, InitInput } from '@core/utils/initInput'
import { ListenInput } from '@core/utils/listenInput'
import { useIsMobile } from '@core/utils/hooks/useIsMobile'

type IProps<T, Y> = IPropsInput<T, Y> & {
  placeholder?: string
  size?: SizeType
  autoFocus?: boolean
  showTime?: boolean
  required?: boolean
  widthFull?: boolean
  clearAfterChange?: boolean
}

SkDatePicker.defaultProps = {
  widthFull: true
}
export function SkDatePicker<T>(props: IProps<T, Date>) {
  const { id } = InitInput(props, 'SkDatePicker')
  const isMobile = useIsMobile()

  return <>{!isMobile ? <SkDatePickerDesktop id={id} {...props} /> : <SkDatePickerMobile id={id} {...props} />}</>
}

const SkDatePickerDesktop = (props: IProps<unknown, Date>) => {
  const formContext = useFormContext()
  const [allowClear, setAllowClear] = useState(true)

  const { onChangeInput, value, setFieldsValue } = ListenInput({ ...props, withInitModel: (model: any) => moment(model) }, props.id)

  useEffect(() => {
    if (props.rules || props.required) {
      setAllowClear(!(props.rules?.some((x: any) => x['required']) || props.required))
    }
  }, [props.rules])

  useEffect(() => {
    setFieldsValue(value)
    if (props.clearAfterChange) {
      setTimeout(() => {
        setFieldsValue(null)
      }, 300)
    }
  }, [value])

  return (
    <WithFormItem {...props} value={value ? moment(value) : undefined} id={props.id}>
      <DatePicker
        id={props.id}
        value={value ? moment(value) : undefined}
        allowClear={allowClear}
        showTime={props.showTime}
        onChange={onChangeInput}
        className={classNames({ 'w-full': props.widthFull }, { 'w-[200px]': !props.widthFull })}
        disabled={props.disabled || formContext?.props.disabled}
        format="DD/MM/YYYY"
        size={props.size}
        placeholder={props.placeholder}
      />
    </WithFormItem>
  )
}

const SkDatePickerMobile = (props: IProps<unknown, Date>) => {
  const formContext = useFormContext()
  const { value, setFieldsValue, setValueAndOnChange } = ListenInput({ ...props, withInitModel: (model: any) => moment(model).format('YYYY-MM-DD'), valueForClear: '' }, props.id)
  const inputRef = useRef(null)

  useEffect(() => {
    setFieldsValue(value)
  }, [value])

  useEffect(() => {
    setTimeout(() => {
      if (inputRef.current) inputRef.current.defaultValue = ''
    }, 200)
  }, [])

  const onChangeInputDate = event => {
    const newValue = event.target.value ? moment(event.target.value, 'YYYY-MM-DD').toDate() : null
    setValueAndOnChange(newValue)
    // setTimeout is required dor
    setTimeout(() => (event.target.defaultValue = ''), 500)
    // alert(newValue)
  }

  return (
    <WithFormItem {...props} value={value ? moment(value).format('YYYY-MM-DD') : ''} id={props.id}>
      {/*<div>{value ? moment(value).format('YYYY-MM-DD') : ''}</div>*/}
      <input
        ref={inputRef}
        id={props.id}
        value={value ? moment(value).format('YYYY-MM-DD') : ''}
        type="date"
        onChange={onChangeInputDate}
        onReset={onChangeInputDate}
        className={classNames('w-full rounded border-2 p-2', { 'w-full': props.widthFull }, { 'w-[200px]': !props.widthFull })}
        disabled={props.disabled || formContext?.props.disabled}
        placeholder={props.placeholder}
      />
    </WithFormItem>
  )
}

import { makeObservable, observable } from 'mobx'
import { AbstractCustomer } from '@core/models/abstractCustomer'
import { AbstractLabel } from '@core/models/abstractLabel'
import { PersonTypeEnum } from '@core/global/enums'
import { Discount } from '@common/models/discount/discount'
import { Price } from '@common/models/price/price'
import { User } from '@common/models/user/user'
import { Accounting } from '@common/models/accounting/accounting'

export class Customer extends AbstractCustomer {
  @observable
  globalDiscount: Discount
  @observable
  discounts: Discount[]
  @observable
  totalRemainingAmountToPay: Price
  @observable
  totalCreditToUse: Price
  @observable
  hasAwaitingOrder: boolean
  @observable
  hasSubscriptionInProgress: boolean
  @observable
  commercial: User
  @observable
  isCommissionable: boolean
  @observable
  accounting: Accounting
  // ATTENTION : A l'ajout d'une property, il faut l'instancier dans le constructor

  constructor(data?: Partial<Customer>) {
    super(data)
    makeObservable(this)
    if (!data?.personType) this.personType = new AbstractLabel({ id: PersonTypeEnum.person })
    if (data?.globalDiscount) this.globalDiscount = new Discount(data.globalDiscount)
    if (data?.discounts) {
      this.discounts = []
      data.discounts.forEach(item => {
        this.discounts.push(new Discount(item))
      })
    }
    if (data?.totalRemainingAmountToPay) this.totalRemainingAmountToPay = new Price(data.totalRemainingAmountToPay)
    if (data?.totalCreditToUse) this.totalCreditToUse = new Price(data.totalCreditToUse)
    this.hasAwaitingOrder = data?.hasAwaitingOrder
    this.hasSubscriptionInProgress = data?.hasSubscriptionInProgress
    if (data?.commercial) this.commercial = new User(data.commercial)
    this.isCommissionable = data?.isCommissionable
    if (data?.accounting) this.accounting = new Accounting(data.accounting)
  }
}

import React, { useEffect, useState } from 'react'
import { EventArgs, Rule, StoreValue } from 'rc-field-form/lib/interface'
import { Form } from 'antd'
import { observer } from 'mobx-react-lite'
import { useFormContext } from '@core/contexts/formContext'
import classNames from 'classnames'

export type IProps<T> = {
  label?: React.ReactNode
  description?: React.ReactNode
  value?: any
  rules?: Rule[]
  children: React.ReactNode
  guid?: string
  id: string
  rowClassName?: string
  validateTrigger?: 'onChange' | 'onBlur'
  withValidationMessage?: boolean
  required?: boolean
  disabled?: boolean
  property?: (item: T) => any
  model?: T
  valuePropName?: string
  getValueFromEvent?: (...args: EventArgs) => StoreValue
}

SkWithFormItem.defaultProps = {
  withValidationMessage: false
}

function SkWithFormItem<T>(props: IProps<T>) {
  const formContext = useFormContext()

  const [rules, setRules] = useState(props.rules ?? [])

  useEffect(() => {
    setRules(props.rules)
  }, [props.rules])

  useEffect(() => {
    if (props.required && !props.disabled) {
      setRules([...(rules ?? []), { required: true }])
    } else if (props.required === false) {
      if (rules?.length) {
        setRules(rules.filter((x: any) => !x.required))
      }
    }
  }, [props.required])

  useEffect(() => {
    if (props.disabled) {
      setRules([])
    } else if (props.rules || props.required) {
      if (props.required) {
        setRules([...(props.rules ?? []), { required: true }])
      } else {
        setRules([...(props.rules ?? [])])
      }
    }
  }, [props.disabled])

  return (
    <>
      <Form.Item
        initialValue={props.value}
        label={props.description ? <LabelWithDescription label={props.label} description={props.description} /> : props.label}
        name={props.id}
        rules={rules}
        valuePropName={props.valuePropName ?? 'value'}
        getValueFromEvent={props.getValueFromEvent}
        className={classNames(
          { 'float-label': formContext?.props.floatLabel },
          { 'with-value': props.value?.toString().length },
          { 'hide-explain': !(formContext?.props.withValidationMessage || props.withValidationMessage) },
          props.rowClassName
        )}
        validateTrigger={props.validateTrigger}
      >
        {props.children}
      </Form.Item>
    </>
  )
}

const LabelWithDescription = ({ label, description }: { label?: React.ReactNode; description?: React.ReactNode }) => {
  return (
    <div>
      {label}
      <div className="text-muted block py-1 text-xs font-light">{description}</div>
    </div>
  )
}

export const WithFormItem = observer(SkWithFormItem)

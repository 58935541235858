import { action, computed, makeObservable, observable } from 'mobx'
import { AbstractLabel } from '@core/models/abstractLabel'
import { Price } from '@common/models/price/price'
import { PackageTag } from '@common/models/package/packageTag'
import { PackageGroup } from '@common/models/packageGroup/packageGroup'
import { Discount } from '@common/models/discount/discount'
import { Tax } from '@common/models/tax/tax'
import { referentialStore } from '@common/models/referential/referentialStore'

export class Package extends AbstractLabel {
  @observable
  packageTags: PackageTag[]
  @observable
  price: Price
  @observable
  packageGroup: PackageGroup
  @observable
  discount: Discount
  unitPriceIncludingTaxes: Price
  tax: Tax

  constructor(data?: Partial<Package>) {
    super(data)
    makeObservable(this)
    Object.assign(this, data)
    if (this.packageTags) {
      this.packageTags.forEach((item, index) => {
        this.packageTags[index] = new PackageTag(item)
      })
    }
    this.price = new Price(this.price)
    if (this.discount) this.discount = new Discount(this.discount)
    if (this.packageGroup) this.packageGroup = new PackageGroup(this.packageGroup)
  }

  @action
  setDiscountType = (type: AbstractLabel) => {
    if (!type) {
      this.discount = null
      return
    }
    if (!this.discount) this.discount = new Discount()
    this.discount.type = type
  }

  @computed
  get taxes() {
    if (!this.packageTags?.length) return null
    const taxes = []
    this.packageTags.forEach(packageTag => {
      referentialStore.data.taxes
        .filter(x => x.taxTags.some(y => y.tag.id === packageTag.tag.id))
        .forEach(tax => {
          if (!taxes.some(x => x.id === tax.id)) taxes.push(tax)
        })
    })
    return taxes.sort((x, y) => x.rate - y.rate)
  }
}

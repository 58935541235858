import { DatePicker } from 'antd'
import * as React from 'react'
import { useEffect } from 'react'
import { SizeType } from 'antd/es/config-provider/SizeContext'
import { WithFormItem } from '@core/components/form'
import moment from 'moment'
import { useFormContext } from '@core/contexts/formContext'
import { IPropsInput, InitInput } from '@core/utils/initInput'
import { ListenInput } from '@core/utils/listenInput'

type IProps<T, Y> = IPropsInput<T, Y> & {
  placeholder?: string
  size?: SizeType
  autoFocus?: boolean
  inputClassName?: string
  required?: boolean
}

export function SkRangePicker(props: IProps<any, { from: string; to: string }>) {
  const { id } = InitInput(props, 'SkRangePicker')
  const { onChangeInput, value } = ListenInput(props, id)
  const formContext = useFormContext()

  useEffect(() => {
    const data = value?.to ? [moment(value.from), moment(value.to)] : null
    formContext.fields.instance?.setFieldsValue({ [id]: data })
  }, [value])

  return (
    <WithFormItem {...props} value={value && [moment(value.from), moment(value.to)]} id={id}>
      <DatePicker.RangePicker
        format="DD/MM/YYYY"
        className="w-full"
        disabled={props.disabled}
        onChange={(dates: [moment.Moment, moment.Moment]) =>
          dates ? onChangeInput({ from: dates[0].startOf('d').format(), to: dates[1].endOf('d').format() }) : onChangeInput(null)
        }
      />
    </WithFormItem>
  )
}

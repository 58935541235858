import { Company } from '@core/models/company'
import { AbstractItem } from '@core/models/abstractItem'
import { makeObservable, observable } from 'mobx'

export class UserCompany extends AbstractItem {
  @observable
  company: Company
  @observable
  isDefault: boolean

  constructor(data?: Partial<UserCompany>) {
    super(data)
    makeObservable(this)
    Object.assign(this, data)
  }
}

import { computed, makeObservable, observable } from 'mobx'
import { Tools } from '@core/utils/tools'
import { AbstractLabel } from '@core/models/abstractLabel'
import { referentialStore } from '@common/models/referential/referentialStore'
import React from 'react'
import { AbstractLabelObservable } from '@core/models/abstractLabelObservable'

export class Price extends AbstractLabel {
  @observable
  amount: number = 0
  @observable
  currency: AbstractLabelObservable
  @observable
  amountAsString: string

  constructor(data?: Partial<Price>) {
    super(data)
    makeObservable(this)
    Object.assign(this, data)
    if (!this.currency) {
      this.currency = referentialStore.data.tenantProperties.defaultCurrency ? referentialStore.data.tenantProperties.defaultCurrency : referentialStore.data.currencies[0]
    }
    if (!Tools.isNullOrUndefined(this.amount)) {
      this.label = `${this.amount} ${this.currency?.label}`
    }
  }

  @computed
  get isEmpty() {
    return JSON.stringify(this) === JSON.stringify(new Price())
  }

  @computed
  get amountRoundForCash() {
    if (Tools.isNullOrUndefined(this.amount)) return null
    const s = String(Math.round(this.amount))
    const lastDigit = s.slice(-1)
    if (lastDigit === '1' || lastDigit === '2') {
      return Number(`${s.slice(0, -1)}0`)
    } else if (lastDigit === '6' || lastDigit === '7' || lastDigit === '3' || lastDigit === '4') {
      return Number(`${s.slice(0, -1)}5`)
    } else if (lastDigit === '8') {
      return this.amount + 2
    } else if (lastDigit === '9') {
      return this.amount + 1
    }
    return this.amount
  }

  @computed
  get amountRoundForCurrency() {
    return +this.amount.toFixed(referentialStore.data.currencies.find(currency => currency.id === this.currency.id).currencyDecimal)
  }
}

export function SkFormatPrice<T>({ price }: { price: Price }) {
  return (
    <>
      {price ? (
        <>
          <>{price.amountAsString ?? Intl.NumberFormat('fr-FR').format(price.amount).split(' ').join(' ')}</>
          {price.currency && <sup> {price.currency.label}</sup>}
        </>
      ) : (
        '-'
      )}
    </>
  )
}

import React, { useEffect } from 'react'
import styles from '@core/components/filtersInput/skFiltersInput.module.scss'
import { observer } from 'mobx-react-lite'
import { ApiStore } from '@core/api/apiStore'
import { SkForm } from '@core/components/form/skForm'
import classNames from 'classnames'
import { action, runInAction } from 'mobx'
import { SkIconUilSlidersVAlt, SkIconUilTrashAlt } from '@core/global/icons'
import { SkPageListColFilters } from '@core/components'
import { SkBtn } from '@core/components/btns'
import { AbstractItem } from '@core/models/abstractItem'
import { Filter } from '@core/api/filter'
import { SkStoreProvider } from '@core/contexts/storeContext'

interface IProps<T extends AbstractItem> {
  store: ApiStore<T>
  className?: string
  onPressEnter?: () => void
  autoFocus?: boolean
  filters: Filter[]
  noSaveInLocalStorage?: boolean
  onChange?: () => void
}

export const SkFiltersInput = observer(<T extends AbstractItem>({ store, filters, noSaveInLocalStorage, className, autoFocus, onPressEnter }: IProps<T>) => {
  useEffect(
    action(() => {
      store.filtersAndViews.withViewAndLocalStorage = !noSaveInLocalStorage
    }),
    [noSaveInLocalStorage]
  )

  useEffect(
    action(() => {
      store.filtersAndViews.items = filters
    }),
    [filters]
  )

  return (
    <SkStoreProvider store={store}>
      {!!filters?.length && (
        <>
          <SkForm className={classNames(styles.skFormFilters, className, 'pb-6')} autoFocus={autoFocus}>
            <div className="flex flex-wrap gap-3">
              {filters
                ?.filter(x => x.isActive)
                .map(filter => {
                  const values: any = {
                    key: filter.label,
                    value: filter.data,
                    id: `store-${store.key}_filter-${filter.componentName}_property-${filter.propertyToFilter.replace(/\./g, '-')}`,
                    ...filter,
                    onChange: async (data: any) => {
                      runInAction(() => {
                        filter.data = data
                      })
                      store.setQueryToSearch()
                    },
                    onPressEnter: onPressEnter
                  }
                  return (
                    <div style={{ width: filter.width }} key={filter.propertyToFilter}>
                      {React.createElement(filter.component, values)}
                    </div>
                  )
                })}
              {store.filtersAndViews.needMoreFilters && (
                <div className="flex-center mt-5">
                  <SkBtn id="toggleFiltersOpen" gray onClick={() => store.filtersAndViews.toggleFiltersOpen()} icon={<SkIconUilSlidersVAlt />}>
                    {store.filtersAndViews.countMoreFilter > 0 ? (
                      <>
                        +{store.filtersAndViews.countMoreFilter} filtre{store.filtersAndViews.countMoreFilter > 1 && <>s</>}
                      </>
                    ) : (
                      <>filtres</>
                    )}
                  </SkBtn>
                </div>
              )}
              {filters?.length && (
                <div className="flex-center mt-5">
                  <SkBtn id="resetFilters" gray onClick={() => store.filtersAndViews.reset()} icon={<SkIconUilTrashAlt />}>
                    effacer tout
                  </SkBtn>
                </div>
              )}
            </div>
          </SkForm>
          {store.filtersAndViews.filtersOpen && <SkPageListColFilters store={store} />}
        </>
      )}
    </SkStoreProvider>
  )
})

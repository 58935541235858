import React from 'react'
import { SkText } from '@core/components'
import { SkIconUilSearch } from '@core/global/icons'

interface IProps {
  id: string
  label: string
  placeholder?: string
  className?: string
  value: string
  onChange: (data: string) => void
  onPressEnter?: () => void
  autoCapitalize?: boolean
  bordered?: boolean
  debounce?: number
}

export function SkFilterText(props: IProps) {
  return (
    <>
      <div className="w-full filter">
        <SkText
          id={props.id}
          label={props.label}
          value={props.value}
          debounce={props.debounce}
          onChange={undefined}
          onDebounceChange={props.onChange}
          onPressEnter={props.onPressEnter}
          placeholder={props.placeholder ?? 'Taper au moins un caractère ...'}
          size={'large'}
          autoCapitalize={props.autoCapitalize}
          className={props.className}
          suffix={<SkIconUilSearch size="15" />}
          bordered={props.bordered}
        />
      </div>
    </>
  )
}

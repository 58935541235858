import React, { useState } from 'react'
import { SkModal, SkModalBody, SkModalFooter, SkModalHeader, SkText } from '@core/components'
import { SkForm } from '@core/components/form'
import { ApiStore } from '@core/api/apiStore'
import { EditContext } from '@core/contexts/editContext'
import { Message } from '@core/service/message'
import { AbstractItem } from '@core/models/abstractItem'

interface IProps<T extends AbstractItem> {
  store: ApiStore<T>
}

class Context extends EditContext<ApiStore<any>> {
  filterName: string

  constructor(public props: IProps<any>) {
    super(props.store)
  }

  submit = async () => {
    try {
      await this.formContext.fields.areValid()
      this.store.filtersAndViews.saveViewsInStorage(this.filterName)
      this.store.filtersAndViews.toggleModalToCreate()
      Message.success('Nouvelle vue enregistrée')
      await this.store.filtersAndViews.setView(this.filterName, false)
    } catch {
      // silent
    }
  }
}

export function SkModalToCreateView<T extends AbstractItem>(props: IProps<T>) {
  const [context] = useState(() => new Context(props))
  const { filtersAndViews } = props.store

  return (
    <SkModal editContext={context} onCancel={() => filtersAndViews.toggleModalToCreate()}>
      <SkModalHeader title="Créer une nouvelle vue" onCancel={() => filtersAndViews.toggleModalToCreate()} />
      <SkForm>
        <SkModalBody withFooter>
          <SkText label="Nom" value={context.filterName} onChange={x => (context.filterName = x)} id="filterName" required autoFocus />
        </SkModalBody>
        <SkModalFooter onCancel={() => filtersAndViews.toggleModalToCreate()} onSubmit={() => context.submit()} />
      </SkForm>
    </SkModal>
  )
}

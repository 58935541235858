import { AbstractLabel } from '@core/models/abstractLabel'
import { makeObservable, observable } from 'mobx'

export class Currency extends AbstractLabel {
  @observable
  currencyDecimal: number = 0

  constructor(data?: Partial<Currency>) {
    super(data)
    makeObservable(this)
    Object.assign(this, data)
  }
}

import * as React from 'react'
import { FocusEventHandler, KeyboardEventHandler, useEffect, useState } from 'react'
import { SizeType } from 'antd/es/config-provider/SizeContext'
import { observer } from 'mobx-react-lite'
import { makeAutoObservable } from 'mobx'
import { Input } from 'antd'
import classNames from 'classnames'
import { SkIconUilSearch, SkIconUilTimes } from '@core/global/icons'
import { Tools } from '@core/utils/tools'

interface IProps {
  placeholder?: string
  value?: string
  onChange: (value: string) => void
  size?: SizeType
  disabled?: boolean
  autoFocus?: boolean
  id?: string
  iconPrefix?: boolean
  bordered?: boolean
  className?: string
  onFocus?: FocusEventHandler<any>
  onKeyDown?: KeyboardEventHandler<any>
}

class Context {
  value: string
  input: any

  constructor(protected props: IProps) {
    makeAutoObservable(this)
  }

  onChange = (value: any) => {
    this.value = Tools.capitalize(value)
    this.props.onChange?.(value)
  }

  reset = () => {
    this.onChange(null)
  }
}

export const SkSearch = observer((props: IProps) => {
  //***********************************************************************************************//
  //******** state ********************************************************************************//
  //***********************************************************************************************//
  const [context] = useState(() => new Context(props))

  useEffect(() => {
    setTimeout(() => {
      if (props.autoFocus) {
        context.input.focus()
      }
    })
  }, [])

  useEffect(() => {
    if (context.value?.toLowerCase() === props.value?.toLowerCase()) return
    context.onChange(props.value)
  }, [props.value])

  //***********************************************************************************************//
  //******** return *******************************************************************************//
  //***********************************************************************************************//
  return (
    <>
      {props.iconPrefix ? (
        <Input
          disabled={props.disabled}
          id={props.id}
          ref={instance => (context.input = instance)}
          type="text"
          value={context.value}
          prefix={context.value ? <SkIconUilTimes className="cursor-pointer" width={15} height={15} onClick={() => context.reset()} /> : <SkIconUilSearch width={15} height={15} />}
          placeholder={props.placeholder || 'Rechercher ...'}
          size={props.size || 'large'}
          className={classNames('skSearch pl-3', props.className)}
          autoFocus={props.autoFocus}
          onKeyDown={props.onKeyDown}
          onChange={x => context.onChange(x.target.value)}
          onFocus={props.onFocus}
          autoComplete="off"
          bordered={props.bordered}
        />
      ) : (
        <Input
          id={props.id}
          ref={instance => (context.input = instance)}
          type="text"
          value={context.value}
          suffix={context.value ? <SkIconUilTimes className="cursor-pointer" width={15} height={15} onClick={() => context.reset()} /> : <SkIconUilSearch width={15} height={15} />}
          placeholder={props.placeholder || 'Rechercher ...'}
          size={props.size || 'large'}
          className={classNames('skSearch pl-3', props.className)}
          autoFocus={props.autoFocus}
          onKeyDown={props.onKeyDown}
          onChange={x => context.onChange(x.target.value)}
          autoComplete="off"
          bordered={props.bordered}
        />
      )}
    </>
  )
})

import React from 'react'
import { SkMask } from '@core/components'

interface IProps {
  id: string
  label: string
  value: string
  mask: string
  onChange: (data: string) => void
  onPressEnter?: () => void
}
export function SkFilterMask(props: IProps) {
  return (
    <>
      <div className="w-full filter">
        <SkMask id={props.id} label={props.label} value={props.value} onChange={props.onChange} onPressEnter={props.onPressEnter} mask={props.mask} size={'large'} />
      </div>
    </>
  )
}

import React from 'react'
import { SkSelectMultiple } from '@core/components'
import { AbstractLabel } from '@core/models/abstractLabel'

interface IProps {
  id: string
  label: string
  value: AbstractLabel[] | number[]
  items: AbstractLabel[]
  onChange: (data: AbstractLabel[] | number[]) => void
}
export function SkFilterSelectMultiple(props: IProps) {
  return (
    <div className="w-full filter">
      <SkSelectMultiple
        id={props.id}
        label={props.label}
        value={props.value}
        allowClear={true}
        onChange={props.onChange}
        returnId={true}
        items={props.items}
        placeholder="Taper au moins un caractère ..."
        size={'large'}
      />
    </div>
  )
}

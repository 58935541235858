import { AbstractLabel } from '@core/models/abstractLabel'
import { Document } from '@core/models/document'
import { makeObservable, observable } from 'mobx'

export class BankAccount extends AbstractLabel {
  @observable
  bankCode: string
  @observable
  officeCode: string
  @observable
  accountNumber: string
  @observable
  ribKey: string
  @observable
  ribFile: Document

  constructor(data?: Partial<BankAccount>) {
    super(data)
    Object.assign(this, data)
    makeObservable(this)
  }
}

import { Select } from 'antd'
import * as React from 'react'
import { useEffect, useState } from 'react'
import { Rule } from 'rc-field-form/lib/interface'
import { AbstractLabel } from '../../models/abstractLabel'
import { SizeType } from 'antd/es/config-provider/SizeContext'
import { SkDrawer, SkFilterText } from '@core/components'
import { WithFormItem } from '@core/components/form'
import { SkBtn } from '@core/components/btns'
import { action, makeObservable, observable, runInAction } from 'mobx'
import { Recursive } from '@core/utils/recursive'
import { propertyName } from '@core/utils/nameOf'
import { useEditContext } from '@core/contexts/editContext'
import { useFormContext } from '@core/contexts/formContext'
import { InitInput } from '@core/utils/initInput'
import { observer } from 'mobx-react-lite'
import { AbstractItem } from '@core/models/abstractItem'
import { ListenInput } from '@core/utils/listenInput'
import { Tools } from '@core/utils/tools'
import { useIsMobile } from '@core/utils/hooks/useIsMobile'
import classNames from 'classnames'
import { useToggle } from 'react-use'
import { SkIconUilMultiply } from '@core/global/icons'

export type SkSelectProps<T, Y extends AbstractItem<Z>, Z = unknown> = {
  label?: React.ReactNode
  description?: string
  placeholder?: string
  items: Y[]
  rules?: Rule[]
  disabled?: boolean
  readOnly?: boolean
  autoFocus?: boolean
  showArrow?: boolean
  allowClear?: boolean
  setDefaultIfNull?: boolean
  size?: SizeType
  popupClassName?: string
  required?: boolean
  showSearch?: boolean
  itemsLoading?: boolean
  open?: boolean
  width?: string | number
  optionElement?: (item: any) => React.ReactNode
  dropdownRender?: (menu: React.ReactElement) => React.ReactElement
  propertyLabel?: string
  filterOption?: (input: string, option: { value: string }) => boolean
} & (
  | {
      id?: string
      value?: never
      model: T
      property: (x: T) => unknown
      onChange?: (value: Y) => void
      returnId?: never
    }
  | {
      id?: string
      value?: never
      model: T
      property: (x: T) => unknown
      onChange?: (value: Z) => void
      returnId: true
    }
  | {
      id: string
      value: Y
      model?: never
      property?: never
      onChange: (value: Y) => void
      returnId?: never
    }
  | {
      id: string
      value: Z
      model?: never
      property?: never
      onChange: (value: Z) => void
      returnId: true
    }
)

class Context<T, Y extends AbstractItem> {
  value: number | AbstractLabel
  @observable
  valueForSelect: string
  @observable
  allowClear = true

  constructor(value: number | AbstractLabel) {
    makeObservable(this)
    this.extractIdFromValue(value)
  }

  @action
  extractIdFromValue = (value: number | AbstractLabel) => {
    if (value === this.value) return
    this.value = value
    if (this.value !== undefined && this.value !== null) {
      if (this.value instanceof AbstractLabel || (this.value['id'] != undefined && this.value['id'] != null)) {
        this.valueForSelect = this.value['id'].toString()
      } else {
        this.valueForSelect = this.value.toString()
      }
    } else {
      this.valueForSelect = undefined
    }
  }

  @action
  setAllowClear = (value: boolean) => {
    this.allowClear = value
  }
}

export const SkSelect = observer(function SkSelect<T, Y extends AbstractItem<Z>, Z = unknown>(props: SkSelectProps<T, Y, Z>) {
  const formContext = useFormContext()
  const editContext = useEditContext()
  const { id } = InitInput(props, 'SkSelect')
  const { value, setFieldsValue } = ListenInput(props, id)
  const [context] = useState(() => new Context(value))
  const isMobile = useIsMobile()
  const [openDrawer, toggleOpenDrawer] = useToggle(false)

  useEffect(() => {
    if (props.setDefaultIfNull && Tools.isNullOrUndefined(context.valueForSelect) && props.items?.length) {
      onChangeValue((props.items[0] instanceof AbstractLabel && props.items.find(x => (x as unknown as AbstractLabel).isDefault)?.id.toString()) ?? props.items[0].id.toString())
    }
  }, [props.items, props.setDefaultIfNull])

  useEffect(() => {
    context.extractIdFromValue(value)
    setFieldsValue(context.valueForSelect)
  }, [value])

  useEffect(() => {
    if (Tools.isNullOrUndefined(props.allowClear) && (props.rules || props.required)) {
      context.setAllowClear(!(props.rules?.some((x: any) => x['required']) || props.required))
    }
  }, [props.rules])

  const onChangeValue = action((data: string) => {
    let valueToReturn: Z | Y

    context.valueForSelect = data

    if (data) {
      const item = data ? props.items?.find(x => x.id.toString() === data) : null
      valueToReturn = props.returnId ? item.id : item
    }

    if (props.property) {
      runInAction(() => Recursive.setData(props.model, propertyName(props.property), valueToReturn ?? null))
    }
    // @ts-ignore
    props.onChange?.(valueToReturn)

    editContext?.checkIfItemIsItemChanged()
    // mobile
    if (isMobile) toggleOpenDrawer()
  })

  const WrapperSelect = observer(() => (
    <WithFormItem {...props} id={id} value={context.valueForSelect}>
      <Select
        id={id}
        showSearch={props.showSearch ?? true}
        size={props.size}
        allowClear={props.allowClear ?? context.allowClear}
        placeholder={props.itemsLoading ? 'Chargement en cours ...' : props.placeholder ?? 'Rechercher ...'}
        className={props.popupClassName}
        onChange={onChangeValue}
        open={props.open}
        disabled={props.disabled || formContext?.props.disabled || props.itemsLoading}
        autoFocus={props.autoFocus}
        filterOption={(input, option) =>
          props.filterOption?.(input, { value: option.value as string }) ||
          (typeof option.children === 'string' && (option.children as string).toLowerCase().indexOf(input.toLowerCase()) >= 0)
        }
        showArrow={props.showArrow}
        dropdownRender={props.dropdownRender}
        style={{ width: props.width }}
        notFoundContent={<span>Aucun résultat ne correspond</span>}
      >
        {props.itemsLoading && <Select.Option value={context.valueForSelect?.toString() ?? 'items-loading'}>Chargement en cours ...</Select.Option>}
        {props.items?.map((item, i) => (
          <Select.Option key={item.id.toString()} value={item.id.toString()} id={`${id}-${i}`} data-id={`${id}_itemId-${item.id}`}>
            {props.optionElement ? props.optionElement(item) : item[props.propertyLabel ?? 'label']}
          </Select.Option>
        ))}
      </Select>
    </WithFormItem>
  ))

  return (
    <>
      {!isMobile ? (
        <WrapperSelect />
      ) : (
        <>
          <div onClick={toggleOpenDrawer}>
            <WrapperSelect />
          </div>
          {openDrawer && <SkDrawerSelect {...props} onClose={toggleOpenDrawer} valueForSelect={context.valueForSelect} onChangeValue={onChangeValue} />}
        </>
      )}
    </>
  )
})

const SkDrawerSelect = ({
  id,
  label,
  onChangeValue,
  items,
  propertyLabel,
  optionElement,
  onClose,
  valueForSelect
}: SkSelectProps<unknown, AbstractItem<unknown>> & { onClose: () => void; valueForSelect: string; onChangeValue: (string) => void }) => {
  const [terms, setTerms] = useState('')
  const [itemsFiltered, setItemFiltered] = useState(items)

  const queryItems = action((terms: string = null) => {
    setTerms(terms)
    if (items.length && terms) {
      const data = []
      items.forEach(x => {
        if (x[propertyLabel ?? 'label'].toLowerCase().includes(terms.toLowerCase())) {
          data.push(x)
        }
      })
      setItemFiltered(data)
    } else {
      setItemFiltered(items)
    }
  })

  return (
    <SkDrawer height="100%" width="100%" placement="bottom" title={<div className="-ml-14 text-center">{label}</div>} onClose={onClose}>
      <div className="px-3">
        <div className="border-b py-6">
          <div className="no-padding-for-form-item rounded-lg bg-gray-100">
            <SkFilterText id={`search${id}`} value={terms} onChange={queryItems} debounce={100} label={undefined} bordered={false} className="py-3" />
          </div>
          {!Tools.isNullOrUndefined(valueForSelect) && (
            <div className="pt-3">
              <SkBtn type="default" id={`clearSelection${id}`} size="middle" className="flex items-center rounded-full" onClick={() => onChangeValue(null)}>
                <span className="pr-2 text-xs">effacer la sélection</span>
                <span className="text-primary">
                  <SkIconUilMultiply width={15} height={15} />
                </span>
              </SkBtn>
            </div>
          )}
        </div>
        <div>
          {itemsFiltered?.map((item, i) => (
            <div
              className={classNames('border-b border-gray-200 p-3 px-3', { 'bg-blue2-200 font-bold': valueForSelect === item.id.toString() })}
              key={item.id.toString()}
              id={`${id}-${i}`}
              data-id={`${id}_itemId-${item.id}`}
              onClick={() => onChangeValue(item.id.toString())}
            >
              {optionElement ? optionElement(item) : item[propertyLabel ?? 'label']}
            </div>
          ))}
        </div>
      </div>
    </SkDrawer>
  )
}

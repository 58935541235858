import React, { useEffect, useMemo } from 'react'
import { Button, Tooltip } from 'antd'
import { SizeType } from 'antd/es/config-provider/SizeContext'
import { ButtonType } from 'antd/es/button'
import Link from 'next/link'
import { SkIconUilCheck } from '@core/global/icons'
import classNames from 'classnames'
import { useStoreContext } from '@core/contexts/storeContext'
import { ButtonShape } from 'antd/lib/button/button'
import { coreStore } from '@core/store/coreStore'

export type BtnProps = {
  size?: SizeType
  type?: ButtonType
  className?: string
  icon?: any
  disabled?: boolean
  children?: any
  danger?: boolean
  block?: boolean
  submit?: boolean
  autoFocus?: boolean
  loading?: boolean
  success?: boolean
  ghost?: boolean
  tooltip?: string
  shape?: ButtonShape
  permission?: string
  dark?: boolean
  gray?: boolean
  grayDark?: boolean
  gray200?: boolean
  second?: boolean
  dataId?: string
} & (
  | {
      id?: string
      linkTo: string
      onClick?: never
    }
  | {
      id: string
      linkTo?: never
      onClick?: (event: any) => void
    }
)

SkBtn.defaultProps = {
  size: 'large',
  type: 'primary'
}

export function SkBtn(props: BtnProps) {
  if (props.permission && coreStore.meStore && !coreStore.meStore.hasPermission(props.permission)) {
    return <></>
  }

  return props.tooltip ? (
    <Tooltip title={props.tooltip}>
      <div className="inline-flex">
        <GetButtonOrLink {...props} />
      </div>
    </Tooltip>
  ) : (
    <GetButtonOrLink {...props} />
  )
}

function GetButtonOrLink(props: BtnProps) {
  return (
    <>
      {props.linkTo ? (
        <Link href={props.linkTo} passHref id={props.id}>
          <BtnWrapper {...props} />
        </Link>
      ) : (
        <BtnWrapper {...props} />
      )}
    </>
  )
}

function BtnWrapper(props: BtnProps) {
  const storeContext = useStoreContext()
  let ref: any = null

  useEffect(() => {
    setTimeout(() => {
      if (props.autoFocus) {
        ref.focus()
      }
    })
  }, [])

  const id = useMemo(() => {
    const suffix = props.id ? props.id : props.linkTo
    return `SkBtn_${storeContext ? storeContext?.key : 'noStore'}_${suffix}`
  }, [])

  return (
    <Button
      id={id}
      data-id={props.dataId}
      size={props.size}
      danger={props.danger}
      onClick={props.onClick}
      type={props.type as ButtonType}
      icon={props.icon}
      disabled={props.disabled}
      block={props.block}
      loading={props.loading}
      ghost={props.ghost}
      shape={props.shape}
      className={classNames(
        'flex-center',
        { 'btn-dark': props.dark },
        { 'btn-gray': props.gray },
        { 'btn-second': props.second },
        { 'btn-gray-dark': props.grayDark },
        { 'btn-gray-200': props.gray200 },
        { 'pl-2': props.children && props.icon },
        props.className
      )}
      htmlType={props.submit ? 'submit' : 'button'}
      ref={instance => (ref = instance)}
    >
      <GetElement {...props} />
    </Button>
  )
}

function GetElement(props: BtnProps) {
  if (!props.children) return <></>
  return (
    <>
      {props.success ? (
        <div className="flex-center">
          <span className="animate-ping text-green-600">
            <SkIconUilCheck />
          </span>
        </div>
      ) : (
        <>{props.icon ? <span className="ml-2 !flex items-center">{props.children}</span> : props.children}</>
      )}
    </>
  )
}

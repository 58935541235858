import * as React from 'react'
import { Spin } from 'antd'

interface IProps {
  isLoading: boolean
  showIfLoading?: boolean
  children?: any
  className?: string
}

export function SkLoader(props: IProps) {
  return (
    <Spin spinning={props.isLoading} data-id={props.isLoading && 'SkLoaderIsLoading'}>
      {props.isLoading ? props.showIfLoading && props.children : props.children}
    </Spin>
  )
}

SkLoader.defaultProps = {
  showIfLoading: true,
  isLoading: false
}

import * as React from 'react'

type IProps<T> = {
  value: number
  sup?: string
}

export function SkFormatNumber<T>(props: IProps<T>) {
  const format = props.value?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
  return (
    <>
      {format ? format : '-'} {props.sup && <sup>{props.sup}</sup>}
    </>
  )
}

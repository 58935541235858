import { action, makeObservable, observable } from 'mobx'
import { FormInstance } from 'antd'
import { Message } from '@core/service/message'

export class FormFields {
  instance: FormInstance
  @observable
  valid = true
  @observable
  isInit = false

  constructor() {
    makeObservable(this)
  }

  @action
  init(instance: FormInstance) {
    this.instance = instance
    this.isInit = true
  }

  areValid() {
    return new Promise((resolve, reject) => {
      this.instance
        ?.validateFields()
        .then(() => {
          this.onValidationSuccess(resolve)
        })
        .catch(errors => {
          // Warning: on some environments, we can reach this 'catch' callback even if the form has no error
          // That is why we need to check errors.errorFields here
          if (errors.errorFields?.length) {
            this.onValidationError(errors)
            reject(errors)
          } else {
            this.onValidationSuccess(resolve)
          }
        })
    })
  }

  private onValidationSuccess(resolve: (value: unknown) => void) {
    this.setValid(true)
    resolve(true)
  }

  private onValidationError(errors: any) {
    const firstField = errors.errorFields[0]
    Message.error('Veuillez corriger les erreurs de saisie', 'formFieldsError')
    this.setValid(false)
    const el = document.getElementById(firstField.name)
    if (el) {
      document.getElementById(firstField.name).focus()
      this.instance.scrollToField(firstField.name, {
        behavior: actions =>
          actions.forEach(({ el, top, left }) => {
            el.scrollTop = top - 100
            el.scrollLeft = left
          })
      })
    }
  }

  @action
  setValid(data: boolean): void {
    this.valid = data
  }

  reset() {
    this.instance.resetFields()
  }
}

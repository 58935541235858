import { message } from 'antd'
import { ConfigOnClose } from 'antd/es/message'
import { SkIconUilBan, SkIconUilCheck, SkIconUilExclamationCircle, SkIconUilInfoCircle } from '@core/global/icons'
import { SkLoader } from '@core/components/loader/skLoader'
import { ReactNode } from 'react'

export const Message = {
  info(content: ReactNode, key?: string, onClose?: ConfigOnClose) {
    message
      .info({
        className: 'message-info',
        key,
        content: (
          <div className="flex items-center" id={`message-info${key ? `-${key}` : ''}`}>
            <div className="pr-3 text-blue-500">
              <SkIconUilInfoCircle />
            </div>
            <div className="text-left">{content}</div>
          </div>
        ),
        onClose,
        onClick: () => Message.destroy(key),
        icon: <></>
      })
      .then()
  },
  success(content: ReactNode, key?: string, onClose?: ConfigOnClose) {
    message
      .success({
        className: 'message-success',
        key,
        content: (
          <div className="flex items-center" id={`message-success${key ? `-${key}` : ''}`}>
            <div className="pr-3 text-green-600">
              <SkIconUilCheck />
            </div>
            <div className="text-left">{content}</div>
          </div>
        ),
        onClose,
        onClick: () => Message.destroy(key),
        icon: <></>
      })
      .then()
  },
  error(content: ReactNode, key?: string, onClose?: ConfigOnClose) {
    message
      .error({
        className: 'message-error',
        key,
        content: (
          <div className="flex items-center" id={`message-error${key ? `-${key}` : ''}`}>
            <div className="pr-3 text-red-300">
              <SkIconUilBan />
            </div>
            <div className="text-left">{content}</div>
          </div>
        ),
        onClose,
        onClick: () => Message.destroy(key),
        icon: <></>
      })
      .then()
  },
  warning(content: ReactNode, key?: string, onClose?: ConfigOnClose) {
    message
      .warning({
        className: 'message-warning',
        key,
        content: (
          <div className="flex items-center" id={`message-warning${key ? `-${key}` : ''}`}>
            <div className="pr-3 text-yellow-500">
              <SkIconUilExclamationCircle />
            </div>
            <div className="text-left">{content}</div>
          </div>
        ),
        onClose,
        onClick: () => Message.destroy(key),
        icon: <></>
      })
      .then()
  },
  loading(content: ReactNode, key: string, onClose?: ConfigOnClose) {
    message
      .loading({
        className: 'message-info',
        key,
        content: (
          <div className="flex items-center" id={`message-loading${key ? `-${key}` : ''}`}>
            <div className="pr-3">
              <SkLoader isLoading />
            </div>
            <div className="text-left">{content}</div>
          </div>
        ),
        onClose,
        onClick: () => Message.destroy(key),
        icon: <></>
      })
      .then()
  },
  destroy(key: string) {
    message.destroy(key)
  }
}

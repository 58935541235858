import * as React from 'react'
import { SkBtn } from '@core/components/btns'
import { SkIconUilCheck, SkIconUilTimes } from '@core/global/icons'
import { useEditContext } from '@core/contexts/editContext'
import classNames from 'classnames'

type IProps = {
  onSubmit: (e: any) => void
  submitLabel?: string
  disabled?: boolean
  bgColor?: 'bg-white' | 'bg-gray-100'
  templateLeft?: React.ReactNode
} & (
  | {
      onCancel: () => void
      hideCancel?: never
    }
  | {
      onCancel?: () => never
      hideCancel: true
    }
)

export function SkModalFooter(props: IProps) {
  const editContext = useEditContext()

  return (
    <div className={classNames('flex justify-center border-t p-3', props.bgColor ?? 'bg-gray-100')}>
      {props.templateLeft}
      {!props.hideCancel && (
        <SkBtn className="min-w-100 mx-3" type="default" onClick={props.onCancel} id="modalCancel" icon={<SkIconUilTimes className="text-red-600" />}>
          Annuler
        </SkBtn>
      )}
      {(!editContext?.store || editContext.store.canEdit) && !props.disabled && (
        <SkBtn className="min-w-100 mx-3" onClick={e => props.onSubmit(e)} submit id="modalSubmit" icon={<SkIconUilCheck />}>
          {props.submitLabel}
        </SkBtn>
      )}
    </div>
  )
}

SkModalFooter.defaultProps = {
  submitLabel: 'Enregistrer'
}

import axios, { AxiosRequestConfig } from 'axios'
import { RequestConfig } from '@core/global/interfaces'
import { Message } from '@core/service/message'
import { coreStore } from '@core/store/coreStore'
import { runInAction } from 'mobx'
import { Tools } from '@core/utils/tools'

export class Http {
  static async $get<T>(url: string, config?: RequestConfig): Promise<T> {
    try {
      const response = await this.createInstance().get<T>(url, this.getConfig(config))
      return response.data
    } catch (e) {
      if (config?.hideGlobalMessageError) {
        throw e
      } else {
        return Promise.reject(e)
      }
    }
  }

  static async $getArrayBuffer<T>(url: string, config?: RequestConfig): Promise<T> {
    config = {
      responseType: 'arraybuffer',
      ...config
    }
    return Http.$get(url, config)
  }

  static async $put<T>(url: string, data: any = {}, config?: RequestConfig): Promise<T> {
    try {
      const response = await this.createInstance().put<T>(url, Tools.cleanData(data), this.getConfig(config))
      return response.data
    } catch (e) {
      if (config?.hideGlobalMessageError) {
        throw e
      } else {
        return Promise.reject(e)
      }
    }
  }

  static async $post<T>(url: string, data: any = {}, config?: RequestConfig): Promise<T> {
    try {
      const response = await this.createInstance().post<T>(url, Tools.cleanData(data), this.getConfig(config))
      return response.data
    } catch (e) {
      if (config?.hideGlobalMessageError) {
        throw e
      } else {
        return Promise.reject(e)
      }
    }
  }

  static async $postMultipart<T>(url: string, formData: FormData, config?: RequestConfig): Promise<T> {
    try {
      if (!config) config = {}
      if (!config.headers) config.headers = {}
      config.headers['Content-type'] = 'multipart/form-data'
      config.headers['Accept'] = '*/*'
      const response = await this.createInstance().post<T>(url, formData, this.getConfig(config))
      return response.data
    } catch (e) {
      if (config?.hideGlobalMessageError) {
        throw e
      } else {
        return Promise.reject(e)
      }
    }
  }

  static async $delete<T>(url: string, config?: RequestConfig): Promise<T> {
    try {
      const response = await this.createInstance().delete<T>(url, this.getConfig(config))
      return response.data
    } catch (e) {
      if (config?.hideGlobalMessageError) {
        throw e
      } else {
        return Promise.reject(e)
      }
    }
  }

  private static getConfig(config: RequestConfig): AxiosRequestConfig {
    const c = {
      ...config,
      paramsSerializer: params => this.transformRequestOptions(params),
      cancelToken: config?.cancelTokenSource?.token
    } as AxiosRequestConfig
    if (config?.hideGlobalMessageError) {
      if (!c.headers) c.headers = {}
      c.headers.SkHideGlobalMessageError = true
    }
    return c
  }

  private static transformRequestOptions = (params: any) => {
    let options = ''
    for (const key in params) {
      if (typeof params[key] !== 'object' && params[key] !== null && params[key] !== '{}') {
        options += `${key}=${encodeURIComponent(params[key])}&`
      } else if (typeof params[key] === 'object' && params[key] && params[key].length) {
        params[key].forEach((el: any) => {
          options += `${key}=${encodeURIComponent(el)}&`
        })
      } else if (typeof params[key] === 'object' && !Array.isArray(params[key])) {
        options += `${key}=${encodeURIComponent(JSON.stringify(params[key]))}&`
      }
    }
    return options ? options.slice(0, -1) : options
  }

  private static createInstance() {
    const instance = axios.create({
      withCredentials: false
    })

    // add interceptor for request
    instance.interceptors.request.use(async request => {
      request.withCredentials = false
      if (!request.headers) request.headers = {}
      if (!request.headers['Accept']) request.headers['Accept'] = 'application/json'
      if (!request.headers['Content-type']) request.headers['Content-type'] = 'application/json'
      request.headers['X-Requested-With'] = 'XMLHttpRequest'
      request.headers['X-Session-Uid'] = coreStore.sessionUid
      if (coreStore.globalHttpHeaders) Object.assign(request.headers, coreStore.globalHttpHeaders)
      return request
    })

    instance.interceptors.response.use(
      response => {
        if (window.location.pathname.startsWith('/admin/') && ['POST', 'PUT', 'PATCH', 'DELETE'].includes(response.config.method.toUpperCase())) {
          runInAction(() => (coreStore.eventStore.whenChangeReferential = new Date()))
        }
        return response
      },
      error => {
        if (axios.isCancel(error)) {
          return Promise.reject(error)
        }

        let content: string
        coreStore.interceptError && coreStore.interceptError(error)
        if (error.response.data instanceof ArrayBuffer) {
          error.response.data = JSON.parse(new TextDecoder().decode(error.response.data))
        }
        if (!error.config?.headers?.SkHideGlobalMessageError) {
          if (error.response && error.response.status) {
            switch (error.response.status) {
              case 400:
                content = error.response.data.message ? error.response.data.message : 'Suppression impossible, au moins une donnée est liée à cet enregistrement'
                break
              case 401:
                content = 'Vos identifiants ne sont pas corrects'
                break
              case 403:
                if (coreStore.meStore?.data) {
                  content = 'Vos droits ne sont pas suffisants, reconnectez-vous pour disposer de nouveaux droits'
                }
                break
              case 502:
              case 504:
                content = 'Mise a jour de l‘application en cours, veuillez réessayer'
                break
              default:
                content = error.response.data.message ? error.response.data.message : 'Une erreur est survenue'
                break
            }
          } else {
            content = 'Une erreur réseau est survenue'
          }
          if (content) Message.error(content)
        }
        return Promise.reject(error)
      }
    )

    return instance
  }
}

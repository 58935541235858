import { AbstractItem } from '@core/models/abstractItem'
import { makeObservable, observable } from 'mobx'
import { ArticleUnit } from '@common/models/articleUnit/articleUnit'

export class ArticleToArticleUnit extends AbstractItem {
  @observable
  unit: ArticleUnit
  @observable
  isDefault: boolean

  constructor(data?: Partial<ArticleToArticleUnit>) {
    super(data)
    makeObservable(this)
    Object.assign(this, data)
    if (this.unit) {
      this.unit = new ArticleUnit(this.unit)
    }
  }
}

function getPropertyNameInternal<T = unknown>(
  expression: (instance: T) => any,
  options: {
    isDeep: boolean
  }
) {
  let propertyThatWasAccessed = ''
  const proxy: any = new Proxy({} as any, {
    get: function (_: any, prop: any) {
      if (options.isDeep) {
        if (propertyThatWasAccessed) propertyThatWasAccessed += '.'

        propertyThatWasAccessed += prop
      } else {
        propertyThatWasAccessed = prop
      }
      return proxy
    }
  })
  expression(proxy)

  return propertyThatWasAccessed
}

function getDeepPropertyName<T = unknown>(expression: (instance: T) => any) {
  return getPropertyNameInternal(expression, {
    isDeep: true
  })
}
export function sorter<T = unknown>(expression: (instance: T) => any) {
  return getDeepPropertyName<T>(expression)
}
export function propertyName<T = unknown>(expression: (instance: T) => any) {
  return getDeepPropertyName<T>(expression)
}

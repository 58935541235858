import { LoadingOutlined } from '@ant-design/icons'
import { Spin } from 'antd'
import React from 'react'
import moment from 'moment'
import 'moment/locale/fr'

export function setDefault() {
  moment.locale('fr')
  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />
  Spin.setDefaultIndicator(antIcon)
}

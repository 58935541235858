import { Document } from '@core/models/document'
import { Photo } from '@common/models/photo/photo'
import { action, computed, makeObservable, observable } from 'mobx'
import { Brand } from '@common/models/brand/brand'
import { Variant } from '@common/models/variant/variant'
import { AbstractLabel } from '@core/models/abstractLabel'
import { ArticleTag } from '@common/models/article/articleTag'
import { ArticleAttribute } from '@common/models/articleAttribute/articleAttribute'
import { ArticleTax } from '@common/models/article/articleTax'
import { ArticleToArticleUnit } from '@common/models/article/articleToArticleUnit'
import { referentialStore } from '@common/models/referential/referentialStore'
import { AbstractUi } from '@core/models/abstractItem'
import { Frequency } from '@common/models/frequency/frequency'
import { Accounting } from '@common/models/accounting/accounting'
import { ArticleUnit } from '@common/models/articleUnit/articleUnit'

export class Article extends AbstractLabel {
  @observable
  description: string
  type: AbstractLabel
  @observable
  articleUnits: ArticleToArticleUnit[]
  @observable
  articleTags: ArticleTag[]
  @observable
  brand: Brand
  @observable
  documents: Document[]
  @observable
  photos: Photo[]
  @observable
  variants: Variant[]
  @observable
  articleTaxes: ArticleTax[]
  @observable
  averageUnitCost: number
  @observable
  isSingleTracked = false
  @observable
  isLotTracked = false
  @observable
  articleAttributes: ArticleAttribute[]
  @observable
  withStockTracking = false
  @observable
  invoiceFrequency: Frequency
  @observable
  guarantee: number
  @observable
  accounting: Accounting
  ui = new Ui()

  constructor(data?: Partial<Article>) {
    super(data)
    makeObservable(this)
    Object.assign(this, data)

    if (this.articleUnits) {
      this.articleUnits.forEach((item, index) => {
        this.articleUnits[index] = new ArticleToArticleUnit(item)
      })
    }
    if (this.articleTags) {
      this.articleTags.forEach((item, index) => {
        this.articleTags[index] = new ArticleTag(item)
      })
    }
    if (this.documents) {
      this.documents.forEach((item, index) => {
        this.documents[index] = new Document(item)
      })
    }
    if (this.photos) {
      this.photos.forEach((item, index) => {
        this.photos[index] = new Photo(item)
      })
      this.photos = this.photos.slice().sort((x, y) => {
        return x.idx - y.idx
      })
    }
    if (this.variants) {
      this.variants = this.variants.filter(x => !x.isDeleted).sort((x, y) => x.id - y.id)
      this.variants.forEach((item, index) => {
        this.variants[index] = new Variant(item)
      })
    }
    if (this.articleAttributes) {
      this.articleAttributes.forEach((item, index) => {
        this.articleAttributes[index] = new ArticleAttribute(item)
      })
      this.articleAttributes = this.articleAttributes.slice().sort((x, y) => x.idx - y.idx)
    }
    if (this.articleTaxes) {
      this.articleTaxes.forEach((item, index) => {
        this.articleTaxes[index] = new ArticleTax(item)
      })
    }
    if (this.invoiceFrequency) this.invoiceFrequency = new Frequency(this.invoiceFrequency)
  }

  @computed
  get isSubscription() {
    return !!this.invoiceFrequency
  }

  @computed
  get variant() {
    return this.variants?.length ? this.variants[0] : null
  }

  @computed
  get isVariantTracked() {
    return this.isSingleTracked || this.isLotTracked
  }

  @computed
  get fullLabel() {
    const labels = []
    labels.push(this.label)
    if (this.brand) labels.push(this.brand.label)
    return labels.join(' ')
  }

  @computed
  get defaultUnit() {
    if (!this.articleUnits?.length) return null
    return this.articleUnits.find(x => x.isDefault) ?? this.articleUnits[0]
  }

  @computed
  get taxesFromTags() {
    if (!this.articleTags?.length || this.articleTaxes?.length || this.ui?.showCustomTaxes) return null
    const returnFirstTaxes = (tagId: number) => {
      const taxes = referentialStore.data.taxes.filter(x => x.taxTags?.some(y => y.tag.id === tagId))
      if (taxes.length) return taxes
      const parentTagId = referentialStore.data.tags.find(x => x.id === tagId)?.parent?.id
      if (parentTagId) return returnFirstTaxes(parentTagId)
      return null
    }
    return returnFirstTaxes(this.articleTags[0].tag.id)
  }

  @computed
  get customTaxes() {
    if (!this.articleTaxes?.length) return null
    return this.articleTaxes.map(x => x.tax)
  }

  @action
  setTaxesInheritFromTag() {
    this.articleTaxes = null
    this.ui.showCustomTaxes = false
  }

  @action
  setTaxesCustom() {
    this.articleTaxes = []
    this.ui.showCustomTaxes = true
  }

  @action
  setArticleUnitDefault = (unit: ArticleUnit) => {
    this.articleUnits.forEach(value => (value.isDefault = false))
    const index = this.articleUnits.findIndex(x => x.unit.id === unit.id)
    this.articleUnits[index].isDefault = true
  }

  @action
  addArticleUnit = (unit: ArticleUnit) => {
    if (!this.articleUnits) this.articleUnits = []
    this.articleUnits.push(new ArticleToArticleUnit({ unit }))
  }

  @action
  removeArticleUnit = (unit: ArticleUnit) => {
    this.articleUnits = this.articleUnits.filter(x => x.unit.id !== unit.id)
    if (this.articleUnits.length === 1) this.articleUnits[0].isDefault = true
  }
}

class Ui extends AbstractUi {
  @observable
  showCustomTaxes = false

  constructor() {
    super()
    makeObservable(this)
  }
}

import * as React from 'react'
import classNames from 'classnames'
import { KeyboardEventHandler } from 'react'
import { useModalContext } from '@core/components/modal/skModal'
import { action } from 'mobx'
import { useIsMobile } from '@core/utils/hooks/useIsMobile'
import { observer } from 'mobx-react-lite'

interface IProps {
  children: React.ReactNode
  className?: string
  bodyBgGray?: boolean
  onKeyDown?: KeyboardEventHandler<any> | undefined
  withFooter: boolean
  styleHeight?: string
}

export const SkModalBody = observer((props: IProps) => {
  const modalContext = useModalContext()
  const isMobile = useIsMobile()

  const getStyle = () => {
    if (props.styleHeight) return { height: props.styleHeight }

    if (!isMobile) {
      if (props.withFooter) {
        if (modalContext.size === 'full') return null
        return { maxHeight: 'calc(var(--vh) - 270px)' }
      }
      return { maxHeight: 'calc(var(--vh) - 230px)' }
    }
    // mobile
    if (props.withFooter) {
      if (modalContext.size === 'full') return { height: 'calc(var(--vh) - 70px)' }
      return { height: 'calc(var(--vh) - 125px)' }
    }
    return { height: 'calc(var(--vh) - 60px)' }
  }

  return (
    <div
      className={classNames('sk-modal-body scrollbar-gray overflow-y-auto', { 'bg-white': !props.bodyBgGray }, { 'bg-gray-100': props.bodyBgGray }, props.className ?? 'p-3')}
      style={getStyle()}
      onKeyDown={props.onKeyDown}
      ref={action(x => {
        if (x) modalContext.modalBodyRef = x
      })}
    >
      {props.children}
    </div>
  )
})

import { VariantTracked } from '@common/models/variantTracked/variantTracked'
import { SkIconUilQrcodeScan } from '@core/global/icons'
import { coreStore } from '@core/store/coreStore'
import { ApiStore } from '@core/api/apiStore'
import { action } from 'mobx'

export class VariantTrackedStore extends ApiStore<VariantTracked> {
  constructor(
    objectNameGenre: 'male' | 'female' = 'male',
    objectName: string = 'Exemplaires',
    itemName: string = 'un exemplaire',
    thisItemName: string = 'cet exemplaire',
    route: string = '/variants-tracked'
  ) {
    super(coreStore.paramAppStore.apiUrl, '/variant-tracked', VariantTracked, objectNameGenre, objectName, itemName, thisItemName, SkIconUilQrcodeScan, route, 'route')
    this.searchTypeLight = 'light'
  }

  @action
  $editVariant = async (variantTracked: VariantTracked) => {
    return await this.$save(variantTracked, { subUrlPath: '/change-article' }, false)
  }

  @action
  $editQuantity = async (variantTracked: VariantTracked) => {
    return await this.$save(variantTracked, { subUrlPath: '/change-quantity' }, false)
  }

  @action
  async $downloadBarcodes(variantTrackeds: VariantTracked[]) {
    await this.$pdf({ subUrlPath: '/barcodes', params: { ids: variantTrackeds.map(x => x.id) } }, false)
  }
}

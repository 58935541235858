import { AbstractPersonContacts } from '@core/models/abstractPersonContacts'
import { computed, observable } from 'mobx'
import { PaymentProfile } from 'skazy-gescom-common/models/paymentProfile/paymentProfile'
import { Price } from 'skazy-gescom-common/models/price/price'

export class AbstractCustomer extends AbstractPersonContacts {
  originalCustomerId: number
  @observable
  paymentProfile: PaymentProfile
  @observable
  additionalInformations: string
  @observable
  occupation: string
  @observable
  acronym: string
  @observable
  companyGroup: string
  @observable
  areItemsUnderCustomsControlAuthorized: boolean
  @observable
  isGeneric: boolean
  @observable
  comment: string
  isMaximumDebitAndMarginExceeded: boolean
  excessDebitAndMargin: Price

  constructor(data?: Partial<AbstractCustomer>) {
    super(data)
    this.originalCustomerId = data?.originalCustomerId
    if (data?.paymentProfile) this.paymentProfile = new PaymentProfile(data.paymentProfile)
    this.additionalInformations = data?.additionalInformations
    this.occupation = data?.occupation
    this.acronym = data?.acronym
    this.companyGroup = data?.companyGroup
    this.areItemsUnderCustomsControlAuthorized = data?.areItemsUnderCustomsControlAuthorized
    this.isGeneric = data?.isGeneric
    this.comment = data?.comment
    this.isMaximumDebitAndMarginExceeded = data?.isMaximumDebitAndMarginExceeded
    this.excessDebitAndMargin = data?.excessDebitAndMargin
  }

  @computed
  get canValidateEstimateWithoutPayment() {
    return !!this.paymentProfile?.invoiceFrequency
  }
}

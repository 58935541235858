import * as React from 'react'
import Head from 'next/head'
import { PropsWithChildren } from 'react'
import { coreStore } from '@core/store/coreStore'

type Props = PropsWithChildren<{
  title?: string
  description?: string
}>

export function SkPage({ title, children }: Props) {
  return (
    <>
      <Head>
        <title>
          {title && `${title} | `} {coreStore.paramAppStore.applicationName}
        </title>
      </Head>
      {children}
    </>
  )
}

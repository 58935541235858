import set from 'lodash/set'
import get from 'lodash/get'

export class Recursive {
  public static setData(object: any, propertyToFilter: string, value: any) {
    const properties = propertyToFilter.split('.')
    if (!object) {
      throw new Error('object need to initialized')
    }
    if (properties.length === 0) {
      throw new Error('propertyToFilter for filter' + object.label + 'unknown')
    } else {
      set(object, propertyToFilter, value)
    }
  }

  public static getData(object: any, propertyToFilter: string) {
    return get(object, propertyToFilter)
  }
}

import { makeAutoObservable, runInAction } from 'mobx'
import { Spin, notification } from 'antd'
import React from 'react'
import { SkIconUilCheck, SkIconUilTimes } from '@core/global/icons'
import classNames from 'classnames'
import moment from 'moment'
import { Tools } from '@core/utils/tools'

export class EventStore {
  storeModified: any = {}
  eventAction: any = {}
  actionsInProgress: ActionInProgress[] = []
  whenChangeUser: Date
  whenChangeReferential: Date
  withEventSource: boolean
  notificationIsOpen: boolean

  private readonly notificationKey = 'actionsInProgress'
  private keepAliveTimer = null
  private event: EventSource

  constructor() {
    makeAutoObservable(this)
  }

  connectEventSourceIfNotConnected = (onMessage: (action: ActionInProgress) => void) => {
    if (!this.event) this.connectEventSource(onMessage)
  }

  addActionInProgress(action: ActionInProgress) {
    this.actionsInProgress.push(action)
    this.openAndUpdateNotificationActionsInProgress()
  }

  openAndUpdateNotificationActionsInProgress() {
    this.notificationIsOpen = true
    notification.info({
      key: this.notificationKey,
      placement: 'bottomLeft',
      closeIcon: <SkIconUilTimes />,
      message: 'Actions en cours',
      className: 'sk-notification',
      description: (
        <>
          {this.actionsInProgress.map((x, index) => {
            return (
              <>
                <div key={x.uuid} className={classNames('flex items-center justify-between py-2', { ' border-t': index > 0 })}>
                  <span className="pr-2">{x.label}</span>
                  <div className="flex-center">
                    {Tools.isNullOrUndefined(x.success) ? (
                      <Spin size="small" />
                    ) : x.success ? (
                      <span className="text-green-700">
                        <SkIconUilCheck />
                      </span>
                    ) : (
                      <span className="text-red-600">
                        <SkIconUilTimes />
                      </span>
                    )}
                    <div className="text-muted pl-3 text-xs">{x.date.format('HH:MM')}</div>
                  </div>
                </div>
                <div className="text-xs text-red-600">{x.message}</div>
              </>
            )
          })}
        </>
      ),
      duration: null,
      onClose: () => {
        this.notificationIsOpen = false
      }
    })
  }

  removeActionInProgress(action: ActionInProgress) {
    setTimeout(() => {
      runInAction(() => {
        this.actionsInProgress = this.actionsInProgress.filter(x => x.uuid !== action.uuid)
        if (this.actionsInProgress.length === 0) notification.close(this.notificationKey)
      })
    }, 2000)
  }

  handleKeepAlive = (keepAliveSecs: number, onMessage: (action: ActionInProgress) => void) => {
    this.clearKeepAlive()
    this.keepAliveTimer = setTimeout(() => this.connectEventSource(onMessage), keepAliveSecs * 1000)
  }

  connectEventSource(onMessage: (action: ActionInProgress) => void) {
    if (this.event) {
      this.event.close()
      this.actionsInProgress = []
      this.notificationIsOpen = false
    }

    this.event = new EventSource('/api/events', { withCredentials: true })

    this.event.onmessage = (e: { data: string }) => {
      this.handleKeepAlive(20, onMessage)
      const actionInProgress = JSON.parse(e.data) as ActionInProgress
      onMessage(actionInProgress)
    }

    this.event.onopen = () => {
      console.log('eventSource onopen')
      this.handleKeepAlive(20, onMessage)
    }

    this.event.onerror = () => {
      console.log('eventSource onerror')
      this.handleKeepAlive(5, onMessage)
    }
  }

  clearKeepAlive = () => {
    if (this.keepAliveTimer != null) clearTimeout(this.keepAliveTimer)
  }
}

export class ActionInProgress {
  uuid: string
  label: string
  date: moment.Moment
  success: boolean
  message: string
  topic: string
  onSuccess: () => void
  onError: () => void
  type: {
    id: number
    label: string
    name: string
  }
  map: unknown
  constructor(data?: Partial<ActionInProgress>) {
    Object.assign(this, data)
    makeAutoObservable(this)
  }
}

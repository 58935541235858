import { AbstractItem } from './abstractItem'
import { Tools } from '@core/utils/tools'
import { action, computed, observable } from 'mobx'

export class AbstractLabel<T = number> extends AbstractItem<T> {
  @observable
  code?: string
  @observable
  label: string
  @observable
  isDefault?: boolean

  constructor(data: Partial<AbstractLabel<T>> = null) {
    super(data)
    this.code = data?.code
    this.label = data?.label
    this.isDefault = data?.isDefault
  }

  @computed
  get tidyLabel() {
    return Tools.accentsTidy(this.label)
  }

  @action
  generateCodeByLabel = () => {
    this.code = Tools.slugify(this.label)
  }
}

import { action, makeObservable, observable } from 'mobx'
import React from 'react'
import { UilExchange, UilRedo, UilShop, UilTimesSquare, UilTrashAlt, UilTruck } from '@iconscout/react-unicons'
import { CodeBundleEnum, CodePermissionEnum, WarehousesActionTypeEnum } from '@common/global/enums'
import {
  SkIconUilArchive,
  SkIconUilArrowRandom,
  SkIconUilArrowToRight,
  SkIconUilPlusSquare,
  SkIconUilShoppingCart,
  SkIconUilTruckLoading,
  SkIconUilUserPlus,
  SkIconUilUserSquare
} from '@core/global/icons'
import { WarehouseAction } from '@common/models/warehouseAction/warehouseAction'
import { QueryModalStore } from '@core/store/queryModalStore'
import { coreStore } from '@core/store/coreStore'
import { referentialStore } from '@common/models/referential/referentialStore'

export enum ModalTypeEnum {
  skEstimate,
  skTransfertsShipments,
  skTransfertsReceipts,
  skTransfertsRegulations,
  skTransfertsAddInStock,
  skTransfertsReturnInStock,
  skTransfertsStock,
  skTransfertsToSeller,
  skTransfertsReplenishment,
  skTransfertsReconditioned,
  skTransfertsScrap,
  skTransfertsDeletions,
  skTransfertsDestructions,
  skEstimateFullModalEdit
}

export class ShortcutsStore extends QueryModalStore<any> {
  @observable
  skTransfertsReceiptsModalPrevious: WarehouseAction
  @observable
  modalTypeEditIsOpen = {}

  constructor() {
    super()
    makeObservable(this)
  }

  @action
  load = () => {
    this.items = []
    if (coreStore.meStore.hasPermission(CodePermissionEnum.actionAssignToClient))
      if (referentialStore.data.tenantProperties?.hasBundle(CodeBundleEnum.invoice)) {
        this.items.push({
          id: CodePermissionEnum.actionAssignToClient,
          label: 'Créer un devis',
          icon: <SkIconUilShoppingCart />,
          onClick: () => this.toggleModal(ModalTypeEnum.skEstimateFullModalEdit)
        })
      } else {
        this.items.push({
          id: CodePermissionEnum.actionAssignToClient,
          label: 'Affecter à un client',
          icon: <SkIconUilUserPlus />,
          onClick: () => this.toggleModal(ModalTypeEnum.skEstimate)
        })
      }

    if (coreStore.meStore.hasPermission(CodePermissionEnum.actionPayback))
      this.items.push({
        id: CodePermissionEnum.actionPayback,
        label: 'Retourner',
        icon: <SkIconUilArchive />,
        canBeAfterThisActionId: WarehousesActionTypeEnum.sale,
        onClick: () => this.toggleModal(ModalTypeEnum.skTransfertsReturnInStock)
      })

    if (coreStore.meStore.hasPermission(CodePermissionEnum.actionAddInStock))
      this.items.push({
        id: CodePermissionEnum.actionAddInStock,
        label: 'Ajouter en stock',
        icon: <SkIconUilPlusSquare />,
        onClick: () => this.toggleModal(ModalTypeEnum.skTransfertsAddInStock)
      })

    if (coreStore.meStore.hasPermission(CodePermissionEnum.actionChangeStockTrackingQuantity))
      this.items.push({
        id: CodePermissionEnum.actionChangeStockTrackingQuantity,
        label: "Modifier la quantité d'un lot",
        icon: <SkIconUilPlusSquare />,
        onClick: () => this.toggleModal(ModalTypeEnum.skTransfertsAddInStock)
      })

    if (coreStore.meStore.hasPermission(CodePermissionEnum.actionTransfer))
      this.items.push({
        id: CodePermissionEnum.actionTransfer,
        label: 'Transférer',
        icon: <SkIconUilArrowToRight />,
        onClick: () => this.toggleModal(ModalTypeEnum.skTransfertsStock)
      })

    if (coreStore.meStore.hasPermission(CodePermissionEnum.actionAssignToReseller))
      this.items.push({
        id: CodePermissionEnum.actionAssignToReseller,
        label: 'Affecter à un revendeur',
        icon: <SkIconUilUserSquare />,
        onClick: () => this.toggleModal(ModalTypeEnum.skTransfertsToSeller)
      })

    if (coreStore.meStore.hasPermission(CodePermissionEnum.actionReceiveShipment))
      this.items.push({
        id: CodePermissionEnum.actionReceiveShipment,
        label: 'Réceptionner une expédition',
        icon: <SkIconUilTruckLoading />,
        canBeAfterThisActionId: WarehousesActionTypeEnum.shipping,
        onClick: () => this.toggleModal(ModalTypeEnum.skTransfertsReceipts)
      })

    if (coreStore.meStore.hasPermission(CodePermissionEnum.actionShip))
      this.items.push({
        id: CodePermissionEnum.actionShip,
        label: 'Expédier',
        icon: <SkIconUilArrowRandom />,
        onClick: () => this.toggleModal(ModalTypeEnum.skTransfertsShipments)
      })

    if (coreStore.meStore.hasPermission(CodePermissionEnum.actionRepackage)) {
      this.items.push({
        id: CodePermissionEnum.actionRepackage,
        label: 'Reconditionner',
        icon: <UilRedo />,
        onClick: () => this.toggleModal(ModalTypeEnum.skTransfertsReconditioned)
      })
    }

    if (coreStore.meStore.hasPermission(CodePermissionEnum.actionScrap)) {
      this.items.push({
        id: CodePermissionEnum.actionScrap,
        label: 'Mettre au rebut',
        icon: <UilTimesSquare />,
        onClick: () => this.toggleModal(ModalTypeEnum.skTransfertsScrap)
      })
    }

    if (coreStore.meStore.hasPermission(CodePermissionEnum.actionRemoval))
      this.items.push({
        id: CodePermissionEnum.actionRemoval,
        label: 'Organiser l‘enlèvement',
        icon: <UilTruck />,
        onClick: () => this.toggleModal(ModalTypeEnum.skTransfertsDestructions)
      })

    if (coreStore.meStore.hasPermission(CodePermissionEnum.actionDelete))
      this.items.push({ id: CodePermissionEnum.actionDelete, label: 'Supprimer', icon: <UilTrashAlt />, onClick: () => this.toggleModal(ModalTypeEnum.skTransfertsDeletions) })

    if (referentialStore.data?.tenantProperties?.withSocalogInterface) {
      if (coreStore.meStore.hasPermission(CodePermissionEnum.actionAddInStockSocalog)) {
        this.items.push({
          id: CodePermissionEnum.actionAddInStockSocalog,
          label: 'Ajouter en stock chez Socalog',
          icon: <UilShop />,
          onClick: () => this.toggleModal(ModalTypeEnum.skTransfertsRegulations)
        })
      }
      if (coreStore.meStore.hasPermission(CodePermissionEnum.actionRequestReplenishmentSocalog)) {
        this.items.push({
          id: CodePermissionEnum.actionRequestReplenishmentSocalog,
          label: 'Demander un réappro Socalog',
          icon: <UilExchange />,
          onClick: () => this.toggleModal(ModalTypeEnum.skTransfertsReplenishment)
        })
      }
    }
  }

  @action
  openModal = () => {
    this.modalIsOpen = true
    this.queryActions(null)
  }

  @action
  closeModal = () => {
    this.modalIsOpen = false
  }

  @action
  queryActions = (terms: string) => {
    if (terms?.length) {
      const data = []
      this.items.forEach(x => {
        if (x.label.toLowerCase().includes(terms.toLowerCase())) {
          data.push(x)
        }
      })
      this.itemsFiltered = data
      this.indexSelected = 0
    } else {
      this.itemsFiltered = this.items
    }
    this.itemsFiltered = this.itemsFiltered.sort((a, b) => a.label.toString().localeCompare(b.label.toString()))
    if (terms?.length && this.itemsFiltered.length) {
      this.indexSelected = 0
    }
  }

  @action
  toggleModalSkTransfertsReceiptsIsOpen = (previous?: WarehouseAction) => {
    this.skTransfertsReceiptsModalPrevious = previous
    this.toggleModal(ModalTypeEnum.skTransfertsReceipts)
  }

  @action
  toggleModal = (modalType: ModalTypeEnum) => {
    this.closeModal()
    this.modalTypeEditIsOpen[modalType] = !this.modalTypeEditIsOpen[modalType]
  }
}

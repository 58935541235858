import { AbstractItem } from '@core/models/abstractItem'
import { Tag } from '@common/models/tag/tag'
import { makeObservable } from 'mobx'

export class TaxTag extends AbstractItem {
  tag: Tag

  constructor(data?: Partial<TaxTag>) {
    super(data)
    makeObservable(this)
    Object.assign(this, data)
    if (this.tag) {
      this.tag = new Tag(this.tag)
    }
  }
}

import React from 'react'
import SkEmptyLayout from '@/layouts/empty/skEmptyLayout'
import SkDefaultLayout from '@/layouts/default/skDefaultLayout'
import SkAdminLayout from '@/layouts/skAdminLayout'

const layouts = {
  empty: SkEmptyLayout,
  default: SkDefaultLayout,
  admin: SkAdminLayout
}

export function withLayoutAdmin(page) {
  page.layout = 'admin'
  return page
}

export default function SkLayoutWrapper(props: { children }) {
  const Layout = layouts[props.children.type.layout]
  if (Layout != null) {
    return <Layout {...props}>{props.children}</Layout>
  }
  return <SkDefaultLayout {...props}>{props.children}</SkDefaultLayout>
}

import { referentialStore } from '@common/models/referential/referentialStore'
import { makeObservable } from 'mobx'
import { PersonTypeEnum } from '@core/global/enums'
import { AbstractPersonContacts } from '@core/models/abstractPersonContacts'

export class Supplier extends AbstractPersonContacts {
  constructor(data?: Partial<Supplier>) {
    super(data)
    makeObservable(this)
    if (!this.id) {
      this.personType = referentialStore.data.personTypes.find(x => x.id === PersonTypeEnum.company)
    }
  }
}

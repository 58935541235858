import { ApiStore } from '@core/api/apiStore'
import * as React from 'react'
import { useObjectMemo } from '@core/utils/hooks'
import { AbstractItem } from '@core/models/abstractItem'

export function useStoreContext<T extends AbstractItem>() {
  return React.useContext<ApiStore<T>>(SkStoreContext)
}

const SkStoreContext = React.createContext<ApiStore<any>>(null)

export const SkStoreProvider = ({ store, children }: { store: ApiStore<any>; children: React.ReactNode }) => {
  const contextValue = useObjectMemo(store)
  return <SkStoreContext.Provider value={contextValue}>{children}</SkStoreContext.Provider>
}

export class FileTools {
  public static download(blobPart: any, type: string, filename: string, extension: string) {
    const blob: Blob = new Blob([blobPart], { type })
    const fileName = filename + '.' + extension
    const objectUrl: string = URL.createObjectURL(blob)
    const a: HTMLAnchorElement = document.createElement('a') as HTMLAnchorElement

    a.href = objectUrl
    a.download = fileName
    document.body.appendChild(a)
    a.click()

    document.body.removeChild(a)
    URL.revokeObjectURL(objectUrl)
  }

  public static downloadPng(blobPart: any, filename: string) {
    FileTools.download(blobPart, 'image/png', filename, 'png')
  }

  public static downloadPdf(blobPart: any, filename: string) {
    FileTools.download(blobPart, 'application/pdf', filename, 'pdf')
  }

  public static downloadCsv(blobPart: any, filename: string) {
    FileTools.download(blobPart, 'text/csv', filename, 'csv')
  }

  public static downloadByUrl(url: string, fileName: string) {
    const a = document.createElement('a')
    a.href = url
    a.download = fileName
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)
  }
}

import { makeObservable, observable } from 'mobx'
import { AbstractItem } from '@core/models/abstractItem'
import { Tag } from '@common/models/tag/tag'

export class PackageGroupTag extends AbstractItem {
  idx: number
  tag: Tag
  @observable
  isQuantityFromParent: boolean

  constructor(data?: Partial<PackageGroupTag>) {
    super(data)
    makeObservable(this)
    Object.assign(this, data)
    if (this.tag) {
      this.tag = new Tag(this.tag)
    }
  }
}

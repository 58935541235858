import React from 'react'
import { SkIconUilPlus } from '@core/global/icons'
import { SkBtn } from '@core/components/btns'
import { BtnProps } from '@core/components/btns/skBtn'

SkBtnAdd.defaultProps = {
  size: 'large',
  type: 'primary'
}

export function SkBtnAdd(props: BtnProps) {
  return (
    <>
      {!props.linkTo && (
        <SkBtn {...props} icon={<SkIconUilPlus />}>
          {props.children}
        </SkBtn>
      )}
    </>
  )
}

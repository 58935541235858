import { makeObservable, observable } from 'mobx'
import { VariantTracked } from '@common/models/variantTracked/variantTracked'
import { Variant } from '@common/models/variant/variant'
import { AbstractItem, AbstractUi } from '@core/models/abstractItem'
import { AbstractLabel } from '@core/models/abstractLabel'
import { ArticleUnit } from '@common/models/articleUnit/articleUnit'

export class WarehouseActionLine extends AbstractItem {
  @observable
  variant: Variant
  @observable
  variantTracked: VariantTracked
  @observable
  quantity: number
  @observable
  variantTrackedState?: AbstractLabel
  warehouseActionId: number
  @observable
  isComplete: boolean
  @observable
  comment: string
  declare ui: Ui
  @observable
  unit: ArticleUnit

  constructor(data?: Partial<WarehouseActionLine>) {
    super(data)
    makeObservable(this)
    Object.assign(this, data)
    if (this.variantTrackedState) {
      this.variantTrackedState = new AbstractLabel(this.variantTrackedState)
    }
    if (this.variant) {
      this.variant = new Variant(this.variant)
    }
    if (this.variantTracked) {
      if (!this.variant && this.variantTracked.variant) this.variant = new Variant(this.variantTracked.variant)
      this.variantTracked = new VariantTracked(this.variantTracked)
    }
    if (this.isComplete === undefined) {
      this.isComplete = true
    }
    if (this.unit) this.unit = new ArticleUnit(this.unit)
    this.ui = new Ui()
  }
}

class Ui extends AbstractUi {
  canEditQuantity = true
  @observable
  totalPriceIsChanging = false
  constructor() {
    super()
    makeObservable(this)
  }
}

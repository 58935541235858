import { Input } from 'antd'
import * as React from 'react'
import { useEffect } from 'react'
import { SizeType } from 'antd/es/config-provider/SizeContext'
import { WithFormItem } from '@core/components/form'
import { useFormContext } from '@core/contexts/formContext'
import { IPropsInput, InitInput } from '@core/utils/initInput'
import { ListenInput } from '@core/utils/listenInput'
import { Tools } from '@core/utils/tools'
import classNames from 'classnames'
import { useDebouncedCallback } from '@core/utils/hooks/useDebouncedCallback'

type IProps<T, Y> = IPropsInput<T, Y> & {
  placeholder?: string
  type?: string
  size?: SizeType
  maxLength?: number
  addonBefore?: React.ReactNode
  addonAfter?: React.ReactNode
  prefix?: React.ReactNode
  suffix?: React.ReactNode
  autoFocus?: boolean
  className?: string
  autoCapitalize?: boolean
  autoUppercase?: boolean
  onPressEnter?: () => void
  onBlur?: () => void
  onFocus?: () => void
  validateTrigger?: 'onChange' | 'onBlur'
  withValidationMessage?: boolean
  required?: boolean
  autoComplete?: string
  bordered?: boolean
  slugify?: boolean
} & (
    | {
        debounce?: number
        onDebounceChange: (newValue: string) => void
      }
    | {
        debounce?: never
        onDebounceChange?: (newValue: string) => never
      }
  )

SkText.defaultProps = {
  autoCapitalize: true,
  maxLength: 255
}

export function SkText<T>(props: IProps<T, string>) {
  const { id } = InitInput(props, 'SkText')
  const formContext = useFormContext()
  const { onChangeInput, value, setFieldsValue } = ListenInput(props, id)

  const capitalize = (s: string) => {
    return s.charAt(0).toUpperCase() + s.slice(1)
  }

  const onDebounceChange = useDebouncedCallback(
    data => {
      props.onDebounceChange(data)
    },
    [],
    props.debounce ?? 500
  )

  const onChangeInputText = (eventOrValue: any) => {
    let value = eventOrValue.target ? eventOrValue.target.value : eventOrValue
    if (value) {
      if (props.slugify) {
        value = Tools.slugify(value)
      } else if (props.autoUppercase) {
        value = value.toUpperCase()
      } else if (props.autoCapitalize) {
        value = capitalize(value)
      }
    }
    onChangeInput(value)
    if (props.onDebounceChange) onDebounceChange(value)
  }

  useEffect(() => {
    setFieldsValue(value)
  }, [value])

  return (
    <WithFormItem<T> {...props} id={id} value={value}>
      <Input
        type={props.type}
        data-id={props.dataId}
        bordered={props.bordered}
        className={classNames(props.className)}
        value={value}
        size={props.size}
        placeholder={props.placeholder}
        onChange={onChangeInputText}
        onPressEnter={props.onPressEnter}
        onFocus={props.onFocus}
        disabled={props.disabled || formContext.props.disabled}
        addonAfter={props.addonAfter}
        addonBefore={props.addonBefore}
        autoFocus={props.autoFocus}
        prefix={props.prefix}
        suffix={props.suffix}
        autoComplete={props.autoComplete ?? id}
        onBlur={props.onBlur}
        maxLength={props.maxLength}
      />
    </WithFormItem>
  )
}

import { Referential } from '@common/models/referential/referential'
import { IReactionDisposer, action, makeAutoObservable, reaction, runInAction } from 'mobx'
import { Http } from '@core/api/http'
import { coreStore } from '@core/store/coreStore'
import { ActionInProgress } from '@core/store/eventStore'

export class ReferentialStore {
  isLoading = false
  data: Referential
  private disposerWhenChangeReferential: IReactionDisposer

  constructor() {
    makeAutoObservable(this)
  }

  $get(onMessage?: (action: ActionInProgress) => void): Promise<Referential> {
    if (this.disposerWhenChangeReferential) this.disposerWhenChangeReferential()
    return new Promise<Referential>((resolve, reject) => {
      this.isLoading = true
      Http.$get<Referential>('/api/referential')
        .then(data => {
          runInAction(() => {
            this.data = new Referential(data)
            if (onMessage && coreStore.eventStore.withEventSource && this.data.tenantProperties) coreStore.eventStore.connectEventSourceIfNotConnected(onMessage)
            this.disposerWhenChangeReferential = reaction(
              () => coreStore.eventStore.whenChangeReferential,
              async () => {
                await this.$get(onMessage)
              }
            )
          })
          resolve(data)
        })
        .catch(() => reject('get referential with error'))
        .finally(
          action(() => {
            this.isLoading = false
          })
        )
    })
  }
}

export const referentialStore = new ReferentialStore()

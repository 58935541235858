import { AbstractLabel } from '@core/models/abstractLabel'
import { FrequencyDayEnum, FrequencyEnum } from '@common/global/enums'
import { computed, makeObservable, observable } from 'mobx'
import { AbstractItem } from '@core/models/abstractItem'
import moment from 'moment'

export class Frequency extends AbstractItem {
  multiple: number
  @observable
  period: AbstractLabel<FrequencyEnum>
  @observable
  day: AbstractLabel<FrequencyDayEnum>
  @observable
  date: Date

  constructor(data?: Partial<Frequency>) {
    super(data)
    Object.assign(this, data)
    makeObservable(this)
    if (!this.multiple) this.multiple = 1
    if (!this.period) this.period = new AbstractLabel({ id: FrequencyEnum.month })
    if (!this.day && !this.date) this.day = new AbstractLabel({ id: FrequencyDayEnum.monthLastDay })
    if (this.date) this.date = new Date(this.date)
  }

  getFrequencyType() {
    if (this.period.code === 'MONTH') {
      if (this.multiple === 1) return 'Tous les mois'
      return `Tous les ${this.multiple} mois`
    }
    if (this.period.code === 'YEAR') {
      if (this.multiple === 1) return 'Tous les ans'
      return `Tous les ${this.multiple} ans`
    }
    return this.multiple + ' ' + this.period.label
  }

  @computed
  get frequencyLabel() {
    if (this.period.code === 'MONTH') {
      if (this.day.id === FrequencyDayEnum.monthFirstDay) {
        if (this.multiple === 1) return 'Tous les débuts de mois'
        return `Tous les ${this.multiple} mois en début de mois`
      } else if (this.day.id === FrequencyDayEnum.monthLastDay) {
        if (this.multiple === 1) return 'Tous les fins de mois'
        return `Tous les ${this.multiple} mois en fin de mois`
      } else if (this.day.id === FrequencyDayEnum.custom) {
        if (this.multiple === 1) return `Tous les ${this.date?.getDate() ?? '-'} du mois`
        return `Tous les ${this.multiple} mois le ${this.date?.getDate() ?? '-'}`
      }
    } else if (this.period.code === 'YEAR') {
      return `Le ${this.date ? moment(this.date).format('DD MMMM') : '-'}`
    }
    return '-'
  }
}

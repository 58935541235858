import { Modal } from 'antd'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import React, { ReactNode } from 'react'

const { confirm, error } = Modal

export class ModalService {
  static showDeleteConfirm(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      confirm({
        title: 'Confirmer votre suppression',
        icon: <ExclamationCircleOutlined />,
        okText: <span id="SkConfirmDelete">Supprimer</span>,
        cancelText: 'Annuler',
        onOk() {
          resolve(true)
        },
        onCancel() {
          reject()
        }
      })
    })
  }

  static showConfirm(title: ReactNode, okText: string): Promise<boolean> {
    return new Promise((resolve, reject) => {
      confirm({
        title: title,
        okButtonProps: {
          id: 'skConfirm'
        },
        icon: <ExclamationCircleOutlined />,
        okText: okText,
        cancelText: 'Annuler',
        onOk() {
          resolve(true)
        },
        onCancel() {
          reject()
        }
      })
    })
  }

  static showError(title: ReactNode) {
    error({
      title: title
    })
  }
}

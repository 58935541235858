import * as React from 'react'
import { useEffect } from 'react'
import InputMask from 'react-input-mask'
import { Input } from 'antd'
import { SizeType } from 'antd/es/config-provider/SizeContext'
import { WithFormItem } from '@core/components/form'
import { runInAction } from 'mobx'
import { Recursive } from '@core/utils/recursive'
import { propertyName } from '@core/utils/nameOf'
import { useFormContext } from '@core/contexts/formContext'
import { IPropsInput, InitInput } from '@core/utils/initInput'
import { ListenInput } from '@core/utils/listenInput'

type IProps<T, Y> = IPropsInput<T, Y> & {
  mask: string
  onChange?: (value: any) => void
  size?: SizeType
  onPressEnter?: () => void
  required?: boolean
}

export function SkMask<T>(props: IProps<T, string>) {
  const { id } = InitInput(props, 'SkMask')
  const formContext = useFormContext()
  const { onChangeInput, value, setFieldsValue } = ListenInput(props, id)

  const onChange = (eventOrValue: any) => {
    const tempValue = eventOrValue && typeof eventOrValue === 'object' && eventOrValue.target ? eventOrValue.target.value : eventOrValue
    if (tempValue !== null && tempValue !== undefined && (tempValue.indexOf('_') > -1 || tempValue.length === 0)) {
      if (value) {
        setValueAndOnChange(null)
      }
      return
    }
    setValueAndOnChange(tempValue)
  }

  const setValueAndOnChange = (value: any) => {
    runInAction(() => {
      onChangeInput(value)
      if (props.property) Recursive.setData(props.model, propertyName(props.property), value ?? null)
      props.onChange?.(value)
    })
  }

  useEffect(() => {
    setFieldsValue(value)
  }, [value])

  let isTouched = false
  return (
    <WithFormItem
      {...props}
      id={id}
      value={value}
      rules={
        props.rules && [
          { validator: (_, value) => (isTouched && value && value.indexOf('_') > -1 ? Promise.reject('Le format ne correspond pas') : Promise.resolve()) },
          ...props.rules
        ]
      }
    >
      <InputMask
        mask={props.mask}
        value={props.value}
        onChange={onChange}
        disabled={props.disabled || formContext.props.disabled}
        autoComplete="off"
        onBlur={() => (isTouched = true)}
      >
        <Input onPressEnter={props.onPressEnter} size={props.size} />
      </InputMask>
    </WithFormItem>
  )
}

import { LocalStorage } from '@core/utils/localStorage'

export class Context {
  public static setIsUserSimulation() {
    LocalStorage.setItem('isUserSimulation', true)
  }

  public static removeIsUserSimulation() {
    LocalStorage.removeItem('isUserSimulation')
  }

  public static isUserSimulation(): boolean {
    return !!LocalStorage.getItem('isUserSimulation')
  }
}

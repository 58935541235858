import { ActionInProgress } from '@core/store/eventStore'
import { coreStore } from '@core/store/coreStore'
import { Message } from '@core/service/message'
import { runInAction } from 'mobx'
import { rootStore } from '@/stores/rootStore'
import { Actuator } from '@common/models/actuator/actuator'

enum WebSocketActionEnum {
  warehouseAction = 1,
  reloadStat = 3
}

export class EventStore {
  whenChangeStock = () => {
    return coreStore.eventStore.eventAction[WebSocketActionEnum.warehouseAction]
  }

  whenReloadStat = () => {
    return coreStore.eventStore.eventAction[WebSocketActionEnum.reloadStat]
  }

  onMessage = (action: ActionInProgress) => {
    if (action.topic === 'action' || action.topic === 'global') {
      this.onActionResponse(action)
    } else if (action.topic === 'stats') {
      this.onStatResponse(action)
    } else if (action.topic === 'actuatorInfo') {
      this.onActuatorInfo(action)
    }
  }

  protected onActionResponse = (action: ActionInProgress) => {
    if (action.type && action.success) {
      runInAction(() => (coreStore.eventStore.eventAction[action.type.id] = new Date()))
    }
    if (action.uuid) {
      const actionInProgress = coreStore.eventStore.actionsInProgress.find(x => x.uuid === action.uuid)
      if (actionInProgress)
        runInAction(() => {
          actionInProgress.success = action.success
          actionInProgress.message = action.message
          if (actionInProgress.success) {
            if (actionInProgress.onSuccess) actionInProgress.onSuccess()
            Message.destroy(action.uuid)
          } else {
            if (actionInProgress.onError) actionInProgress.onError()
            if (actionInProgress.message) Message.error(`${actionInProgress.message}`, action.uuid)
          }
          if (coreStore.eventStore.notificationIsOpen) coreStore.eventStore.openAndUpdateNotificationActionsInProgress()
          coreStore.eventStore.removeActionInProgress(actionInProgress)
        })
    }
  }

  protected onStatResponse = (action: ActionInProgress) => {
    runInAction(() => (coreStore.eventStore.eventAction[action.type.id] = new Date()))
  }

  protected onActuatorInfo = (action: ActionInProgress) => {
    rootStore.actuatorStore.checkLastCommitTimeAndReloadIfDiff(action.map as Actuator)
  }
}

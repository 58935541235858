import { AbstractLabel } from '@core/models/abstractLabel'
import { makeObservable } from 'mobx'

export class ZipCode extends AbstractLabel {
  constructor(data?: Partial<ZipCode>) {
    super(data)
    makeObservable(this)
    Object.assign(this, data)
  }
}

import React from 'react'
import { SkDatePicker } from '@core/components'

interface IProps {
  id: string
  label: string
  value: Date
  onChange: (value: Date) => void
}

export function SkFilterDatePicker(props: IProps) {
  return (
    <div className="w-full filter">
      <SkDatePicker label={props.label} value={props.value} onChange={props.onChange} id={props.id} size="large" />
    </div>
  )
}

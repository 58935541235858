import { makeObservable, observable } from 'mobx'
import { WarehouseAction } from '@common/models/warehouseAction/warehouseAction'
import moment from 'moment'
import { Warehouse } from '@common/models/warehouse/warehouse'
import { AbstractItem } from '@core/models/abstractItem'
import { VariantTracked } from '@common/models/variantTracked/variantTracked'

export class StockTracking extends AbstractItem {
  @observable
  action: WarehouseAction
  @observable
  quantity: number
  @observable
  date: moment.Moment
  @observable
  warehouse: Warehouse
  @observable
  variantTracked?: VariantTracked

  constructor(data?: Partial<StockTracking>) {
    super(data)
    makeObservable(this)
    Object.assign(this, data)
    if (this.action) {
      this.action = new WarehouseAction(this.action)
    }
    if (this.date) {
      this.date = moment(this.date)
    }
  }
}

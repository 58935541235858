import { AbstractLabel } from '@core/models/abstractLabel'
import { makeAutoObservable, makeObservable, observable } from 'mobx'
import { CodeBundleEnum } from '@common/global/enums'
import { Document } from '@core/models/document'

export class Tenant extends AbstractLabel {
  @observable
  schema: string
  @observable
  contexts: string
  @observable
  isSocalogFtpEnabled?: boolean
  @observable
  withSocalogInterface?: boolean
  @observable
  socalogFileExtension?: string
  @observable
  withDocuwareInterface?: boolean
  @observable
  docuwareMountFolder?: string
  @observable
  withSmileApi?: boolean
  @observable
  smileApiUrl?: string
  @observable
  defaultCurrency?: AbstractLabel
  @observable
  mailProperties = new TenantMailProperties()
  @observable
  applicationProperties = new TenantApplicationProperties()
  @observable
  ldapProperties = new TenantLdapProperties()
  @observable
  articleProperties = new TenantArticleProperties()
  @observable
  saleProperties = new TenantSaleProperties()
  @observable
  personProperties = new TenantPersonProperties()
  @observable
  authenticationType?: AbstractLabel
  @observable
  bundles?: AbstractLabel[]
  @observable
  locationReferentials?: AbstractLabel[]
  @observable
  tenantPaymentMethodProperties = new TenantPaymentMethodProperties()
  @observable
  tenantPaymentProvider = new TenantPaymentProvider()
  @observable
  captchaProperties = new TenantCaptchaProperties()
  @observable
  barcodeProperties = new TenantBarcodeProperties()
  @observable
  fieldNamingProperties = new TenantFieldNamingProperties()
  @observable
  accountingProperties = new TenantAccountingProperties()
  @observable
  allowSignup?: boolean

  constructor(data?: Partial<Tenant>) {
    super(data)
    makeObservable(this)
    Object.assign(this, data)
  }
}

export class TenantMailProperties {
  host?: string
  port?: number
  username?: string
  password?: string
  auth?: boolean
  startTls?: boolean
  from?: string
  replyTo?: string
  sendingInterval?: number

  constructor(data?: Partial<TenantMailProperties>) {
    makeAutoObservable(this)
    Object.assign(this, data)
  }
}

export class TenantApplicationProperties {
  host?: string
  clientHost?: string
  name?: string
  domain?: string

  constructor(data?: Partial<TenantApplicationProperties>) {
    makeAutoObservable(this)
    Object.assign(this, data)
  }
}

export class TenantArticleProperties {
  brandVisible?: boolean
  tagRequired?: boolean
  generateExternalRef?: boolean
  withCost?: boolean
  withPrice?: boolean
  withAttributes?: boolean
  withVariants?: boolean
  withLots?: boolean
  withCopies?: boolean
  withSubscription?: boolean
  withEcoContribution?: boolean

  constructor(data?: Partial<TenantArticleProperties>) {
    makeAutoObservable(this)
    Object.assign(this, data)
  }

  public get withVariantsTracked(): boolean {
    return this.withLots || this.withCopies
  }
}

export class TenantSaleProperties {
  withNegativeStockSale?: boolean
  withGroupedArticlesInSubscription?: boolean
  withInvoiceFrequencyChoice?: boolean
  withMandatoryAddressForPerson?: boolean
  withSaleTags?: boolean
  withSubscriptionInvoicingBatch?: boolean
  withSubscriptionEstimateBatch?: boolean
  subscriptionInvoicingPeriod?: AbstractLabel
  withSubscriptionMarkUp?: boolean

  constructor(data?: Partial<TenantSaleProperties>) {
    makeAutoObservable(this)
    Object.assign(this, data)
  }
}

export class TenantPersonProperties {
  withJustExternalRef?: boolean
  autoCreateUserForCustomer?: boolean
  autoCreateCustomerForUser?: boolean
  isEmailAsLogin?: boolean

  constructor(data?: Partial<TenantPersonProperties>) {
    makeAutoObservable(this)
    Object.assign(this, data)
  }
}

export class TenantPaymentMethodProperties {
  withDirectDebit?: boolean
  directDebitAuthorization?: Document
  withTpe?: boolean
  withOnline?: boolean

  constructor(data?: Partial<TenantPaymentMethodProperties>) {
    makeAutoObservable(this)
    Object.assign(this, data)
  }
}

export class TenantPaymentProvider {
  secretKey?: string
  shopId?: string
  mode?: AbstractLabel
  paymentProvider?: AbstractLabel

  constructor(data?: Partial<TenantPaymentProvider>) {
    makeAutoObservable(this)
    Object.assign(this, data)
  }
}

export class TenantCaptchaProperties {
  secretKey?: string
  siteKey?: string

  constructor(data?: Partial<TenantCaptchaProperties>) {
    makeAutoObservable(this)
    Object.assign(this, data)
  }
}

export class TenantBarcodeProperties {
  disallowedLength?: number[]
  sequentialGeneration?: boolean

  constructor(data?: Partial<TenantBarcodeProperties>) {
    makeAutoObservable(this)
    Object.assign(this, data)
  }

  lengthIsAllowed(length: number) {
    if (!this.disallowedLength || !this.disallowedLength.length) return true
    return !this.disallowedLength.some(x => x === length)
  }
}

export class TenantFieldNamingProperties {
  estimateFieldNaming: AbstractLabel

  constructor(data?: Partial<TenantFieldNamingProperties>) {
    makeAutoObservable(this)
    Object.assign(this, data)
  }
}

export class TenantAccountingProperties {
  accountingSoftware: AbstractLabel

  constructor(data?: Partial<TenantAccountingProperties>) {
    makeAutoObservable(this)
    Object.assign(this, data)
  }
}

export class TenantLdapProperties {
  url?: string
  user?: string
  password?: string
  searchBase?: string
  searchAllFilter?: string
  searchOneFilter?: string
  usernameAttribute?: string
  firstnameAttribute?: string
  lastnameAttribute?: string
  emailAttribute?: string
  providerIdAttribute?: string

  constructor(data?: Partial<TenantLdapProperties>) {
    makeAutoObservable(this)
    Object.assign(this, data)
  }
}

export class TenantProperties {
  articleProperties: TenantArticleProperties
  personProperties: TenantPersonProperties
  mailProperties: TenantMailProperties
  barcodeProperties: TenantBarcodeProperties
  fieldNamingProperties: TenantFieldNamingProperties
  accountingProperties: TenantAccountingProperties
  tenantPaymentMethodProperties: TenantPaymentMethodProperties
  saleProperties: TenantSaleProperties
  withSocalogInterface: boolean
  withSmileApi: boolean
  id: number
  bundles: AbstractLabel[]
  defaultCurrency: AbstractLabel

  constructor(data?: Partial<TenantProperties>) {
    Object.assign(this, data)
    this.articleProperties = new TenantArticleProperties(this.articleProperties)
    this.personProperties = new TenantPersonProperties(this.personProperties)
    this.barcodeProperties = new TenantBarcodeProperties(this.barcodeProperties)
    this.saleProperties = new TenantSaleProperties(this.saleProperties)
  }

  hasBundle(bundleCode: CodeBundleEnum) {
    return this.bundles.some(x => x.code === bundleCode)
  }
}

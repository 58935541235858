import * as React from 'react'
import { SkLoader } from '@core/components/loader/skLoader'

interface IProps {
  isLoading: boolean
  showIfLoading?: boolean
  children?: any
}

export function SkPageLoader(props: IProps) {
  return (
    <div className="min-w-screen flex-center min-h-screen">
      <SkLoader {...props}>{props.children}</SkLoader>
    </div>
  )
}

SkPageLoader.defaultProps = {
  isLoading: true
}

import * as React from 'react'
import { SizeType } from 'antd/es/config-provider/SizeContext'
import { WithFormItem } from '@core/components/form'
import TextArea, { TextAreaRef } from 'antd/lib/input/TextArea'
import { useFormContext } from '@core/contexts/formContext'
import { IPropsInput, InitInput } from '@core/utils/initInput'
import { ListenInput } from '@core/utils/listenInput'
import { useEffect, useRef } from 'react'

type IProps<T, Y> = IPropsInput<T, Y> & {
  placeholder?: string
  size?: SizeType
  maxLength?: number
  autoFocus?: boolean
  inputClassName?: string
  required?: boolean
  bordered?: boolean
  onBlur?: () => void
  onPressEnter?: () => void
  ref?: React.Ref<TextAreaRef>
}

SkTextarea.defaultProps = {
  maxLength: 65535
}

export function SkTextarea<T>(props: IProps<T, string>) {
  const formContext = useFormContext()
  const { id } = InitInput(props, 'SkTextarea')
  const { onChangeInput, value, setFieldsValue } = ListenInput(props, id)
  const inputRef = useRef(null)

  useEffect(() => {
    if (props.autoFocus) {
      inputRef.current!.focus({
        cursor: 'end'
      })
    }
  }, [])

  useEffect(() => {
    setFieldsValue(value)
  }, [value])

  return (
    <WithFormItem {...props} id={id} value={value}>
      <TextArea
        ref={inputRef}
        className={props.inputClassName}
        value={value}
        size={props.size}
        placeholder={props.placeholder}
        onChange={onChangeInput}
        disabled={props.disabled || formContext.props.disabled}
        autoFocus={props.autoFocus}
        bordered={props.bordered}
        onBlur={props.onBlur}
        onPressEnter={props.onPressEnter}
        autoSize={{ minRows: 3 }}
        maxLength={props.maxLength}
      />
    </WithFormItem>
  )
}

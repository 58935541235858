import React from 'react'
import { Layout } from 'antd'
import { SkPage } from '@core/components/page'

export default function SkEmptyLayout({ children }) {
  const { Content } = Layout
  return (
    <SkPage>
      <Layout>
        <Content>{children}</Content>
      </Layout>
    </SkPage>
  )
}

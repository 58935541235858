import React from 'react'
import classNames from 'classnames'
import { SkTooltip } from '@core/components'
import { SkIconUilAngleRightB } from '@core/global/icons'
import { SkBtn } from '@core/components/btns'
import Link from 'next/link'

interface Props {
  id: string | number
  icon?: React.ReactNode
  href?: string
  label: React.ReactNode
  bottom?: React.ReactNode
  right?: React.ReactNode
  textClassName?: string
  iconClassName?: string
  withAngleRight?: boolean
  onClick?: (event: any) => void
  selected?: boolean
  withoutBgHover?: boolean
  tooltip?: string
  size?: 'small' | undefined
  withWrapperMenuItem?: boolean
  withTooltipBorderInfo?: boolean
  disabled?: boolean
}

SkMenuItem.defaultProps = {
  withAngleRight: false,
  iconClassName: '',
  wrapperMenuItem: false
}

export function SkMenuItem(props: Props) {
  const onClick = (e: any) => {
    props.onClick?.(e)
  }

  function getWrapperItem() {
    return props.tooltip ? (
      <SkTooltip withBorderInfo={props.withTooltipBorderInfo} label={props.tooltip}>
        {getWrapperMenuItem()}
      </SkTooltip>
    ) : (
      getWrapperMenuItem()
    )
  }

  function getWrapperMenuItem() {
    const c = classNames(
      'p-0 h-fit !justify-start w-full text-left !text-gray-800',
      { '!bg-gray-100': props.selected },
      { 'hover:bg-gray-100': !props.withoutBgHover },
      { 'text-xs': props.size === 'small' },
      { 'w-full px-3 !py-2 cursor-pointer': props.withWrapperMenuItem }
    )
    return (
      <SkBtn type="link" size="middle" id={props.id.toString()} className={c} onClick={onClick} disabled={props.disabled}>
        {getMenuItem()}
      </SkBtn>
    )
  }

  function getMenuItem() {
    return (
      <div className="flex w-full items-center">
        <div className="flex-grow">
          <div className="flex justify-between">
            <div className="flex items-center">
              {props.icon && <div className={classNames('flex-center text-primary mr-2', props.iconClassName)}>{props.icon}</div>}
              <div className="pl-2">
                <div className="flex">{props.label}</div>
                {props.bottom && <div className="text-muted flex pt-1 text-xs">{props.bottom}</div>}
              </div>
            </div>
            {props.right}
          </div>
        </div>

        {props.withAngleRight && (
          <div className="flex-center h-4 w-4">
            <SkIconUilAngleRightB />
          </div>
        )}
      </div>
    )
  }

  return <>{!props.href ? <>{getWrapperItem()}</> : <Link href={props.href}>{getWrapperItem()}</Link>}</>
}

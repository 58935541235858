import { Modal } from 'antd'
import * as React from 'react'
import { SkLoader } from '@core/components/loader/skLoader'
import { useEffect, useState } from 'react'
import { makeObservable, observable } from 'mobx'
import { observer } from 'mobx-react-lite'
import { EditContext, SkEditProvider } from '@core/contexts/editContext'
import { useOnUnmount } from '@core/utils/hooks'
import { coreStore } from '@core/store/coreStore'
import { useRouter } from 'next/router'

interface IProps {
  onCancel?: () => void
  children: React.ReactNode
  size?: 'full' | 'max' | string
  isLoading?: boolean
  setModalContext?: (data: ModalContext) => void
  editContext?: EditContext<any>
  keyboard?: boolean
  transitionName?: string
}

export const SkModalContext = React.createContext<ModalContext>(null)
export const useModalContext = () => React.useContext<ModalContext>(SkModalContext)

export class ModalContext {
  size: 'full' | 'max' | string
  @observable
  modalBodyRef: HTMLElement

  constructor(data: Partial<ModalContext>) {
    Object.assign(this, data)
    makeObservable(this)
  }

  scrollTop() {
    this.modalBodyRef.scrollTo(0, 0)
  }
}

export const SkModal = observer(({ editContext, setModalContext, size, isLoading, onCancel, children, keyboard, transitionName }: IProps) => {
  const [modalContext] = useState(() => new ModalContext({ size }))
  const router = useRouter()

  useEffect(() => {
    setModalContext && setModalContext(modalContext)
  }, [])

  if (editContext) {
    useEffect(() => {
      editContext.setIsModal(true)
      if (editContext.store?.item?.id) {
        editContext.startPath = coreStore.routerStore.asPath
      } else {
        // remove queryParam urlParamTabKey to start with first tab
        editContext.removeQueryParam(editContext.urlParamTabKey).then(() => (editContext.startPath = coreStore.routerStore.asPath))
      }
      editContext.modalContext = modalContext
    }, [editContext])

    useEffect(() => {
      editContext?.setTabActive(editContext.urlParamTabKeyValue).then()
    }, [router.query])
  }

  useOnUnmount(() => coreStore.countModalOpen--)

  return (
    <SkEditProvider editContext={editContext}>
      <SkModalContext.Provider value={modalContext}>
        <Modal
          open
          footer={null}
          bodyStyle={{ padding: 0 }}
          wrapClassName={size}
          maskClosable={true}
          closable={false}
          destroyOnClose={true}
          onCancel={onCancel}
          width={size === 'full' ? '100vw' : size ? size : '500px'}
          keyboard={keyboard}
          transitionName={transitionName}
        >
          <SkLoader isLoading={isLoading}>{children}</SkLoader>
        </Modal>
      </SkModalContext.Provider>
    </SkEditProvider>
  )
})

import { AbstractLabel } from '@core/models/abstractLabel'
import { makeObservable, observable } from 'mobx'
import { Accounting } from '@common/models/accounting/accounting'

export class Tag extends AbstractLabel {
  @observable
  idx: number
  @observable
  parent: Tag
  @observable
  children: Tag[]
  @observable
  accounting: Accounting

  constructor(data?: Partial<Tag>) {
    super(data)
    makeObservable(this)
    Object.assign(this, data)
    if (!this.idx) this.idx = 1
    if (this.parent) {
      this.parent = new Tag(this.parent)
    }
    if (this.accounting) {
      this.accounting = new Accounting(this.accounting)
    }
  }
}

export enum PersonTypeEnum {
  person = 0,
  company = 1,
  service = 2
}

export enum PersonTitleEnum {
  mr = 1,
  mrs = 2
}

import * as React from 'react'
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons'
import { WithFormItem } from '@core/components/form'
import { SizeType } from 'antd/es/config-provider/SizeContext'
import Password from 'antd/lib/input/Password'
import { IPropsInput, InitInput } from '@core/utils/initInput'
import { ListenInput } from '@core/utils/listenInput'
import { useEffect } from 'react'

type IProps<T, Y> = IPropsInput<T, Y> & {
  placeholder?: string
  size?: SizeType
  maxLength?: number
  addonBefore?: React.ReactNode
  addonAfter?: React.ReactNode
  prefix?: React.ReactNode
  suffix?: React.ReactNode
  autoFocus?: boolean
  inputClassName?: string
  required?: boolean
  autoComplete?: string
}

export function SkPassword<T>(props: IProps<T, string>) {
  const { id } = InitInput(props, 'SkPassword')
  const { onChangeInput, value, setFieldsValue } = ListenInput(props, id)

  useEffect(() => {
    setFieldsValue(value)
  }, [value])

  return (
    <WithFormItem {...props} id={id} value={value}>
      <Password
        iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
        type="text"
        value={value}
        className={props.inputClassName}
        size={props.size}
        placeholder={props.placeholder}
        onChange={onChangeInput}
        disabled={props.disabled}
        addonAfter={props.addonAfter}
        addonBefore={props.addonBefore}
        autoFocus={props.autoFocus}
        prefix={props.prefix}
        suffix={props.suffix}
        autoComplete={props.autoComplete ?? 'new-password'}
      />
    </WithFormItem>
  )
}

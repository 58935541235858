import React from 'react'
import { SkLinkMenu, SkMenuItem, SkPageEditBackButton, useEditContext } from '@core/components'

interface IProps {
  children: React.ReactNode
}

export const SkPageEditColLeft = (props: IProps) => {
  const editContext = useEditContext()

  return (
    <div className="skPageEditCol scroll-design w-1/4 border-r">
      <div className="flex items-center justify-between pt-3">
        <SkPageEditBackButton store={editContext.store} />
        <SkLinkMenu id="actions" label="Actions" className="px-3" menuItems={[{ key: 1, label: <SkMenuItem id="delete" label="Supprimer" onClick={() => console.log('ok')} /> }]} />
      </div>
      {props.children}
    </div>
  )
}

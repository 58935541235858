import { AbstractLabel } from './abstractLabel'
import { AbstractUi } from './abstractItem'
import { computed, observable } from 'mobx'
import { AbstractCustomer } from '@core/models/abstractCustomer'
import { Permission, Role } from '@core/models/role'

export class AbstractUser extends AbstractLabel {
  enabled: boolean
  password: string
  @observable
  roles: Role[]
  token: string
  provider: string
  @observable
  username: string
  tenants: AbstractLabel[]
  @observable
  customer: AbstractCustomer
  @observable
  canCollectCommission: boolean
  @observable
  isInEmailCopy: boolean
  declare ui: Ui

  constructor(data?: Partial<AbstractUser>) {
    super(data)
    Object.assign(this, data)
    if (this.customer) {
      this.customer = new AbstractCustomer(this.customer)
    }
    if (this.roles) {
      this.roles.forEach(role => Object.assign(role, new Role(role)))
    }
    this.ui = new Ui(this)
  }

  @computed
  get providerIsLocal() {
    return this.provider === 'LOCAL'
  }
}

class Ui extends AbstractUi {
  permissions: Permission[] = []
  crudPermissions: Permission[] = []

  constructor(user?: Partial<AbstractUser>) {
    super()
    this.isDisabled = user.provider?.indexOf('LDAP') > -1
    user.roles?.forEach(role => {
      role.permissions?.forEach(permission => {
        if (!this.permissions.some(x => x.type.id === permission.type.id)) {
          this.permissions.push(permission)
        }
      })
      role.crudPermissions?.forEach(permission => {
        const index = this.crudPermissions.findIndex(x => x.type.id === permission.type.id)
        if (index < 0) {
          this.crudPermissions.push(permission)
        } else {
          this.crudPermissions[index].canEdit = this.crudPermissions[index].canEdit || permission.canEdit
          this.crudPermissions[index].canRead = this.crudPermissions[index].canRead || permission.canRead
        }
      })
    })
  }
}

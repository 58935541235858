import { action, makeObservable, observable } from 'mobx'
import { WarehousesActionTypeEnum } from 'skazy-gescom-common/global/enums'
import { ReactNode } from 'react'

export abstract class QueryModalStore<T> {
  @observable
  items: T[] = []
  @observable
  itemsFiltered: ItemSearchResult<T>[] = []
  @observable
  modalIsOpen: boolean
  @observable
  indexSelected = 0
  @observable
  terms: string
  @observable
  isLoading: boolean

  protected constructor() {
    makeObservable(this)
  }

  @action
  handleKeyDown = data => {
    if (data.key === 'ArrowDown') {
      if (this.itemsFiltered.length - 1 > this.indexSelected) {
        this.indexSelected++
      } else {
        this.indexSelected = 0
      }
    } else if (data.key === 'ArrowUp') {
      if (this.indexSelected > 0) {
        this.indexSelected--
      } else {
        this.indexSelected = this.itemsFiltered.length - 1
      }
    } else if (data.key === 'Enter') {
      if (this.itemsFiltered[this.indexSelected]) {
        this.itemsFiltered[this.indexSelected].onClick(this.itemsFiltered[this.indexSelected].data)
      }
    }
  }
}

export class ItemSearchResult<T> {
  id: string | number
  label: ReactNode
  bottom?: ReactNode
  right?: ReactNode
  icon?: ReactNode
  data?: T
  onClick: (item: T) => void
  canBeAfterThisActionId?: WarehousesActionTypeEnum
}

import { makeObservable, observable } from 'mobx'
import { AbstractItem } from '@core/models/abstractItem'
import { Attribute } from '@common/models/attribute/attribute'

export class ArticleAttribute extends AbstractItem {
  @observable
  idx: number
  @observable
  isUsedInBarcode: boolean
  @observable
  attribute: Attribute

  constructor(data?: Partial<ArticleAttribute>) {
    super(data)
    makeObservable(this)
    Object.assign(this, data)
    if (this.attribute) this.attribute = new Attribute(this.attribute)
    if (!this.id) this.isUsedInBarcode = true
  }
}

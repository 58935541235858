import { WarehouseActionLine } from '@common/models/warehouseActionLine/warehouseActionLine'
import { WarehouseActionUtils } from '@common/models/warehouseAction/missingItems/warehouseActionUtils'
import { LineQuantityCalculator } from '@common/models/warehouseActionLine/lineQuantityCalculator'

export class MissingArticlesGenerator {
  missingObjectsCount = 0
  missingWarehouseActionLines: WarehouseActionLine[] = []

  constructor(private warehouseActionLines: WarehouseActionLine[] = [], private previousLines: WarehouseActionLine[] = []) {
    this.previousLines.forEach(previousLine => this.checkForMissingItems(previousLine))
  }

  private checkForMissingItems(previousLine: WarehouseActionLine) {
    if (previousLine.variant.article.isVariantTracked) {
      this.checkForMissingItemsInVariantTrackedLine(previousLine)
    } else {
      this.checkForMissingItemsInVariantLine(previousLine)
    }
  }

  private checkForMissingItemsInVariantTrackedLine(previousLine: WarehouseActionLine) {
    if (!this.isCorrespondingToACurrentLine(previousLine)) {
      if (this.shouldReplaceLineByPreviousLineWithSameVariant(previousLine)) {
        this.replaceLineByPreviousLineWithSameVariant(previousLine)
      } else if (!this.variantTrackedIsDeleted(previousLine)) {
        this.addMissingItem(previousLine)
      }
    }
  }

  private variantTrackedIsDeleted(previousLine: WarehouseActionLine) {
    return previousLine.variantTracked.isDeleted
  }

  private isCorrespondingToACurrentLine(previousLine: WarehouseActionLine) {
    return this.warehouseActionLines.some(line => WarehouseActionUtils.havSameVariantTracked(line, previousLine))
  }

  private shouldReplaceLineByPreviousLineWithSameVariant(previousLine: WarehouseActionLine): boolean {
    const hasBarCode = previousLine.variantTracked.barcode
    const hasSameVariantAsACurrentLine = this.warehouseActionLines.some(line => this.correspondsByVariant(line, previousLine))
    return !hasBarCode && hasSameVariantAsACurrentLine
  }

  private replaceLineByPreviousLineWithSameVariant(previousLine: WarehouseActionLine) {
    const index = this.warehouseActionLines.findIndex(line => this.correspondsByVariant(line, previousLine))
    this.warehouseActionLines[index].variantTracked.id = previousLine.variantTracked.id
  }

  private correspondsByVariant(line: WarehouseActionLine, previousLine: WarehouseActionLine): boolean {
    const hasAVariantTrackedWithoutId = line.variantTracked && !line.variantTracked.id
    const hasSameVariant = WarehouseActionUtils.haveSameVariant(line, previousLine)
    return hasAVariantTrackedWithoutId && hasSameVariant
  }

  private checkForMissingItemsInVariantLine(previousLine: WarehouseActionLine) {
    const receivedQuantity = this.calculateReceivedQuantityForVariant(previousLine)

    if (receivedQuantity === 0 || receivedQuantity < previousLine.quantity) {
      const missingQuantity = previousLine.quantity - receivedQuantity
      this.addMissingItem(previousLine, missingQuantity)
    }
  }

  private calculateReceivedQuantityForVariant(previousLine: WarehouseActionLine) {
    const currentLinesWithSameVariant = this.warehouseActionLines.filter(line => WarehouseActionUtils.haveSameVariant(line, previousLine))
    let receivedQuantity = 0
    currentLinesWithSameVariant.forEach(received => (receivedQuantity += received.quantity))
    return receivedQuantity
  }

  private addMissingItem(previousLine: WarehouseActionLine, quantity = 1) {
    this.missingObjectsCount += quantity
    const calc = new LineQuantityCalculator(previousLine.variant, previousLine.variantTracked)
    this.missingWarehouseActionLines.push(new WarehouseActionLine({ ...previousLine, quantity: calc.shouldQuantityBeFixed() ? previousLine.quantity : quantity }))
  }
}

export enum CodeRoleEnum {
  admin = 'ADMINISTRATOR',
  client = 'CLIENT'
}

export enum CodeBundleEnum {
  stock = 'STOCK',
  restaurant = 'RESTAURANT',
  invoice = 'INVOICE',
  delivery = 'DELIVERY',
  intervention = 'INTERVENTION',
  commission = 'COMMISSION'
}

export enum CodePermissionEnum {
  // actions
  actionAssignToClient = 'ACTION_ASSIGN_TO_CLIENT',
  actionAssignToReseller = 'ACTION_ASSIGN_TO_RESELLER',
  actionAddInStock = 'ACTION_ADD_IN_STOCK',
  actionChangeStockTrackingQuantity = 'ACTION_CHANGE_STOCK_TRACKING_QUANTITY',
  actionAddInStockSocalog = 'ACTION_ADD_IN_STOCK_SOCALOG',
  actionRequestReplenishmentSocalog = 'ACTION_REQUEST_REPLENISHMENT_SOCALOG',
  actionShip = 'ACTION_SHIP',
  actionScrap = 'ACTION_SCRAP',
  actionRemoval = 'ACTION_REMOVAL',
  actionReceiveShipment = 'ACTION_RECEIVE_SHIPMENT',
  actionRepackage = 'ACTION_REPACKAGE',
  actionPayback = 'ACTION_PAYBACK',
  actionDelete = 'ACTION_DELETE',
  actionTransfer = 'ACTION_TRANSFER',
  actionArchiveReception = 'ACTION_ARCHIVE_RECEPTION',

  // saleMenu
  invoiceManagement = 'INVOICE_MANAGEMENT',
  clientManagement = 'CLIENT_MANAGEMENT',
  action_validate_and_invoice_estimate = 'ACTION_VALIDATE_AND_INVOICE_ESTIMATE',
  action_add_payment = 'ACTION_ADD_PAYMENT',

  // stockMenu
  itemManagement = 'ITEM_MANAGEMENT',
  articleManagement = 'ARTICLE_MANAGEMENT',
  packageGroupManagement = 'PACKAGE_GROUP_MANAGEMENT',

  // stockTracking tab for variantTracked
  stockTracking = 'STOCK_TRACKING_READ',

  // b2b
  businessDealManagement = 'BUSINESS_DEAL_MANAGEMENT',
  purchaseManagement = 'PURCHASE_MANAGEMENT',

  // accounting
  paramAccounting = 'PARAM_ACCOUNTING',
  actionAccountingExportAll = 'ACTION_ACCOUNTING_EXPORT_ALL',

  // paramsStock
  paramWarehouse = 'PARAM_WAREHOUSE',

  // paramsTemplate
  paramTemplate = 'PARAM_TEMPLATE',

  // paramsSecurity
  paramUser = 'PARAM_USER',
  paramRole = 'PARAM_ROLE',
  paramBatch = 'PARAM_BATCH',

  // user
  userPassword = 'USER_PASSWORD',

  // paramsReferential
  paramSupplier = 'PARAM_SUPPLIER',
  paramBrand = 'PARAM_BRAND',
  paramArticleTag = 'PARAM_ARTICLE_TAG',
  paramEstimateCategory = 'PARAM_ESTIMATE_CATEGORY',
  paramTransporter = 'PARAM_TRANSPORTER',
  paramAccount = 'PARAM_ACCOUNT',
  paramCompany = 'PARAM_COMPANY',
  paramTax = 'PARAM_TAX',
  paramArticleUnit = 'PARAM_ARTICLE_UNIT',
  paramCity = 'PARAM_CITY',
  paramBank = 'PARAM_BANK',
  paramSaleTag = 'SALE_TAG',

  // estimate
  estimatesExport = 'ESTIMATES_EXPORT',
  estimateOpening = 'ESTIMATE_OPENING',
  subscriptionInvoicing = 'SUBSCRIPTION_INVOICING',

  // commission
  commissionManagement = 'COMMISSION_MANAGEMENT',
  commission = 'COMMISSION',
  commissionRate = 'COMMISSION_RATE',

  // Restaurant
  planningManagement = 'PLANNING_MANAGEMENT',
  recipeManagement = 'RECIPE_MANAGEMENT',
  menuManagement = 'MENU_MANAGEMENT',
  welcomeScreen = 'WELCOME_SCREEN',

  // Statistiques
  globalStatistics = 'GLOBAL_STATISTICS'
}

export enum ArticleStateEnum {
  catalog = 1,
  internal = 2,
  archive = 3
}

export enum ArticleTypeEnum {
  physical = 1,
  service = 2,
  subscription = 3,
  rental = 4
}

export enum WarehouseTypeEnum {
  virtualInStock = 1,
  virtualOutOfStock = 2,
  internal = 3,
  external = 4,
  transit = 5,
  socalog = 6
}

export enum WarehousesActionTypeEnum {
  shipping = 1,
  reception = 2,
  changeStateAndReceipt = 3,
  destruction = 4,
  sale = 5,
  regulation = 6,
  replenishment = 7,
  deletion = 8,
  regulationCustom = 9
}

export enum EstimateStateEnum {
  draft = 1,
  open = 2,
  validated = 3,
  canceled = 4,
  rejected = 5,
  deleted = 6
}

export enum VariantTrackedStateEnum {
  isNew = 1,
  toTest = 2,
  reconditioned = 3,
  destroyed = 4,
  toDestroy = 5,
  awaitingDocuWareForDestruction = 6,
  inService = 7
}

export enum CodeTagEnum {
  recipes = 'recipe',
  extras = 'extra'
}

export enum PaymentTypeEnum {
  bankCheck = 1,
  bankCard = 2,
  cash = 3,
  transfert = 4,
  directDebit = 5,
  credit = 6,
  other = 7
}

export enum EstimateTypeEnum {
  estimate,
  subscription,
  template
}

export enum NextPublicTypeEnum {
  dev = 'dev'
}

export enum FrequencyEnum {
  week = 1,
  month,
  year
}

export enum DiscountCodeEnum {
  firstArticleDiscount = 'FIRST_ARTICLE_DISCOUNT',
  secondArticleDiscount = 'SECOND_ARTICLE_DISCOUNT',
  globalDiscount = 'GLOBAL_DISCOUNT'
}

export enum InvoicePlanTypeEnum {
  custom,
  recurrent
}

export enum PaymentStateEnum {
  toPay,
  payed,
  refused,
  abandoned,
  toValidate,
  canceled,
  expired,
  underVerification,
  deposit
}

export enum DateTimeEnum {
  today = 0,
  tomorrow,
  exact,
  between,
  start,
  end,
  exactMonth,
  currentMonth,
  lastMonth,
  nextMonth
}

export enum ShippingStateEnum {
  toShip,
  canceled,
  shipped
}

export enum BatchStateEnum {
  running,
  success,
  fail,
  unknown
}

export enum BatchEnum {
  handleLastPublishedPlannings = 'HANDLE_LAST_PUBLISHED_PLANNINGS'
}

export enum EstimateFieldNamingEnum {
  estimate = 'ESTIMATE',
  quote = 'QUOTE'
}

export enum FieldTypeEnum {
  boolean,
  number,
  text,
  date,
  list,
  textarea,
  textAndCheckbox,
  file,
  variantTracked
}

export enum AttributeCodePlacementEnum {
  estimateGeneral = 'ESTIMATE_GENERAL',
  estimateInvoicing = 'ESTIMATE_INVOICING',
  articleGeneral = 'ARTICLE_GENERAL',
  equipmentGeneral = 'EQUIPMENT_GENERAL'
}

export enum TemplateModelEnum {
  user = 'USER',
  estimate = 'ESTIMATE'
}

export enum FrequencyDayEnum {
  weekFirstDay,
  weekLastDay,
  monthFirstDay,
  monthLastDay,
  custom
}

export enum InvoiceStateEnum {
  toPay,
  payed
}

export enum AccountingAccountTypeEnum {
  customer,
  sale,
  tax,
  cashDesk,
  bank,
  discount,
  loss,
  profit
}

export enum BankDepositSlipTypeEnum {
  bankCheck,
  directDebit
}

export enum PaymentBankDepositStateEnum {
  deposited,
  pending,
  success,
  rejected
}

export enum InvoiceBankDepositStateEnum {
  pending,
  success,
  rejected
}

export enum InterventionWorkTypeEnum {
  initialisation,
  action
}

export enum InterventionStateEnum {
  draft,
  planned,
  toDo,
  validated,
  review,
  closed
}

export enum TransactionTypeEnum {
  sale,
  subscription,
  rental
}

export enum SaleTypeForCommissionEnum {
  sale,
  subscription,
  rental,
  subscriptionRenewal,
  rentalRenewal
}

export enum CommissionTableTypeEnum {
  commissionTable,
  commissionRateTable
}

export enum SuscriptionInvoicingPeriodEnum {
  consumed,
  to_consume
}

export enum InstanceTypeEnum {
  demo = 'DEMO',
  prospect = 'PROSPECT',
  client = 'CLIENT'
}

import { makeAutoObservable } from 'mobx'
import { VariantTracked } from '@common/models/variantTracked/variantTracked'
import { referentialStore } from '@common/models/referential/referentialStore'
import { Variant } from '@common/models/variant/variant'
import { rootStore } from '@/stores/rootStore'

export class AppStore {
  tourStep: number
  canOpenModalShortcuts = true
  waitingVariantsTracked: VariantTracked[] = []
  waitingVariants: Variant[] = []
  modalSearchStoreIsOpen = false

  constructor() {
    makeAutoObservable(this)
  }

  setCanOpenModalShortcuts = (value: boolean) => {
    this.canOpenModalShortcuts = value
  }

  resetData() {
    if (referentialStore) referentialStore.data = null
    if (rootStore.menuStore) rootStore.menuStore.links = null
  }

  addWaitingVariantTracked(item: VariantTracked) {
    if (this.waitingVariantsTracked.some(x => x.id === item.id)) {
      return
    }
    this.waitingVariantsTracked.push(item)
  }

  openModalSearchStore = () => {
    this.modalSearchStoreIsOpen = true
  }

  closeModalSearchStore = () => {
    this.modalSearchStoreIsOpen = false
  }

  clearWaitingVariantsTracked = () => {
    this.waitingVariantsTracked = []
  }
}

import React from 'react'
import { SkSelect } from '@core/components'
import { ApiStore } from '@core/api/apiStore'
import { AbstractLabel } from '@core/models/abstractLabel'

type IProps = {
  id: string
  label: string
  value: number
  propertyToSearch: string
  customPropertyToSearch: string
  items: AbstractLabel[]
  store: ApiStore<unknown>
  onChange: (data: number) => void
  disabled?: boolean
} & (
  | {
      propertyToSearch?: never
      customPropertyToSearch?: string
      propertyToOrder?: string
    }
  | {
      propertyToSearch?: string
      customPropertyToSearch?: never
      propertyToOrder?: string
    }
)

export function SkFilterSelect(props: IProps) {
  return (
    <div className="w-full filter">
      <SkSelect {...props} returnId allowClear placeholder="Taper au moins un caractère ..." size="large" />
    </div>
  )
}
